import { Component, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { invoicesTableData } from 'src/app/mocks/proformas';
import { ComboItem } from 'src/app/models/combo-item';
import { ExpedientFilters } from 'src/app/models/expedients/expedient-filters';
import { InvoiceFilters } from 'src/app/models/invoices/invoice-filters';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { StorageService } from 'src/app/services/utils/storage.service';
import { Invoice } from '../../../../../models/pays-charges/invoice';


@UntilDestroy()
@Component({
  selector: 'vex-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class InvoicesComponent {

  constructor(
    private storageService: StorageService) {
  }

  @Input() tableData: Array<Invoice>;
  @Input() type: KeyValue;
  @Input() isMenu: boolean = true;
  @Input() filters: CommonFilter;
  list: ITableParams<Invoice, Invoice>;
  @Input() service: string;
  @Input() readOnly: boolean= false;

  total = 0;
  pageSize = 5;
  pageIndex = 0;
  order = "asc";
  orderType = "";

  invoiceFilters: ExpedientFilters = new ExpedientFilters();
  invoiceFiltersAll: ExpedientFilters = new ExpedientFilters();;

  services = "administrative-files";

  extraValue: string = '';
  
  items: Array<ComboItem> = [
    {
      id: 1,
      value: "España",
      imageSrc: "assets/img/flags/España.jpg",
    },
    {
      id: 2,
      value: "Bulgaria",
      imageSrc: "assets/img/flags/Bulgaria.jpg",
    }];

  @Output() params: ITableParams<Invoice, Invoice> = null;

  ngOnInit() {


    this.loadFilters();
    // this.list.tableData = invoicesTableData.map(invoice => new Invoice(invoice));
    let columns: Array<TableColumn<Invoice>>;
    let columnsList: Array<TableColumn<Invoice>>;
    if (this.service == "proformas") {
      columns = [
        { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible:  !this.readOnly },
        { label: 'Proforma', property: 'proformaCode', type: 'text', visible: true },
        /*  { label: 'Expediente', property: 'name', type: 'text', visible: true },
         { label: 'Proforma', property: 'invoice', type: 'text', visible: true }, */
        { label: 'Total', property: 'cost', type: 'number', visible: true },
        { label: 'Importe', property: 'amount', type: 'number', visible: true }

      ];
      columnsList = [
        { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible:  !this.readOnly },
        { label: 'Proforma', property: 'name', type: 'text', visible: true },
        { label: 'Expediente', property: 'expedientes', type: 'chip', visible: true },
        /*   { label: 'Proforma', property: 'invoice', type: 'text', visible: true },
        { label: 'Total', property: 'total', type: 'number', visible: true },
        { label: 'Importe', property: 'amount', type: 'number', visible: true } */

      ];

    }
    else {
      columns = [
        { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible:  !this.readOnly },
        { label: 'Expediente', property: 'name', type: 'text', visible: true },

      ];
      columnsList = columns;

    }

    this.params = {

      isMenu: false,
      element: this.service == "proformas" ? "Proforma" : "Expediente",
      title: this.service == "proformas" ? "Proformas" : "Expedientes",
      titleProperty: "expedient",
      //tableData: this.tableData,
      service: this.service,
      linkService: this.service == "proformas" ? "accountingentries/proforma" : "accountingentries/costs/administrativefiles",
      deleteService:  this.service == "proformas" ? "accountingentries/proforma" :  "accountingentries/costs/administrativefiles",
      filters: this.invoiceFilters,
      disabledMode: this.readOnly,
      hasImage: false,
      reload: true,
      columns: columns,
      extraValue: null,
      list: {
        isMenu: false,
        element: this.service == "proformas" ? "Proforma" : "Expediente",
        title: this.service == "proformas" ? "Proformas" : "Expedientes",
        titleProperty: 'expedient',
        service: this.service == "proformas" ? this.service + "/maestra" : this.service + "/maestra",
        filters: this.invoiceFiltersAll,
        paginatedOnBack: true,
        hasImage: false,
        extraValue: this.type as unknown as object,
        columns: columnsList
        //tableData: this.loadInvoice('') || [],

      }
    }
  }
  loadFilters() {
   /*  if (this.service == "proformas"){ */
      //this.invoiceFilters = new CommonFilter(null);
      
      if (this.service == "proformas"){ 
        this.invoiceFilters.accountingEntryId = this.filters.accountingEntryId || null;
        this.invoiceFilters.id = this.filters.id;
      }
      else
      {
        this.invoiceFilters.accountingEntryId = this.filters.id;
      }
  /*   }
    else {
      //this.invoiceFilters = new CommonFilter(null);
      this.invoiceFilters.accountingEntryId = this.filters.id || null;
      this.filters.id = null;
      //this.invoiceFilters.orderTypeId = null;
    } */
    //this.invoiceFilters.id = this.filters.id;
    this.invoiceFilters.pageNumber = this.pageIndex + 1;
    this.invoiceFilters.rowsPerPage = 1000;
    this.invoiceFilters.order = this.order;
    
    this.invoiceFilters.orderBy = this.orderType;


    //this.invoiceFiltersAll = new CommonFilter(null);
    
    this.invoiceFiltersAll.id = this.filters.id;
    this.invoiceFiltersAll.pageNumber = this.pageIndex + 1;
    this.invoiceFiltersAll.rowsPerPage = 5;
    this.invoiceFiltersAll.order = this.order;
    this.invoiceFiltersAll.orderBy = this.orderType;
    let orderType = new EmptyKeyValue()
    if(this.type?.id == 1 )
      orderType.id = 2;
    else 
      orderType.id = 1;
      
    this.invoiceFiltersAll.orderType = orderType;
    this.invoiceFiltersAll.orderTypeId = orderType.id;
    
  /*   if (this.service != "proformas")
      this.invoiceFiltersAll.orderTypeId = null; */

    this.storageService.getItem("origin").subscribe(origin => {
        this.invoiceFiltersAll.officeId = origin;
        this.invoiceFilters.officeId = origin;
    })

  }

  receiveType(type: any) {
    this.params.list.tableData = this.loadInvoice(type);
  }

  loadInvoice(type: any) {
    return invoicesTableData.filter(f => !type || f.type === type).map(invoice => new Invoice(invoice));
  }


}
