import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { ComboItem } from 'src/app/models/combo-item';
import { EmptyLanguages } from 'src/app/models/equipments/languages';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { MasterService } from 'src/app/services/api/masters.service';
import { Basic } from '../../../../../models/equipments/basic';
import { BasicFilters } from 'src/app/models/equipments/basic.filters';
import { async } from 'rxjs';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';


@UntilDestroy()
@Component({
  selector: 'vex-basics-list',
  templateUrl: './basics-list.component.html',
  styleUrls: ['./basics-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class BasicsListComponent {
 
  _params : ITableParams<Basic, Basic>;
  
  @Input() filters: CommonFilter = new CommonFilter(null);
  @Input() service: string;
  _tableData: Array<Basic>;

  @Input()
  set tableData(val: Array<Basic>) {
    this.Change.emit(val);
    this._tableData = val;
  }
  get tableData() {
    //this.tableData = this.params?.list?.tableData;
    return this._tableData;
  }

  @Input()
  set packs(val: Array<ComboItem>) {
    //this.changedModelEvent.emit(val);
    this._packs = val;
    this.loadData();
  }
  get packs() {
    //this.tableData = this.params?.list?.tableData;
    return this._packs;
  }
  @Input()
  set params(val: ITableParams<Basic, Basic>) {
    this.dataChange.emit(val);
    this._params = val;
  }
  get params() {
    //this.tableData = this.params?.list?.tableData;
    return this._params;
  }
  @Output() dataChange: EventEmitter<ITableParams<Basic, Basic>> = new EventEmitter<ITableParams<Basic, Basic>>();
  @Output() Change: EventEmitter<Array<Basic>> = new EventEmitter<Array<Basic>>();

  constructor(
    private masterService: MasterService<MasterCommon>) {
  }

  getTableData(tableData) {
    this.tableData = tableData;
  }


  _packs: Array<ComboItem>;
  @Input()
  categories: Array<ComboItem>;
  basics: Array<Basic>;
    
  
  //@Output() params: ITableParams<Basic,Basic> = null;

  ngOnInit() {
      this.loadData();

  }

  loadData(){
    let columns: Array<TableColumn<Basic>> = [
      { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
      { label: 'Descripción', property: 'name', type: 'text', visible: true, validations: Validators.compose([
        Validators.required]) },
      { label: 'Categoría', property: 'equipmentClass', type: 'select', visible: true, items: this.categories , validations: Validators.compose([
        Validators.required]) },
        { label: 'Pack', property: 'equipmentPack', type: 'editable', visible: true, defaultValue: new EmptyKeyValue(), items: this._packs , validations: Validators.compose([
          Validators.required]) },
      { label: 'Idiomas', property: 'translation', type: 'languages', defaultValue: new EmptyLanguages(), visible: false},
    ];
    this.params = {

      isMenu: false,
      element: "Básico",
      title: 'Básicos',
      titleProperty: "name",
      tableData: this._tableData || [],
      //service:"basicEquipments",
      pageSize: 5,
      //tableData: basicsTableData.map(basic => new Basic(basic.languages.map(languages => new ComboItem(languages)) )),
      hasImage: false,
      columns: columns,
      list: {
        isMenu: true,
        filters:  new BasicFilters(),
        element: "Básico",
        title: 'Básicos',
        titleProperty: "name",
        hasImage: false,
        pageSize: 10,
        service: 'basicEquipments',
        columns:  [
          { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
          { label: 'Descripción', property: 'name', type: 'text', visible: true, validations: Validators.compose([
            Validators.required]) },
          { label: 'Categoría', property: 'equipmentClass', type: 'select', visible: true, items: this.categories , validations: Validators.compose([
            Validators.required]) },
            { label: 'Pack', property: 'equipmentPack', type: 'select', visible: false, defaultValue: new EmptyKeyValue(), items: this._packs },
            { label: 'Idiomas', property: 'translation', type: 'languages', defaultValue: new EmptyLanguages(), visible: false},
        ]


      }
     
    }
  }
  async getItems(){
    this.categories = await this.masterService.get("equipmentClass") as unknown as Array<MasterCommon>;
    //this.packs = await this.masterService.get("equipmentPacksAll") as unknown as Array<MasterCommon>;
    //this.basics = await this.masterService.get("basicEquipments") as unknown as Array<Basic>
  }

  setPacks(value){
    this.packs= value;
  }
}