import { IFilters } from "src/app/interfaces/ifilters";
import { KeyValue } from "../key-value";

export class CostFilters implements IFilters {

    id: number = null;
    
    rowsPerPage: number = 5;
    pageNumber: number = 1;
    orderBy: string;
    order: string;
    name: string = "";
    /* hasSale?: boolean = null;
    hasShipment? = null;
    locationType?: KeyValue;
    sign?:number;
    extraId?: number;
    orderTypeId?:number = null;*/
    officeId?:number = null; 
    entityId?:number = null; 
    costTypeId?:number = null; 
    administrativeFileReference?: KeyValue = null;
    bankingTransactionId?: number = null;
    administrativeFileId?: number = null;
    createdAt?:Date = null;
    concept?: string = null;
    accountingEntryId?: number = null;
 
    
  
  }
  