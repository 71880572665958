import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";


@Injectable()
export class StorageService {
    public static readonly TYPE_ACCESS_TOKEN: string = 'access_token';
	public static readonly TYPE_REFRESH_TOKEN: string = 'refresh_token';
	public static readonly TYPE_USER: string = 'user';
	public static readonly TYPE_TABLE_SETTINGS: string = 'tableSettings';

	private behaviorSubjects: Map<string, BehaviorSubject<any>>;

	constructor() {
		this.behaviorSubjects = new Map<string, BehaviorSubject<any>>();
	}

	private getBehaviorSubject(identifier: string): BehaviorSubject<any> {
		let behaviorSubject: BehaviorSubject<any> = this.behaviorSubjects.get(identifier);
		if (!behaviorSubject) {
			behaviorSubject = new BehaviorSubject<any>(null);
			this.behaviorSubjects.set(identifier, behaviorSubject);
		}

		return behaviorSubject;
	}

	/**
	* Gets an item from localStorage
	* @param identifier Identifier of the storage object. Can be anything but for common used objects have a look at TYPE-Identifiers
	*/
	public getItem(identifier: string): BehaviorSubject<any> {
		const behaviorSubject = this.getBehaviorSubject(identifier);
		const item = localStorage.getItem(identifier);
		behaviorSubject.next(item);
		return behaviorSubject;
	}

	/**
	* Stores an item and emits the new value to all its subscribers
	* @param identifier LocalStorage identifier
	* @param object the object that should be stored. Please JSON.stringify if it's not a string
	*/
	public setItem(identifier: string, object: string): void {
		localStorage.setItem(identifier, object);
		this.getBehaviorSubject(identifier).next(object);
	}

	public removeItem(identifier: string): void {
		localStorage.removeItem(identifier);
		this.getBehaviorSubject(identifier).next(null);
	}

	/**
	* Clears the localStorage and tells all subscribers of all items that the value is now null.
	*/
	public clear() {
		localStorage.clear();
		this.behaviorSubjects.forEach((behaviorSubject: BehaviorSubject<any>) => {
			behaviorSubject.next(null);
		});

		console.log('local storage cleared');
	}
}
