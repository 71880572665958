
import { KeyValue } from "../key-value";
import { Vehicle } from "./vehicle";

export class Purchase {
    id: number;
	date: Date;
    expedient: string;
    purchasePrice: number;
	amountPaid: number;
    active:boolean;
    transportType:string;
    providers: Array<KeyValue>;
    vehicles: Array<Vehicle>;
    isSale: boolean;
    administrativeFileType: KeyValue;
    administrativeFileStatus: KeyValue;
    office: KeyValue;
    entity: KeyValue;
    shipmentCostType: KeyValue;
    isActive: boolean;
    frame:string;
    buyExpedients: string[];
    createdAt: Date;
    name: string;
    linkedAdminFiles: Array<KeyValue>;
    profit: number;
    amountCollected: number;
    salePrice: number;
    totalSalePrice: number;
    totalPurchasePrice: number;
    purchasePriceNoVAT: number;
    salePriceNoVAT: number;
    externalShipment: boolean;

    constructor(purchase) {
        this.id = purchase.id;
        this.date = purchase.date;
        this.expedient= purchase.expedient;
        this.purchasePrice = purchase.purchasePrice;
        this.salePrice = purchase.salePrice;
        this.amountPaid = purchase.amountPaid;
        this.amountCollected = purchase.amountCollected;
        this.active = purchase.active;
        this.providers = purchase.providers;
        this.vehicles = purchase.vehicles?.reduce((list, current) => {
            if (!list.some(x => x.vehicleVersion?.id == current.vehicleVersion?.id)) {
                let total = purchase.vehicles?.filter(x => x.vehicleVersion?.id == current.vehicleVersion?.id).length;
                if(current.vehicleVersion.name == "")
                    current.vehicleVersion.name == "Sin asignar";
                if(total > 1)
                    current.vehicleVersion.name = current.vehicleVersion.name + ' (' + total +')';
                list.push(current);
            }
            return list;
        }, []);
       //  purchase.vehicles?.map(lp => lp.vehicleVersion?.name);
        this.transportType = purchase.transportType;
        this.isSale = purchase.isSale;
        this.administrativeFileType = purchase.administrativeFileType;
        this.administrativeFileStatus = purchase.administrativeFileStatus;
        this.entity = purchase.entity;
        this.office = purchase.office;
        this.shipmentCostType = purchase.shipmentCostType;
        this.isActive = purchase.isActive;
        this.createdAt= purchase.createdAt;
        this.name= purchase.name;
        
        this.buyExpedients = purchase.buyExpedients;
        this.frame = purchase.frame;
        this.linkedAdminFiles = purchase.linkedAdminFiles;
        this.profit =  purchase.salePriceNoVAT - purchase.purchasePriceNoVAT;
        this.totalPurchasePrice =  (purchase.amountPaid - purchase.amountCollected) < 0 ? 0 : purchase.amountPaid - purchase.amountCollected;
        this.totalSalePrice =  (purchase.amountCollected - purchase.amountPaid) < 0 ? 0 :purchase.amountCollected - purchase.amountPaid;
        this.externalShipment = purchase.externalShipment;
      }
}
  
