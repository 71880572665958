import { ComboItem } from "../combo-item";
import { Entity } from "../contacts/entity";
import { CommonFilter } from "../masters/common-filter";

export class BasicsTransferData {
    values?: Entity;
    type?: string;
    
    categories: Array<ComboItem>;
    packs: Array<ComboItem>;
    filters: CommonFilter;
    constructor(data) {
        this.values = data.values;
        this.type = data.type;
        this.packs = data.packs;
        this.categories = data.categories;
        this.filters = data.filters;
       
      }
    
}