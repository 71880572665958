import { Observable } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { VehicleFilter } from "./car-type";
import { EntityFilter } from "./contact-type";
import { CostFilter } from "./cost-type";
import { ExpedientFilter } from "./expedient-type";
import { OfferFilter } from "./offer-type";
import { OperationFilter } from "./operation-type";
import { OthersType } from "./others-type";
import { AccountingEntriesFilter } from "./pay-type";

export interface IDocumentTypes {
    getFilters(): Observable<IFilters> ;
}

export const documentTypes = new Map([
    [1, VehicleFilter],
    [2, OperationFilter],
    [3, ExpedientFilter],
    [4, EntityFilter],
    [5, AccountingEntriesFilter],
    [6, OfferFilter],
    [7, CostFilter]
  ]);


  export const documentsTypes = new Map([
    [null, ""],
    [1, ""],
    [2, "proformas/maestra"],
    [3, "administrative-files/maestra"],
    [4, "entities"],
    [5, ""],
    [6, "offers"],
    [7, "accountingentries/costs"],
    [8, ""],
    [9, ""]
  ]);