<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

    <h2 *ngIf="amount" class="headline m-0 flex-auto">{{ amount || 0 | number:'1.0-2':'es' }} €</h2>
    <h2 *ngIf="!amount" class="headline m-0 flex-auto">Nuevo {{costType}}
    </h2>

    <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button> -->

    <button class="text-secondary" (click)="close()" mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <!--   <mat-divider class="-mx-6 text-border"></mat-divider> -->

  <mat-tab-group class="vex-tabs vex-tabs-dense border-0" (selectedTabChange)="loadData($event)">
    <mat-tab label="GENERAL">
      <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:info"></mat-icon>
        GENERAL
      </ng-template>
      <ng-template matTabContent>
        <mat-dialog-content class="flex flex-col pt-6">
          <div class="flex flex-col sm:flex-row">
            <mat-form-field class="sm:w-40 flex-auto">
              <mat-label>Tipo de coste</mat-label>
              <mat-select cdkFocusInitial [compareWith]="compareCategoryObjects" formControlName="costType"
                placeholder="Seleccionar tipo" required>
                <mat-option *ngFor="let item of typesItems" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('costType').hasError('required')"><strong>Tipo obligatorio</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="sm:ml-6 flex-auto">
              <mat-label>Concepto</mat-label>
              <input formControlName="name" matInput>
            </mat-form-field>
            <mat-form-field class="sm:ml-6 flex-auto">
              <mat-label>Fecha</mat-label>
              <input [matDatepicker]="basicDatepicker" formControlName="createdAt" matInput required>
              <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #basicDatepicker></mat-datepicker>
              <mat-error *ngIf="form.get('createdAt').hasError('required')"><strong>Fecha obligatoria</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="sm:ml-6 flex-auto">
              <mat-label>Importe</mat-label>
              <input *ngIf="isUpdateMode()" formControlName="amount" matInput  [readonly]=true disabled >
              <input *ngIf="!isUpdateMode()" type="number" formControlName="amount" matInput min="1" required>
              <mat-error><strong>Mayor que 0</strong></mat-error>
            </mat-form-field>
          </div>
          <div class="flex flex-col sm:flex-row">
            <mat-form-field class="w-40 flex-auto">
              <mat-label>Fecha movimiento bancario</mat-label>
              <input [matDatepicker]="basicDatepicker2" formControlName="transactionDate" matInput>
              <mat-datepicker-toggle matSuffix [for]="basicDatepicker2"></mat-datepicker-toggle>
              <mat-datepicker #basicDatepicker2></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="sm:ml-6 flex-auto" required>
              <mat-label>Tipo de contacto</mat-label>
              <mat-select #type formControlName="costEntityType" placeholder="Seleccionar tipo de contacto"
                (valueChange)="changeType($event)" [compareWith]="compareCategoryObjects">
                <mat-option *ngFor="let item of entityTypes" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('costEntityType').hasError('required')"><strong>Tipo obligatorio</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field cdkScrollable class="sm:ml-6 flex-auto" required>
              <mat-label appearance="fill">
                {{(form.get('costEntityType').value).name}} </mat-label>
              <input #autoInput type="text" [dynamicWidth]="{ minWidth: 300, maxWidth: 300 }" [required]="requiredReference"
                placeholder="{{type?.value?.name}}" matInput aria-label="Number" [matAutocomplete]="autoModel"
                formControlName="entity" (input)="SearchWeightuggestions($event.target.value)">
              <mat-autocomplete (optionSelected)="optionSelected(inputArticle)"  showPanel="true" (optionsScroll)="onScroll()" #autoModel="matAutocomplete"
                panelWidth="auto" showPanel="true" [displayWith]="displayFn" class="mat-select-panel flex-auto">
                <mat-option *ngFor="let item of filteredOptions | async" [value]="item" required>
                  {{item.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>



          <mat-form-field class="flex-auto">
            <mat-label>Notas</mat-label>
            <textarea formControlName="remarks" matInput></textarea>
          </mat-form-field>
        </mat-dialog-content>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="!readOnly" label="CONCEPTOS" [disabled]='isCreateMode()'>
      <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:description"></mat-icon>
        CONCEPTOS
      </ng-template>
      <ng-template matTabContent>
        <vex-concepts [(filters)]="filters" [amount]="form.get('amount').value" [readOnly]="!editabled"
          [invoices]="invoicesTableData" (updatedSettledAmount)="getIsUpdatedConceptValue($event)"></vex-concepts>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="!readOnly" label="EXPEDIENTES" [disabled]='isCreateMode()'>
      <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:line_style"></mat-icon>
        EXPEDIENTES
      </ng-template>
      <ng-template matTabContent>
        <vex-invoices [(filters)]="filters" [type]="" [service]="administrativeFiles" [readOnly]="!editabled"></vex-invoices>
      </ng-template>
    </mat-tab>

    <mat-tab *ngIf="!readOnly && !adminManager" label="MOVIMIENTOS BANCARIOS" [disabled]='isCreateMode()'>
      <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:account_balance"></mat-icon>
        MOVIMIENTOS BANCARIOS
      </ng-template>
      <ng-template matTabContent>
        <vex-transactions [(filters)]="filters" [amount]="getSettledAmount()" [amountSett]="amountSett" 
          (updatedSettledAmount)="getIsUpdatedValue($event)" [invoices]="invoicesTableData" 
          [(sign)]="sign"></vex-transactions>
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="!readOnly" label="DOCUMENTOS" [disabled]='isCreateMode()'>
      <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:attach_file"></mat-icon>
        DOCUMENTOS
      </ng-template>
      <ng-template matTabContent>
        <vex-documents [isMenu]="false" [(documentType)]="documentType" [(reference)]="reference" [editabled]="editabled"></vex-documents>
      </ng-template>
    </mat-tab>
    <!--  <mat-tab label="FACTURACIÓN"
    *ngIf="form.get('costType').value === 'Cliente'">
      <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:money"></mat-icon>
        FACTURACIÓN
      </ng-template>
      <vex-banks [isMenu]="false" [tableData]="banksTableData"></vex-banks>
    </mat-tab> -->
  </mat-tab-group>

  <mat-dialog-actions align="end">
    <button mat-button (click)="close()" type="button">Cancelar</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Nuevo
      {{costType}}</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Actualizar
      {{costType}}</button>
  </mat-dialog-actions>
</form>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exportar</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Eliminar</span>
  </button>
</mat-menu> -->