<!-- <vex-page-layout> -->
  <!--  <vex-page-layout-header *ngIf="this.mode === 'menu'" class="h-24 flex flex-col items-start justify-center">
    <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="title mt-2 mb-1">De optional</h1>
        <vex-breadcrumbs [crumbs]="['Apps', 'Optionals Table']"></vex-breadcrumbs>
      </div>

      <div class="hidden sm:block">
        <mat-button-toggle-group [formControl]="layoutCtrl" class="mt-2 sm:mt-0">
          <mat-button-toggle value="boxed">Reducir</mat-button-toggle>
          <mat-button-toggle value="fullwidth">Ampliar</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </vex-page-layout-header> -->
<!--   <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6"> -->

    <form  (ngSubmit)="save()" [formGroup]="form">
      <div class="flex items-center" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

        <h2 *ngIf="form.get('name').value" class="headline m-0 flex-auto">{{ form.get('name').value }}
        </h2>
        <h2 *ngIf="!form.get('name').value" class="headline m-0 flex-auto">Nuevo equipamiento de optional
        </h2>

        <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
          <mat-icon svgIcon="mat:more_vert"></mat-icon>
        </button>-->
        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>

      <!--   <mat-divider class="-mx-6 text-border"></mat-divider> -->

      <!--   <mat-tab-group class="vex-tabs vex-tabs-dense border-0">
        <mat-tab label="DE SERIE">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:build"></mat-icon>
            DE SERIE
          </ng-template> -->
      <mat-dialog-content class="flex flex-col pt-6">
        <div class="flex flex-col sm:flex-row">
          <mat-form-field class="flex-auto w-24">
            <mat-label>Nombre</mat-label>
            <input type="text" cdkFocusInitial formControlName="name" matInput required>
            <mat-error *ngIf="form.get('name').hasError('required')"><strong>Nombre obligatorio</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="sm:ml-6 flex-auto">
            <mat-label>Fecha</mat-label>
            <input [matDatepicker]="basicDatepicker" formControlName="createdAt" matInput required>
            <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #basicDatepicker></mat-datepicker>
            <mat-error *ngIf="form.get('createdAt').hasError('required')"><strong>Fecha obligatoria</strong>
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="sm:ml-6 flex-auto">
            <mat-label>Modelo</mat-label>
            <mat-select #sl formControlName="vehicleVersion" placeholder="Seleccionar modelo" required
              [compareWith]="compareCategoryObjects" (valueChange)="changeVersion($event)">
              <mat-option *ngFor="let item of vehicleVersions" [value]="item">
                {{item.name}}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="form.get('vehicleVersion')?.hasError('required')"><strong>Modelo
                obligatorio</strong>
            </mat-error>
          </mat-form-field>
 -->
        </div>
        <div class="flex flex-col sm:flex-row">
          <mat-form-field class="flex-auto">
            <mat-label>Versión</mat-label>
            <input #inputModel type="text" placeholder="Versión" matInput aria-label="Number" [dynamicWidth]="{ minWidth: 200, maxWidth: 200 }"
            formControlName="vehicleVersion" [(ngModel)]="model" (ngModelChange)="changeVersion($event)" [matAutocomplete]="autoModel" 
            required (click)="clearModels($event)">
          <mat-autocomplete panelWidth="auto" #autoModel="matAutocomplete" [displayWith]="displayFn" class="mat-select-panel flex-auto" 
            (optionSelected)="optionSelected(inputModel)">
            <mat-option *ngFor="let model of filteredModels | async" [value]="model">
              {{model.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('vehicleVersion').hasError('required')"><strong>Versión obligatoria</strong>
          </mat-error>
          </mat-form-field>
        </div>


      </mat-dialog-content>
      <div class="overflow-auto max-h-[20rem] min-w-[200px]">
      <vex-basics-list (Change)="getTableData($event)" [(tableData)]="optionalEquipmentHasBasics"
      [(packs)]="packs" [(categories)]="categories"></vex-basics-list>
      </div>
      <!--   </mat-tab>
         <mat-tab label="BÁSICOS"> -->
      <!--   <ng-template mat-tab-label>
        <mat-icon class="mr-2" matPrefix svgIcon="mat:info"></mat-icon>
        BÁSICOS
      </ng-template> -->

      <!--   </mat-tab> -->
      <!--<mat-tab label="DE SERIE">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:build"></mat-icon>
            DE SERIE
          </ng-template>
          <vex-optionals></vex-optionals>
        </mat-tab>
        <mat-tab label="OPCIONALES">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:more"></mat-icon>
            OPCIONALES
          </ng-template>
          <vex-optionals></vex-optionals>
        </mat-tab>
        <mat-tab label="IMÁGENES">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:image"></mat-icon>
            IMÁGENES
          </ng-template>
            <vex-images-grid></vex-images-grid>
        </mat-tab>
        <mat-tab label="NOTAS">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:notes"></mat-icon>
            NOTAS
          </ng-template>
          <vex-notes></vex-notes>
        </mat-tab>
        <mat-tab label="DOCUMENTOS">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:attach_file"></mat-icon>
            DOCUMENTOS
          </ng-template>
          <vex-documents [isMenu]="false" [tableData]="documentsTableData"></vex-documents>
        </mat-tab>
        <mat-tab label="COMERCIALES">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:card_travel"></mat-icon>
            COMERCIALES
          </ng-template>
          <vex-commercials></vex-commercials>
        </mat-tab>
        <mat-tab label="VENTAS">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:receipt"></mat-icon>
            VENTAS
          </ng-template>
          <vex-sells></vex-sells>
        </mat-tab> -->
      <!--  </mat-tab-group> -->

      <mat-dialog-actions align="end" class="float-right">
        <button mat-flat-button mat-dialog-close type="button" class="m-1">
          <mat-icon class="mr-2" svgIcon="mat:cancel"></mat-icon>
          <span>Cancelar</span>
        </button>
        <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="button" (click)="duplicateOptional()" [disabled]="!form.valid || setModel()" class="m-1">
          <mat-icon class="mr-2" svgIcon="mat:file_copy"></mat-icon>
          <span>Duplicar</span>
        </button>
        <button color="primary" mat-flat-button type="submit" [disabled]="!form.valid || setModel()" class="m-1">
          <mat-icon class="mr-2" svgIcon="mat:save"></mat-icon>
          <span>Grabar</span>
        </button>
        <!--    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Actualizar
      {{optionalType}}</button> -->
      </mat-dialog-actions>
    </form>
<!--   </vex-page-layout-content>
</vex-page-layout> -->

<!-- 
<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exportar</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Eliminar</span>
  </button>
</mat-menu> -->