import { CommonModule, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BreadcrumbsModule } from "src/@vex/components/breadcrumbs/breadcrumbs.module";
import { PageLayoutModule } from "src/@vex/components/page-layout/page-layout.module";
import { ListComponent } from './list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';

import { ListRoutingModule } from "./list-routing.module";
import { ConfirmDialogModule } from "src/app/pages/pages/administration/pays-charges/vehicles/confirm-dialog/confirm-dialog.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CreateUpdateModule } from "../create-update/create-update.module";



@NgModule({
    imports: [
        CommonModule,
        ListRoutingModule,
        PageLayoutModule,
        BreadcrumbsModule,
        MatPaginatorModule,
        MatTableModule,
        MatChipsModule,
        MatSortModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        FormsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonToggleModule,
        ConfirmDialogModule,
        DragDropModule,
        CreateUpdateModule
    ],
    declarations: [ListComponent],
    exports: [ListComponent],
    providers: [DecimalPipe]
  })
  export class ListModule {
  }
  