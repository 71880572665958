import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { EntityFilters } from "src/app/models/contacts/entity-filters";
import { ICostTypes } from "./cost-types";
import { IContactTypes } from "./contact-types";
import { EmptyKeyValue } from "src/app/models/key-value";
import { VehicleFilters } from "src/app/models/vehicles/vehicle-filters";

/* @Injectable()
export class CommercialType implements IContactTypes {
    public getItems(): Observable<Array<ComboItem>> {
        return of(commercialsTableData.map(({ id: id, name: value }) => ({ id, value })));
    }
} */

@Injectable()
export class OtherType implements IContactTypes {
    public getFilters(): Observable<IFilters> {
        return of(new EntityFilters(null));;
}
}