import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableRoutingModule } from './routing.module';
import { TableComponent } from './table.component';
import { CreateUpdateModule } from './create-update/create-update.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { ConfirmDialogModule } from 'src/app/pages/apps/common-dialogs/confirm-dialog/confirm-dialog.module';
import { ListModule } from './list/list.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from 'src/app/services/interceptor/loading.interceptor';




@NgModule({
 /*  providers:[{
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  }], */
  imports: [
    CommonModule,
    TableRoutingModule,
    PageLayoutModule,
    BreadcrumbsModule,
    CreateUpdateModule,
    ListModule,
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonToggleModule,
    ConfirmDialogModule
  ],
  declarations: [TableComponent],
  exports: [TableComponent]
})
export class TableModule {
}
