import { IFilters } from "src/app/interfaces/ifilters";
import { KeyValue } from "../key-value";


export class PayChargeFilters implements IFilters{
    rowsPerPage: number = null;
    pageNumber: number = null;   
    orderBy: string = "";
    order: string = "asc";
    name:string = "";
    
accountingEntryTypeId: number = null;
administrativeFileId: number= null;
proformaInvoiceId: number= null;
remarksLike:  string = "";
createdAt: Date= null;
accountingEntryId: number = null;
proformaInvoices?: Array<KeyValue> = [];
orderType?:KeyValue = null;
  id?: number;
  bankId?: number = null;
  amount?: number = null;
  transactionDate?: Date = null;
  officeId?: number = null;
  sign?: number = null;
conceptLike?: string = "";
}