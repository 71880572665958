export const PaysChargesTableData = [{
    "createdAt": "2022-07-27T17:48:00.361Z",
    "updatedAt": "2022-07-27T17:48:00.361Z",
    vehicles: [],
    transactions: [],
    "id": 2447,
    "amount": 150.00,
    "date": "2022-07-27T17:47:52.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 263,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200031",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200023',
        invoice: 'OPVC2200031',
        type: 'Compra',
        "amount": 150.00,
        "total": 150.00
    },
    {
        id: 1,
        expedient: 'EXVC2200023',
        invoice: 'OPVC2200032',
        type: 'Compra',
        "amount": 150.00,
        "total": 150.00
    }],
    "operation": {
        "createdAt": "2022-01-12T11:56:52.465Z",
        "updatedAt": "2022-01-12T11:56:52.465Z",
        vehicles: [],
        transactions: [],
        "id": 263,
        "expedient": "OPVC2200031",
        "resourceId": 258,
        "type": "broker",
        "serieId": null,
        "date": "2022-01-12T11:56:52.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": null,
        "brokerId": null,
        "contactId": 207,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-01-12T11:55:09.724Z",
            "updatedAt": "2022-01-12T11:55:09.724Z",
            vehicles: [],
            transactions: [],
            "id": 258,
            "expedient": "EXVC2200023",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    /*  "expedient": "EXVC2200023", */
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 0,
    "toLiquidate": 150,
    confirm: false
}, {
    "createdAt": "2022-07-27T14:54:26.195Z",
    "updatedAt": "2022-07-27T14:54:26.195Z",
    vehicles: [],
    transactions: [],
    "id": 2445,
    "amount": 30000.00,
    "date": "2022-07-27T14:49:24.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 202,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200002",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200002',
        invoice: 'OPVV2200002',
        type: 'Venta',
        "amount": 30000.00,
        "total": 30000.00
    }],
    "operation": {
        "createdAt": "2022-01-07T13:00:29.522Z",
        "updatedAt": "2022-01-07T13:11:00.000Z",
        vehicles: [],
        transactions: [],
        "id": 202,
        "expedient": "OPVV2200002",
        "resourceId": 209,
        "type": "car",
        "serieId": 3,
        "date": "2022-01-07T13:00:29.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 18,
        "brokerId": null,
        "contactId": 2386,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-01-07T11:58:52.064Z",
            "updatedAt": "2022-01-07T16:09:44.000Z",
            vehicles: [],
            transactions: [],
            "id": 209,
            "expedient": "EXVV2200002",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200002",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 0,
    "toLiquidate": 30000,
    confirm: false
}, {
    "createdAt": "2022-07-27T10:41:26.607Z",
    "updatedAt": "2022-07-27T10:41:26.607Z",
    vehicles: [],
    transactions: [],
    "id": 2442,
    "amount": 1000.00,
    "date": "2022-07-27T10:41:17.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 263,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200031",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200023',
        invoice: 'OPVC2200031',
        type: 'Compra',
        "amount": 1000.00,
        "total": 1000.00
    }],
    "operation": {
        "createdAt": "2022-01-12T11:56:52.465Z",
        "updatedAt": "2022-01-12T11:56:52.465Z",
        vehicles: [],
        transactions: [],
        "id": 263,
        "expedient": "OPVC2200031",
        "resourceId": 258,
        "type": "broker",
        "serieId": null,
        "date": "2022-01-12T11:56:52.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": null,
        "brokerId": null,
        "contactId": 207,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-01-12T11:55:09.724Z",
            "updatedAt": "2022-01-12T11:55:09.724Z",
            vehicles: [],
            transactions: [],
            "id": 258,
            "expedient": "EXVC2200023",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200023",
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 0,
    "toLiquidate": 1000,
    confirm: false

}, {
    "createdAt": "2022-07-27T09:55:51.783Z",
    "updatedAt": "2022-07-27T09:55:51.783Z",
    vehicles: [],
    transactions: [],
    "id": 2435,
    "amount": 400.00,
    "date": "2022-07-27T09:55:51.000Z",
    "done": false,
    "isTotal": true,
    "operationId": 2077,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200785",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200520',
        invoice: 'OPVV2200785',
        type: 'Venta',
        "amount": 400.00,
        "total": 400.00
    }],
    "operation": {
        "createdAt": "2022-07-26T10:38:20.605Z",
        "updatedAt": "2022-07-26T10:38:20.605Z",
        vehicles: [],
        transactions: [],
        "id": 2077,
        "expedient": "OPVV2200785",
        "resourceId": 1219,
        "type": "other",
        "serieId": 3,
        "date": "2022-07-26T10:37:52.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": null,
        "brokerId": null,
        "contactId": 121,
        "articleId": 3,
        "titleId": 7,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-06T15:55:25.840Z",
            "updatedAt": "2022-07-06T15:55:25.840Z",
            vehicles: [],
            transactions: [],
            "id": 1219,
            "expedient": "EXVV2200520",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200520",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 400,
    "toLiquidate": 0,
    confirm: true
}, {
    "createdAt": "2022-07-27T08:31:19.505Z",
    "updatedAt": "2022-07-27T08:31:19.505Z",
    vehicles: [],
    transactions: [],
    "id": 2434,
    "amount": 64050.00,
    "date": "2022-07-27T08:31:12.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2041,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": "DEPOSITO DI 5% / VETTURA",
    "invoice": "OPVV2200774",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200517',
        invoice: 'OPVV2200774',
        type: 'Venta',
        "amount": 64050.00,
        "total": 64050.00
    }],
    "operation": {
        "createdAt": "2022-07-01T14:50:56.269Z",
        "updatedAt": "2022-07-04T09:10:07.000Z",
        vehicles: [],
        transactions: [],
        "id": 2041,
        "expedient": "OPVV2200774",
        "resourceId": 1213,
        "type": "car",
        "serieId": 3,
        "date": "2022-07-01T14:50:56.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "DEPOSITO DI 5% / VETTURA. IN CASO DI RINUNCIA ALL’ACQUISTO DELLE VETTURE, IL DEPOSITO NON VERRA RESTITUITO",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": null,
        "brokerId": null,
        "contactId": 2882,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-01T14:50:31.835Z",
            "updatedAt": "2022-07-01T14:50:31.835Z",
            vehicles: [],
            transactions: [],
            "id": 1213,
            "expedient": "EXVV2200517",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200517",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 64050,
    "toLiquidate": 0,
    confirm: true,
}, {
    "createdAt": "2022-07-27T07:29:23.474Z",
    "updatedAt": "2022-07-27T07:29:23.474Z",
    vehicles: [],
    transactions: [],
    "id": 2432,
    "amount": 2000.00,
    "date": "2022-07-27T07:29:14.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2039,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200548",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200292',
        invoice: 'OPVC2200548',
        type: 'Venta',
        "amount": 2000.00,
        "total": 2000.00
    }],
    "operation": {
        "createdAt": "2022-07-01T14:48:58.960Z",
        "updatedAt": "2022-07-01T14:48:58.960Z",
        vehicles: [],
        transactions: [],
        "id": 2039,
        "expedient": "OPVC2200548",
        "resourceId": 1212,
        "type": "car",
        "serieId": null,
        "date": "2022-07-01T14:48:58.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": 15,
        "brokerId": null,
        "contactId": 2433,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-01T14:46:19.325Z",
            "updatedAt": "2022-07-01T14:46:19.325Z",
            vehicles: [],
            transactions: [],
            "id": 1212,
            "expedient": "EXVC2200292",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200292",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 287.72,
    "toLiquidate": 1712.28,
    confirm: false
}, {
    "createdAt": "2022-07-27T07:29:00.249Z",
    "updatedAt": "2022-07-27T07:29:00.249Z",
    vehicles: [],
    transactions: [],
    "id": 2431,
    "amount": 1000.00,
    "date": "2022-07-27T07:28:40.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2039,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200548",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200292',
        invoice: 'OPVC2200548',
        type: 'Compra',
        "amount": 1000.00,
        "total": 1000.00
    }],
    "operation": {
        "createdAt": "2022-07-01T14:48:58.960Z",
        "updatedAt": "2022-07-01T14:48:58.960Z",
        vehicles: [],
        transactions: [],
        "id": 2039,
        "expedient": "OPVC2200548",
        "resourceId": 1212,
        "type": "car",
        "serieId": null,
        "date": "2022-07-01T14:48:58.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": 15,
        "brokerId": null,
        "contactId": 2433,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-01T14:46:19.325Z",
            "updatedAt": "2022-07-01T14:46:19.325Z",
            vehicles: [],
            transactions: [],
            "id": 1212,
            "expedient": "EXVC2200292",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200292",
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 1950,
    "toLiquidate": -950,
    confirm: false
}, {
    "createdAt": "2022-07-26T14:30:33.432Z",
    "updatedAt": "2022-07-26T14:30:33.432Z",
    vehicles: [],
    transactions: [],
    "id": 2430,
    "amount": 10000.00,
    "date": "2022-07-26T14:30:24.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2056,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": "",
    "invoice": "OPVV2200779",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200518',
        invoice: 'OPVV2200779',
        type: 'Venta',
        "amount": 10000.00,
        "total": 10000.00
    }],
    "operation": {
        "createdAt": "2022-07-04T15:06:36.748Z",
        "updatedAt": "2022-07-04T15:07:58.000Z",
        vehicles: [],
        transactions: [],
        "id": 2056,
        "expedient": "OPVV2200779",
        "resourceId": 1215,
        "type": "car",
        "serieId": 3,
        "date": "2022-07-04T15:06:36.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "LA PROFORMA EST VALIDÉ UNIQUEMENT À RÉCEPTION DE L'ACOMPTE DEMANDÉ 500€/AUTO. VG RENT A CAR, SL SE RÉSERVE LE DROIT DE GARDER L'ACOMPTE EN CAS D'ANNULATION DE PROFORMA DE LA PART DU CLIENT.",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 13,
        "brokerId": null,
        "contactId": 432,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-04T15:06:17.499Z",
            "updatedAt": "2022-07-04T15:06:17.499Z",
            vehicles: [],
            transactions: [],
            "id": 1215,
            "expedient": "EXVV2200518",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200518",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 0,
    "toLiquidate": 10000,
    confirm: false
}, {
    "createdAt": "2022-07-26T11:24:46.290Z",
    "updatedAt": "2022-07-26T11:24:46.290Z",
    vehicles: [],
    transactions: [],
    "id": 2429,
    "amount": 1000.00,
    "date": "2022-07-26T11:24:30.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2072,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200560",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200295',
        invoice: 'OPVC2200560',
        type: 'Compra',
        "amount": 1000.00,
        "total": 1000.00
    }],
    "operation": {
        "createdAt": "2022-07-06T15:48:51.212Z",
        "updatedAt": "2022-07-06T15:48:51.212Z",
        vehicles: [],
        transactions: [],
        "id": 2072,
        "expedient": "OPVC2200560",
        "resourceId": 1218,
        "type": "car",
        "serieId": null,
        "date": "2022-07-06T15:48:51.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": null,
        "brokerId": null,
        "contactId": 2854,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-06T15:47:48.756Z",
            "updatedAt": "2022-07-06T15:47:48.756Z",
            vehicles: [],
            transactions: [],
            "id": 1218,
            "expedient": "EXVC2200295",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200295",
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 0,
    "toLiquidate": 1000,
    confirm: false
}, {
    "createdAt": "2022-07-25T18:25:58.047Z",
    "updatedAt": "2022-07-25T18:25:58.047Z",
    vehicles: [],
    transactions: [],
    "id": 2428,
    "amount": 1000.00,
    "date": "2022-07-25T18:25:24.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2055,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200550",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200293',
        invoice: 'OPVC2200550',
        type: 'Compra',
        "amount": 1000.00,
        "total": 1000.00
    }],
    "operation": {
        "createdAt": "2022-07-04T15:04:33.652Z",
        "updatedAt": "2022-07-04T15:04:33.652Z",
        vehicles: [],
        transactions: [],
        "id": 2055,
        "expedient": "OPVC2200550",
        "resourceId": 1214,
        "type": "car",
        "serieId": null,
        "date": "2022-07-04T15:04:33.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": 20,
        "brokerId": null,
        "contactId": 2451,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-04T15:02:53.360Z",
            "updatedAt": "2022-07-04T15:02:53.360Z",
            vehicles: [],
            transactions: [],
            "id": 1214,
            "expedient": "EXVC2200293",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200293",
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 0,
    "toLiquidate": 1000,
    confirm: false
}, {
    "createdAt": "2022-07-08T12:30:18.408Z",
    "updatedAt": "2022-07-08T12:30:18.408Z",
    vehicles: [],
    transactions: [],
    "id": 2426,
    "amount": 2000.00,
    "date": "2022-07-08T12:30:12.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2071,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": "DEPOSITO DI 5%",
    "invoice": "OPVV2200783",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200519',
        invoice: 'OPVV2200783',
        type: 'Venta',
        "amount": 2000.00,
        "total": 2000.00
    }],
    "operation": {
        "createdAt": "2022-07-06T10:57:06.593Z",
        "updatedAt": "2022-07-06T10:58:00.000Z",
        vehicles: [],
        transactions: [],
        "id": 2071,
        "expedient": "OPVV2200783",
        "resourceId": 1217,
        "type": "car",
        "serieId": 3,
        "date": "2022-07-06T10:57:06.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "DEPOSITO DI 5%. IN CASO DI RINUNCIA ALL'ACQUISTO DELLE VETTURE, IL DEPOSITO NON VERRA RESTITUITO. ",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "6",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 17,
        "brokerId": null,
        "contactId": 2494,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-06T10:56:42.656Z",
            "updatedAt": "2022-07-06T10:56:42.656Z",
            vehicles: [],
            transactions: [],
            "id": 1217,
            "expedient": "EXVV2200519",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200519",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 2000,
    "toLiquidate": 0,
    confirm: true,
}, {
    "createdAt": "2022-07-08T12:29:51.538Z",
    "updatedAt": "2022-07-08T12:29:51.538Z",
    vehicles: [],
    transactions: [],
    "id": 2425,
    "amount": 1000.00,
    "date": "2022-07-08T12:29:43.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2071,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": "DEPOSITO DI 5%",
    "invoice": "OPVV2200783",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200519',
        invoice: 'OPVV2200783',
        type: 'Venta',
        "amount": 1000.00,
        "total": 1000.00
    }],
    "operation": {
        "createdAt": "2022-07-06T10:57:06.593Z",
        "updatedAt": "2022-07-06T10:58:00.000Z",
        vehicles: [],
        transactions: [],
        "id": 2071,
        "expedient": "OPVV2200783",
        "resourceId": 1217,
        "type": "car",
        "serieId": 3,
        "date": "2022-07-06T10:57:06.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "DEPOSITO DI 5%. IN CASO DI RINUNCIA ALL'ACQUISTO DELLE VETTURE, IL DEPOSITO NON VERRA RESTITUITO. ",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "6",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 17,
        "brokerId": null,
        "contactId": 2494,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-06T10:56:42.656Z",
            "updatedAt": "2022-07-06T10:56:42.656Z",
            vehicles: [],
            transactions: [],
            "id": 1217,
            "expedient": "EXVV2200519",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200519",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 1000,
    "toLiquidate": 0,
    confirm: true,
}, {
    "createdAt": "2022-07-08T12:29:16.844Z",
    "updatedAt": "2022-07-08T12:29:16.844Z",
    vehicles: [],
    transactions: [],
    "id": 2424,
    "amount": 30000.00,
    "date": "2022-07-08T12:29:16.000Z",
    "done": false,
    "isTotal": true,
    "operationId": 2073,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200784",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200520',
        invoice: 'OPVV2200784',
        type: 'Venta',
        "amount": 30000.00,
        "total": 30000.00
    }],
    "operation": {
        "createdAt": "2022-07-06T15:57:34.829Z",
        "updatedAt": "2022-07-06T15:58:54.000Z",
        vehicles: [],
        transactions: [],
        "id": 2073,
        "expedient": "OPVV2200784",
        "resourceId": 1219,
        "type": "car",
        "serieId": 3,
        "date": "2022-07-06T15:57:34.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "6",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 17,
        "brokerId": null,
        "contactId": 336,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-07-06T15:55:25.840Z",
            "updatedAt": "2022-07-06T15:55:25.840Z",
            vehicles: [],
            transactions: [],
            "id": 1219,
            "expedient": "EXVV2200520",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200520",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 30000,
    "toLiquidate": 0,
    confirm: true,
}, {
    "createdAt": "2022-07-06T10:17:56.298Z",
    "updatedAt": "2022-07-06T10:17:56.298Z",
    vehicles: [],
    transactions: [],
    "id": 2423,
    "amount": 66950.00,
    "date": "2022-07-06T10:17:50.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 451,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200129",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200106',
        invoice: 'OPVV2200129',
        type: 'Venta',
        "amount": 66950.00,
        "total": 66950.00
    }],
    "operation": {
        "createdAt": "2022-01-19T16:14:25.177Z",
        "updatedAt": "2022-06-28T12:29:05.000Z",
        vehicles: [],
        transactions: [],
        "id": 451,
        "expedient": "OPVV2200129",
        "resourceId": 391,
        "type": "car",
        "serieId": 3,
        "date": "2022-01-19T16:14:25.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "111",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 16,
        "brokerId": null,
        "contactId": 2406,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-01-19T16:13:39.487Z",
            "updatedAt": "2022-06-29T17:26:21.000Z",
            vehicles: [],
            transactions: [],
            "id": 391,
            "expedient": "EXVV2200106",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200106",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 0,
    "toLiquidate": 66950,
    confirm: false
}, {
    "createdAt": "2022-07-06T10:17:22.839Z",
    "updatedAt": "2022-07-06T10:17:22.839Z",
    vehicles: [],
    transactions: [],
    "id": 2422,
    "amount": 3500.00,
    "date": "2022-07-06T10:17:17.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 2058,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200780",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200489',
        invoice: 'OPVV2200780',
        type: 'Venta',
        "amount": 3500.00,
        "total": 3500.00
    }],
    "operation": {
        "createdAt": "2022-07-05T10:54:44.167Z",
        "updatedAt": "2022-07-05T10:55:31.000Z",
        vehicles: [],
        transactions: [],
        "id": 2058,
        "expedient": "OPVV2200780",
        "resourceId": 1153,
        "type": "transport",
        "serieId": 5,
        "date": "2022-06-07T15:05:54.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": 194,
        "brokerId": null,
        "contactId": 120,
        "articleId": 3,
        "titleId": 6,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-06-07T15:05:25.849Z",
            "updatedAt": "2022-07-05T10:54:44.000Z",
            vehicles: [],
            transactions: [],
            "id": 1153,
            "expedient": "EXVV2200489",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200489",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 3500,
    "toLiquidate": 0,
    confirm: true
}, {
    "createdAt": "2022-07-06T10:16:55.714Z",
    "updatedAt": "2022-07-06T10:16:55.714Z",
    vehicles: [],
    transactions: [],
    "id": 2421,
    "amount": 16000.00,
    "date": "2022-07-06T10:16:51.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 221,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": "VALIDÉ UNIQUEMENT À R'ECEPTION DE L'ACOMPTE DEMANDÉ",
    "invoice": "OPVV2200011",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200009',
        invoice: 'OPVV2200011',
        type: 'Venta',
        "amount": 16000.00,
        "total": 16000.00
    }],
    "operation": {
        "createdAt": "2022-01-10T14:31:30.820Z",
        "updatedAt": "2022-07-04T14:58:48.000Z",
        vehicles: [],
        transactions: [],
        "id": 221,
        "expedient": "OPVV2200011",
        "resourceId": 225,
        "type": "car",
        "serieId": 3,
        "date": "2022-01-10T14:31:30.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "LA PROFORMA EST VALIDÉ UNIQUEMENT À R'ECEPTION DE L'ACOMPTE DEMANDÉ  1.000€/AUTO. VG RENT A CAR, SL SE RÉSERVE LE DROIT DE GARDER L'ACOMPTE EN CAS D'ANNULATION DE PROFORMA DE LA PART DU CLIENT.",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 18,
        "brokerId": null,
        "contactId": 2764,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-01-10T14:29:44.693Z",
            "updatedAt": "2022-01-10T14:29:44.693Z",
            vehicles: [],
            transactions: [],
            "id": 225,
            "expedient": "EXVV2200009",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200009",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 0,
    "toLiquidate": 16000,
    confirm: false
}, {
    "createdAt": "2022-07-06T10:16:17.113Z",
    "updatedAt": "2022-07-06T10:16:17.113Z",
    vehicles: [],
    transactions: [],
    "id": 2420,
    "amount": 2000.00,
    "date": "2022-07-06T10:16:12.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 237,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200020",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200016',
        invoice: 'OPVV2200020',
        type: 'Venta',
        "amount": 2000.00,
        "total": 2000.00
    }],
    "operation": {
        "createdAt": "2022-01-11T14:31:47.553Z",
        "updatedAt": "2022-07-04T14:42:25.000Z",
        vehicles: [],
        transactions: [],
        "id": 237,
        "expedient": "OPVV2200020",
        "resourceId": 238,
        "type": "car",
        "serieId": 3,
        "date": "2022-01-11T14:31:47.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": "",
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": "",
        "commissionBroker": null,
        "observations": null,
        "commercialId": 18,
        "brokerId": null,
        "contactId": 2764,
        "articleId": 4,
        "titleId": 5,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-01-11T14:25:42.167Z",
            "updatedAt": "2022-01-12T13:56:08.000Z",
            vehicles: [],
            transactions: [],
            "id": 238,
            "expedient": "EXVV2200016",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200016",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 0,
    "toLiquidate": 2000,
    confirm: false
}, {
    "createdAt": "2022-07-06T10:15:48.334Z",
    "updatedAt": "2022-07-06T10:15:48.334Z",
    vehicles: [],
    transactions: [],
    "id": 2419,
    "amount": 46650.00,
    "date": "2022-07-06T10:15:44.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 1855,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVV2200708",
    invoices: [{
        id: 1,
        expedient: 'EXVV2200481',
        invoice: 'OPVV2200708',
        type: 'Venta',
        "amount": 46650.00,
        "total": 46650.00
    }],
    "operation": {
        "createdAt": "2022-06-01T10:52:47.329Z",
        "updatedAt": "2022-06-01T11:33:15.000Z",
        vehicles: [],
        transactions: [],
        "id": 1855,
        "expedient": "OPVV2200708",
        "resourceId": 1130,
        "type": "car",
        "serieId": 3,
        "date": "2022-06-01T10:52:47.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": 2728,
        "brokerId": null,
        "contactId": 2813,
        "articleId": 3,
        "titleId": 4,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-06-01T10:44:41.091Z",
            "updatedAt": "2022-06-01T10:44:41.091Z",
            vehicles: [],
            transactions: [],
            "id": 1130,
            "expedient": "EXVV2200481",
            "isClosed": false,
            "type": {
    "id": 2,
	"name": "Venta"
	},
            "transportType": "inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVV2200481",
    "isClosed": false,
    "type": {
    "id": 2,
	"name": "Venta"
	},
    "settled": 0,
    "toLiquidate": 46650,
    confirm: false
}, {
    "createdAt": "2022-07-05T16:53:44.874Z",
    "updatedAt": "2022-07-05T16:53:44.874Z",
    vehicles: [],
    transactions: [],
    "id": 2418,
    "amount": 35492.47,
    "date": "2022-07-05T16:53:38.000Z",
    "done": false,
    "isTotal": false,
    "operationId": 1996,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200537",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200283',
        invoice: 'OPVC2200537',
        type: 'Compra',
        "amount": 35492.47,
        "total": 35492.47
    }],
    "operation": {
        "createdAt": "2022-06-21T10:12:58.451Z",
        "updatedAt": "2022-06-21T10:12:58.451Z",
        vehicles: [],
        transactions: [],
        "id": 1996,
        "expedient": "OPVC2200537",
        "resourceId": 1187,
        "type": "car",
        "serieId": null,
        "date": "2022-06-21T10:12:58.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": 20,
        "brokerId": null,
        "contactId": 2804,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-06-21T10:11:29.986Z",
            "updatedAt": "2022-06-21T10:11:29.986Z",
            vehicles: [],
            transactions: [],
            "id": 1187,
            "expedient": "EXVC2200283",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200283",
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 0,
    "toLiquidate": 35492.47,
    confirm: false
}, {
    "createdAt": "2022-07-05T15:47:28.011Z",
    "updatedAt": "2022-07-05T15:47:28.011Z",
    vehicles: [],
    transactions: [],
    "id": 2416,
    "amount": 2300.00,
    "date": "2022-07-05T15:47:28.000Z",
    "done": false,
    "isTotal": true,
    "operationId": 2068,
    "bankProductId": null,
    "tenantId": 1,
    "remarks": null,
    "invoice": "OPVC2200558",
    invoices: [{
        id: 1,
        expedient: 'EXVC2200153',
        invoice: 'OPVC2200558',
        type: 'Compra',
        "amount": 2300.00,
        "total": 2300.00
    }],
    "operation": {
        "createdAt": "2022-07-05T15:35:59.636Z",
        "updatedAt": "2022-07-05T15:35:59.636Z",
        vehicles: [],
        transactions: [],
        "id": 2068,
        "expedient": "OPVC2200558",
        "resourceId": 709,
        "type": "transport",
        "serieId": null,
        "date": "2022-07-05T15:35:59.000Z",
        "dateOrder": null,
        "importDamage": null,
        "reference": null,
        "remarks": null,
        "observationsCommercial": null,
        "vat": null,
        "commissionCommercial": null,
        "commissionBroker": null,
        "observations": null,
        "commercialId": null,
        "brokerId": null,
        "contactId": 1244,
        "articleId": null,
        "titleId": null,
        "tenantId": 1,
        "resource": {
            "createdAt": "2022-02-16T09:21:16.973Z",
            "updatedAt": "2022-02-16T09:21:16.973Z",
            vehicles: [],
            transactions: [],
            "id": 709,
            "expedient": "EXVC2200153",
            "isClosed": false,
            "type": {
    "id": 1,
	"name": "Compra"
	},
            "transportType": "not_inclusive",
            "tenantId": 1
        }
    },
    "expedient": "EXVC2200153",
    "isClosed": false,
    "type": {
    "id": 1,
	"name": "Compra"
	},
    "settled": 2300,
    "toLiquidate": 0,
    confirm: true
}]