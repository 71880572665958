import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, map, throwError } from 'rxjs';
import { CommonListResponse } from 'src/app/models/common-list-response';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IService } from './iService';
import { CommonItemList } from 'src/app/models/common-item';




@Injectable({
  providedIn: 'root',
})
export class CommonService<T, F> implements IService<T>  {
  private contactSubject: BehaviorSubject<CommonListResponse<T>>;


  constructor(private http: HttpClient) {
  }

  public get currentContactValue(): CommonListResponse<T> {
    return this.contactSubject.value;
  }

  async get(masterTable: string) {


    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return await lastValueFrom(this.http.get<CommonListResponse<T>>(`${environment.apiUrl + masterTable}/get`, options)
      .pipe(
        map(response => {
          localStorage.setItem(masterTable, JSON.stringify(response.data));
          return response.data;
        })));

  }

  async getAllItems(masterTable: string) {

      
    const options = {
        headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': '*/*',
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
    };
    return await lastValueFrom(this.http.get<CommonListResponse<T>>(`${environment.apiUrl + masterTable}/getAll`, options)
        .pipe(
            map(response => {
                localStorage.setItem(masterTable, JSON.stringify(response.data));
                return response.data;
            })));

}

  getAll(service, filters: F) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/get`, { filters }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  all(service, filters: F) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}`, { filters }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  
  getItems<F>(service, item: CommonItemList<T>) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<F>>(`${environment.apiUrl + service}/get`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }
  
  getItem<F>(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<F>>(`${environment.apiUrl + service}/get`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  item<F>(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<F>>(`${environment.apiUrl + service}`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  async getAllAsync(service, filters: F) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/get`, { filters }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }
  
  update(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/update`, { item }, options)
      .pipe(
        map(response => {

          return response;
        }));
  }

  create(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/register`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  addRange<T>(service, item: CommonItemList<T>) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/register`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  updateRange<T>(service, item: CommonItemList<T>) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/update`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  any(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  delete(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/delete`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  deleteRange<T>(service, item: CommonItemList<T>) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/delete`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  cancel(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/cancel`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  process(service, item: T) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<T>>(`${environment.apiUrl + service}/process`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

}