export const invoicesTableData = [{
    id: 1,
    expedient: 'EXVC2200023',
    invoice: 'OPVC2200030',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 2,
    expedient: 'EXVC2200023',
    invoice: 'OPVC2200031',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 3,
    expedient: 'EXVC2200023',
    invoice: 'OPVC2200041',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 4,
    expedient: 'EXVV2200002',
    invoice: 'OPVV2200002',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 5,
    expedient: 'EXVV2200002',
    invoice: 'OPVV2200004',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 6,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200784',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 7,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200785',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 8,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200786',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 9,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200787',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 10,
    expedient: 'EXVV2200517',
    invoice: 'OPVV2200774',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 11,
    expedient: 'EXVC2200292',
    invoice: 'OPVC2200548',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 12,
    expedient: 'EXVC2200292',
    invoice: 'OPVC2200549',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 13,
    expedient: 'EXVV2200518',
    invoice: 'OPVV2200779',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 14,
    expedient: 'EXVV2200518',
    invoice: 'OPVV2200788',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 15,
    expedient: 'EXVC2200295',
    invoice: 'OPVC2200560',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 16,
    expedient: 'EXVC2200293',
    invoice: 'OPVC2200550',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 17,
    expedient: 'EXVV2200519',
    invoice: 'OPVV2200783',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 18,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200784',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 19,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200785',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 20,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200786',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 21,
    expedient: 'EXVV2200520',
    invoice: 'OPVV2200787',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 22,
    expedient: 'EXVV2200106',
    invoice: 'OPVV2200129',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 23,
    expedient: 'EXVV2200106',
    invoice: 'OPVV2200770',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 24,
    expedient: 'EXVV2200489',
    invoice: 'OPVV2200722',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 25,
    expedient: 'EXVV2200489',
    invoice: 'OPVV2200780',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 26,
    expedient: 'EXVV2200009',
    invoice: 'OPVV2200011',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 27,
    expedient: 'EXVV2200016',
    invoice: 'OPVV2200020',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 28,
    expedient: 'EXVV2200481',
    invoice: 'OPVV2200708',
    amount: 1000.00,
    total: 1000.00,
    type: 'Venta'
},
{
    id: 29,
    expedient: 'EXVC2200283',
    invoice: 'OPVC2200537',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 30,
    expedient: 'EXVC2200153',
    invoice: 'OPVC2200210',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 31,
    expedient: 'EXVC2200153',
    invoice: 'OPVC2200211',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 32,
    expedient: 'EXVC2200153',
    invoice: 'OPVC2200346',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 33,
    expedient: 'EXVC2200153',
    invoice: 'OPVC2200464',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 34,
    expedient: 'EXVC2200153',
    invoice: 'OPVC2200556',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
},
{
    id: 35,
    expedient: 'EXVC2200153',
    invoice: 'OPVC2200558',
    amount: 1000.00,
    total: 1000.00,
    type: 'Compra'
}
]