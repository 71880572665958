export class CommonFile {

    name: string;
    mime: string;
    base64: string;
    
    constructor(name: string,mime: string,base64: string) {
        this.name = name;
        this.mime = mime;
        this.base64 = base64;
    }
  
   /*  constructor(file) {
  
      this.name = file.name;
      this.mime = file.mime;
      this.base64 = file.base64;
    } */
  
  }
  