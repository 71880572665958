import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { AuthenticationService } from './services/api/auth.service';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { StorageService } from './services/utils/storage.service';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isUserLoggedIn = false;
  ngOnInit() {
    registerLocaleData( es );
    let storeData = this.storageService.getItem("accessToken");

    if( storeData)
       this.isUserLoggedIn = true;
    else
       this.isUserLoggedIn = false;

 }
  constructor(private configService: ConfigService,
    private authService: AuthenticationService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private storageService: StorageService,
    paginatorIntl: MatPaginatorIntl
    ) {
      paginatorIntl.nextPageLabel = 'Siguiente';
      paginatorIntl.previousPageLabel = 'Anterior';
      paginatorIntl.lastPageLabel = 'Última página';
      paginatorIntl.firstPageLabel = 'Primera página';
      paginatorIntl.itemsPerPageLabel = 'Registros por página:';
      paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} de ${length}`;
      };
    /*   paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
          return `0 de ${length }`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} de ${length}`;
      }; */
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'subheading',
        label: '',
        children: [
          {
            type: 'dropdown',
            label: 'Configuración',
            icon: 'mat:settings',
            roles: ['Administrador','Gestor de Tablas Maestras'],
            children: [
              {
                type: 'link',
                label: 'Usuarios',
                route: '/users',
                roles: ['Administrador'],
                
              },
              {
                type: 'dropdown',
                label: 'Maestras',
                icon: 'mat:library_books',
                roles: ['Administrador','Gestor de Tablas Maestras'],
                children: [
                  {
                    type: 'link',
                    label: 'Artículos de factura',
                    route: '/articles',
                    roles: ['Administrador','Gestor de Tablas Maestras'],
                  },
                  {
                    type: 'link',
                    label: 'Países',
                    route: '/countries',
                    roles: ['Administrador','Gestor de Tablas Maestras'],
                  },
                 /*  {
                    type: 'link',
                    label: 'Productos',
                    route: '/products'
                  }, */
                  {
                    type: 'link',
                    label: 'Series',
                    route: '/series',
                    roles: ['Administrador','Gestor de Tablas Maestras'],
                  },
                 /*  {
                    type: 'link',
                    label: 'Colores',
                    route: '/colors'
                  }, */
                  {
                    type: 'link',
                    label: 'Tipos de coste',
                    route: '/cost-types',
                    roles: ['Administrador','Gestor de Tablas Maestras'],
                  },
                  {
                    type: 'link',
                    label: 'Títulos proforma',
                    route: '/titles',
                    roles: ['Administrador','Gestor de Tablas Maestras'],
                  },
                  {
                    type: 'link',
                    label: 'Títulos factura',
                    route: '/invoice-types',
                    roles: ['Administrador','Gestor de Tablas Maestras'],
                  }
                ]
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Gestión documental',
            icon: 'mat:attach_file',
            roles: ['Administrador','Gestor Documental','Manager','Gestor de Ofertas', 'Comercial de Compra','Comercial de Venta', 'Gestor de Administración','Gestor de Transporte'],
            children: [
              {
                type: 'link',
                label: 'Documentos',
                route: '/documents',
                roles: ['Administrador','Gestor Documental','Manager','Gestor de Ofertas', 'Comercial de Compra','Comercial de Venta', 'Gestor de Administración','Gestor de Transporte'],
              },
              {
                type: 'link',
                label: 'Categorías',
                route: '/categories',
                roles: ['Administrador','Gestor Documental'],
              },
              {
                type: 'link',
                label: 'Seguimiento',
                route: '/tracing',
                roles: ['Administrador','Gestor Documental'],
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Administración',
            icon: 'mat:apps',
            roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager','Gestor de Administración','Gestor de Ofertas','Gestor Documental','Gestor de Entidades Bancarias'],
            children: [
              {
                type: 'dropdown',
                label: 'Entidades externas',
                icon: 'mat:library_books',
                roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager', 'Gestor de Administración','Gestor de Ofertas','Gestor Documental'],
                children: [
                  {
                    type: 'link',
                    label: 'Contactos',
                    route: '/contacts',
                    roles: ['Administrador', 'Comercial de Compra', 'Comercial de Venta', 'Gestor de Transporte', 'Manager', 'Gestor de Administración','Gestor de Ofertas','Gestor Documental'],
                  },
                  {
                    type: 'link',
                    label: 'Clientes',
                    route: '/clients',
                    roles: ['Administrador','Manager', 'Gestor de Administración','Gestor de Ofertas', 'Comercial de Compra','Comercial de Venta','Gestor Documental','Gestor de Transporte'],
                  },
                /*   {
                    type: 'link',
                    label: 'Comerciales',
                    route: '/commercials'
                  }, */
                  {
                    type: 'link',
                    label: 'Proveedores',
                    route: '/providers',
                    roles: ['Administrador', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Comercial de Compra','Gestor Documental','Gestor de Transporte'],
                  },
                  {
                    type: 'link',
                    label: 'Brokers',
                    route: '/brokers',
                    roles: ['Administrador', 'Manager','Gestor de Administración','Gestor de Ofertas', 'Comercial de Compra','Comercial de Venta','Gestor Documental','Gestor de Transporte'],
                  },
                  {
                    type: 'link',
                    label: 'Transporte',
                    route: '/freight-companies',
                    roles: ['Administrador', 'Manager','Gestor de Transporte', 'Gestor de Administración','Gestor de Ofertas', 'Comercial de Compra','Gestor Documental'],
                  }
                ]
              },
              {
                type: 'dropdown',
                label: 'Gestiones económicas',
                icon: 'mat:library_books',
                roles: ['Administrador','Gestor de Administración','Gestor de Entidades Bancarias'],
                children: [
                  {
                    type: 'link',
                    label: 'Bancos',
                    route: '/banks',
                    roles: ['Administrador','Gestor de Entidades Bancarias']
                  },
                  {
                    type: 'link',
                    label: 'Movimientos',
                    route: '/transactions',
                    roles: ['Administrador','Gestor de Entidades Bancarias']
                  },
                  {
                    type: 'link',
                    label: 'Pagos/Cobros',
                    route: '/paysCharges',
                    roles: ['Administrador','Gestor de Administración']
                  },
                  {
                    type: 'link',
                    label: 'Costes',
                    route: '/costs',
                    roles: ['Administrador','Gestor de Administración']
                  },
                  /* {
                    type: 'link',
                    label: 'Balance',
                    route: '/login'
                  }, */
                  {
                    type: 'link',
                    label: 'Facturas',
                    route: '/invoices',
                    roles: ['Administrador','Gestor de Administración']
                  }
                ]
              },
              {
                type: 'link',
                label: 'Almacén',
                route: '/warehouse',
                roles: ['Administrador','Gestor de Administración']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Gestión Comercial',
            icon: 'mat:card_travel',
            roles: ['Administrador','Gestor de Ofertas','Comercial de Compra','Comercial de Venta','Manager','Gestor de Administración'],
            children: [
              /* {
                type: 'link',
                label: 'Ofertas de compra',
                route: '/login'
              }, */
              {
                type: 'link',
                label: 'Oferta de venta',
                route: '/offers',
                roles: ['Administrador','Gestor de Ofertas','Comercial de Compra','Comercial de Venta','Manager','Gestor de Administración'],
              },
             /*  {
                type: 'link',
                label: 'Control de cobros',
                route: '/login'
              } */
            ]
          },
          {
            type: 'dropdown',
            label: 'Gestión de vehículos',
            icon: 'mat:directions_car',
            roles: ['Administrador','Gestor de Ofertas'],
            children: [
              {
                type: 'link',
                label: 'Catálogo de vehículos',
                route: '/vehicles',
                roles: ['Administrador','Gestor de Ofertas']
              },
              {
                type: 'link',
                label: 'Equipamientos',
                route: '/equipments',
                roles: ['Administrador','Gestor de Ofertas']
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Gestión de Transporte',
            icon: 'mat:local_shipping',
            roles: ['Administrador','Gestor de Transporte','Comercial de Venta','Manager'],
            children: [
              {
                type: 'link',
                label: 'Presupuestos',
                route: '/budgets',
                roles: ['Administrador','Comercial de Venta','Manager']
              },
             
                {
                  type: 'link',
                  label: 'Propuestas pendientes',
                  route: '/pending',
                  roles: ['Administrador','Gestor de Transporte']
                },
                {
                  type: 'link',
                  label: 'Gestión de transporte',
                  route: '/management',
                  roles: ['Administrador','Gestor de Transporte']
                },
                {
                  type: 'link',
                  label: 'Transportes diarios',
                  route: '/daily',
                  roles: ['Administrador','Gestor de Transporte']
                },
                /*  {
                  type: 'link',
                  label: 'Control de cobro',
                  route: '/login'
                },
                {
                  type: 'link',
                  label: 'Generar CMR',
                  route: '/login'
                } */
            ]
          },
          {
            type: 'dropdown',
            label: 'Gestión de Expedientes',
            icon: 'mat:library_books',
            roles: ['Administrador','Gestor de Administración','Gestor Documental','Manager'],
            children: [
             /*  {
                type: 'link',
                label: 'Proformas',
                route: '/login'
              }, */
              {
                type: 'link',
                label: 'Expedientes de compra',
                route: '/purchases',
                roles: ['Administrador','Gestor de Administración','Gestor Documental','Manager']
              },
              {
                type: 'link',
                label: 'Expedientes de venta',
                route: '/sales',
                roles: ['Administrador','Gestor de Administración','Gestor Documental','Manager']
              }
            ]
          }
        ]
      },
      /* {
        type: 'subheading',
        label: 'Pages',
        children: [
          {
            type: 'dropdown',
            label: 'Authentication',
            icon: 'mat:fingerprint',
            children: [
              {
                type: 'link',
                label: 'Login',
                route: '/login'
              },
              {
                type: 'link',
                label: 'Nueva contraseña',
                route: '/new-password'
              },
              {
                type: 'link',
                label: 'Petición nueva contraseña',
                route: '/forgot-password'
              }
            ]
          }
        ]
      }, */
    ];
  }
}
