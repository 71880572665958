import { NgModule } from '@angular/core';
import { ConceptsComponent } from './concepts.component';
import { ConceptsRoutingModule } from './concepts-routing.module';
import { CommonModule } from '@angular/common';
import { TableModule } from 'src/app/pages/apps/table/table.module';


@NgModule({
  declarations: [ConceptsComponent],
  imports: [
    CommonModule,
    ConceptsRoutingModule,
    TableModule
  ],
  bootstrap: [ConceptsComponent],
  exports: [ConceptsComponent]
})
export class ConceptsModule {}
