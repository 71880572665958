import { AfterViewInit, Component, Injector, Input, Inject, isDevMode, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { Document } from '../../../../models/documents/document';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumn } from '../../../../../@vex/interfaces/table-column.interface';
import { DocumentCreateUpdateComponent } from './document-create-update/document-create-update.component';
import { SelectionModel } from '@angular/cdk/collections';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { stagger40ms } from '../../../../../@vex/animations/stagger.animation';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatSelectChange } from '@angular/material/select';
import { ConfirmDialogComponent } from '../../../apps/common-dialogs/confirm-dialog/confirm-dialog.component';
import { DocumentService } from 'src/app/services/api/document.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { DocFilters } from 'src/app/models/documents/doc-filters';
import { ComboItem } from 'src/app/models/combo-item';
import { MasterService } from 'src/app/services/api/masters.service';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { TransferData } from 'src/app/models/contacts/transfer-data';
import { KeyValue } from 'src/app/models/key-value';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Router } from '@angular/router';



@UntilDestroy()
@Component({
  selector: 'vex-documents',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class DocumentsTableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatAutocompleteTrigger) autoModel: MatAutocompleteTrigger;
  referenceCtl = new FormControl();
  filteredOptions: Observable<string[]>;

  


  initialized: boolean = false;
  @Input() documentType: string = "";
  @Input() reference: KeyValue = null;
  @Input() editabled: boolean = false;
  _tableData: Array<Document>;
  @Input()
  set tableData(val: Array<Document>) {
    this.tableDataChange.emit(val);
    this._tableData = val;
  }
  get tableData() {
    return this._tableData;
  }
  @Output()
  tableDataChange: EventEmitter<Array<Document>> = new EventEmitter<Array<Document>>();
  @Input() isMenu: boolean = true;
  @Input() params: ITableParams<Document, Document>;

  layoutCtrl = new UntypedFormControl('fullwidth');
  filters: DocFilters = new DocFilters();
  type: number;

  documentTypeId: number;
  documentCategoryId: number;
  categories: Array<ComboItem>;

  subject$: ReplaySubject<Document[]> = new ReplaySubject<Document[]>(1);
  data$: Observable<Document[]> = this.subject$.asObservable();
  documents: Document[];

  docTypes: Array<ComboItem>;



  @Input()
  columns: TableColumn<Document>[];
  total = 0;
  pageSize = 10;
  pageIndex = 0;
  order = "asc";
  orderType = "";
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<Document> | null;
  selection = new SelectionModel<Document>(true, []);

  public service: any;
  items: Array<ComboItem> = [];




  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,
    private alert: ToastService,
    private documentService: DocumentService,
    private masterService: MasterService<MasterCommon>,
    private dialogRef: MatDialogRef<DocumentsTableComponent>,) {
    this.dataSource = new MatTableDataSource();
  }

  get visibleColumns() {
    if (this.columns)
      return this.columns.filter(column => column.visible).map(column => column.property);

    return [];
  }


  getData() {
    if (this.params?.tableData !== undefined) {
      return of(this.params.tableData);
    }
    else if (this.documents !== undefined)
      return of(this.documents);
    else
      return of([])
  }

  ngOnInit() {

    const appUser = JSON.parse(localStorage.getItem("currentUser"));
    if(this.isMenu)
      this.editabled = appUser.roles.filter(y => y.id == 1 || y.id == 2 || y.id == 3 || y.id == 4 || y.id == 7 || y.id == 8).length > 0;

    if(appUser.roles.filter(y => y.id == 7).length > 0)
      this.editabled = true;

    (async () => {

      this.categories = await this.masterService.get("documentCategory") as unknown as Array<MasterCommon>;
      this.docTypes = await this.masterService.get("documentTypes") as unknown as Array<MasterCommon>;

    
      this.columns = [
        { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: this.editabled },
        { label: 'Fecha de subida', property: 'createdAt', type: 'date', visible: true },
        { label: 'Nombre del documento', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium'] },
        { label: 'Categoría', property: 'documentCategory', type: 'select', visible: true },
        { label: 'Tipo', property: 'documentType', type: 'select', visible: true },
        { label: 'Referencia', property: 'referenceName', type: 'text', visible: true, },
        { label: 'Notas', property: 'description', type: 'text', visible: false, },
        // { label: 'Labels', property: 'labels', type: 'button', visible: true },
        { label: 'Acciones', property: 'actions', type: 'button', visible: this.editabled }
      ];

      if (this.data?.reference) {
        this.reference = this.data.reference;
        this.documentTypeId = this.data.documentTypeId;
        this.documentType = this.data.type;
        this.documentCategoryId = this.data.documentCategoryId;
        this.filters.documentCategoryId = this.data.documentCategoryId;
        this.params = {
          root: ["Gestión de expedientes", "Expedientes de venta", "Documentos"],
          //tableData: this.data.tableData || [],
          isMenu: false,
          disabledMode: true,
          element: "Documento",
          title: 'Documentos',
          titleProperty: "name",
          columns: this.columns
        }
      }
      else {
        this.params = this.data;
      }

      if (this.reference){
        this.filters.referenceName = this.reference?.name;
        this.filters.referenceId = this.reference?.id;
      }
        
      if (this.documentType)
        this.documentTypeId = this.docTypes.filter(d => d.name === this.documentType)[0]?.id;

      if (this.params?.columns != undefined) {
        this.isMenu = this.params.isMenu;
        this.columns = this.params.columns;
      }
      /*  else
         this.columns = [
           { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
           { label: 'Fecha de subida', property: 'createdAt', type: 'date', visible: true },
           { label: 'Nombre del documento', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium'] },
           { label: 'Categoría', property: 'documentCategory', type: 'select', visible: true },
           { label: 'Tipo', property: 'documentType', type: 'select', visible: true },
           { label: 'Referencia', property: 'referenceName', type: 'text', visible: true, },
           { label: 'Notas', property: 'description', type: 'text', visible: false, },
           // { label: 'Labels', property: 'labels', type: 'button', visible: true },
           { label: 'Acciones', property: 'actions', type: 'button', visible: true }
         ]; */

      this.loadData();
    })();
  }

  ngAfterContentInit() {
    if (this.dataSource) {
      //this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

  }

  sortData(event) {
    this.orderType = event.active;
    this.order = event.direction;
    this.loadData();
  }
  loadFilters() {
    this.filters.pageNumber = this.pageIndex + 1;
    this.filters.rowsPerPage = this.pageSize;
    this.filters.order = this.order;
    this.filters.orderBy = this.orderType;
    this.filters.documentTypeId = this.documentTypeId || this.filters.documentTypeId;
    //this.filters.referenceId = this.reference?.id || this.items.filter(r => r.name == this.referenceCtl?.value)[0]?.id || null;
    /* this.filters.nameLike = "";
    this.filters.documentCategoryId = null;
    this.filters.documentTypeId = null;
    this.filters.referenceId = null;
    this.filters.referenceName = null; */

  }

  async loadComboResource(type: number) {
    // Resolve AbstractFactory
    // const injectable = documentTypes.get(type);
    // Inject service
    /*  if (injectable) {
       this.service = this.injector.get(injectable); */
    // Calling method implemented by Food interface
    /*  this.contactType.getItems(type).subscribe((items) => {
       this.items = items;
     }) */
    /*  (async () => {
      this.contactType.getAllFilteredAsync(type).subscribe((items) => {
       this.items = items;
     });
   })();
    */
    /* this.items = (await this.contactType.getItems(type)) as unknown as Array<ComboItem>;
    this.filteredOptions = this.referenceCtl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      ); */
  }

  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile(doc: Document) {
    this.documentService.getDocument(doc).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido descargar el documento " + doc.name);
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            var pdfResult = response.base64; //"JVBERi0xLjYKJcOkw7zDtsOfCjIgMCBvYmoKPDwvTGVuZ3RoIDMgMCBSL0ZpbHRlci9GbGF0ZURlY29kZT4+CnN0cmVhbQp4nDPQM1Qo5ypUMFAw0DMwslAwtTTVMzI3VbAwMdSzMDNUKErlCtdSyOMKVAAAtxIIrgplbmRzdHJlYW0KZW5kb2JqCgozIDAgb2JqCjUwCmVuZG9iagoKNSAwIG9iago8PAo+PgplbmRvYmoKCjYgMCBvYmoKPDwvRm9udCA1IDAgUgovUHJvY1NldFsvUERGL1RleHRdCj4+CmVuZG9iagoKMSAwIG9iago8PC9UeXBlL1BhZ2UvUGFyZW50IDQgMCBSL1Jlc291cmNlcyA2IDAgUi9NZWRpYUJveFswIDAgNTk1LjMwMzkzNzAwNzg3NCA4NDEuODg5NzYzNzc5NTI4XS9Hcm91cDw8L1MvVHJhbnNwYXJlbmN5L0NTL0RldmljZVJHQi9JIHRydWU+Pi9Db250ZW50cyAyIDAgUj4+CmVuZG9iagoKNCAwIG9iago8PC9UeXBlL1BhZ2VzCi9SZXNvdXJjZXMgNiAwIFIKL01lZGlhQm94WyAwIDAgNTk1IDg0MSBdCi9LaWRzWyAxIDAgUiBdCi9Db3VudCAxPj4KZW5kb2JqCgo3IDAgb2JqCjw8L1R5cGUvQ2F0YWxvZy9QYWdlcyA0IDAgUgovT3BlbkFjdGlvblsxIDAgUiAvWFlaIG51bGwgbnVsbCAwXQovTGFuZyhlcy1FUykKPj4KZW5kb2JqCgo4IDAgb2JqCjw8L0NyZWF0b3I8RkVGRjAwNTcwMDcyMDA2OTAwNzQwMDY1MDA3Mj4KL1Byb2R1Y2VyPEZFRkYwMDRDMDA2OTAwNjIwMDcyMDA2NTAwNEYwMDY2MDA2NjAwNjkwMDYzMDA2NTAwMjAwMDM3MDAyRTAwMzE+Ci9DcmVhdGlvbkRhdGUoRDoyMDIyMTAzMTE3MzUxNSswMScwMCcpPj4KZW5kb2JqCgp4cmVmCjAgOQowMDAwMDAwMDAwIDY1NTM1IGYgCjAwMDAwMDAyMzQgMDAwMDAgbiAKMDAwMDAwMDAxOSAwMDAwMCBuIAowMDAwMDAwMTQwIDAwMDAwIG4gCjAwMDAwMDA0MDIgMDAwMDAgbiAKMDAwMDAwMDE1OSAwMDAwMCBuIAowMDAwMDAwMTgxIDAwMDAwIG4gCjAwMDAwMDA1MDAgMDAwMDAgbiAKMDAwMDAwMDU5NiAwMDAwMCBuIAp0cmFpbGVyCjw8L1NpemUgOS9Sb290IDcgMCBSCi9JbmZvIDggMCBSCi9JRCBbIDw2NkEyMDZBMERDRjhEMkMyMzIyOEMwNzI2MDcwRkE0Qz4KPDY2QTIwNkEwRENGOEQyQzIzMjI4QzA3MjYwNzBGQTRDPiBdCi9Eb2NDaGVja3N1bSAvNTY4MzJENTBFMjQ1N0Y0RjBCRTZBNTVCREU4QTQ4QkEKPj4Kc3RhcnR4cmVmCjc3MAolJUVPRgo=";//response.base64;

            var file = this.base64toBlob(pdfResult, doc.mimeType);
            //var file = new Blob([pdfResult], {type: 'application/pdf'}); //{type: doc.file.mime});
            var fileURL = URL.createObjectURL(file);
            var anchor = document.createElement("a");
            anchor.download = doc.originalName;
            anchor.href = fileURL;
            anchor.click();
            /* let w = window.open(fileURL);
            w.addEventListener('load', function () {
              w.document.title = doc.originalName;
          }) */

            //window.open("data:application/pdf;base64, " + escape(pdfResult);
            /* let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>") */
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido descargar el documento " + doc.originalName);
        }
    });
  }
  closeReference(event) {
    this.filters.documentTypeId = null;
    //this.filters.referenceId = null;
    this.items = [];
    this.loadData();
    event.stopPropagation()
  }

  changeType(type: any) {
    this.loadComboResource(type);
    this.loadData();
    //this.items = expedientsTableData.map(({ id: id, internalId: value }) => ({ id, value }));
    //this.cities = [];
  }
  loadData() {
    if (this.documentType)
      this.documentTypeId = this.docTypes.filter(d => d.name === this.documentType)[0]?.id;
    this.loadFilters();
    this.documentService.getAll(this.filters)
      .subscribe({
        next:
          response => {
            if (!response) {
              this.alert.error("No hay documentos");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              this.documents = response.data.map(d => new Document(d));
              this.paginator.length = response.total;
              this.paginator.pageIndex = this.pageIndex;
              //this.paginator.page = Math.ceil(response.total/this.pageSize);
              this.subsribeData()
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible recuperar la lista de documentos");
          }
      })
  }

  /*  searchDocuments(){
     this.filters.
   } */

  subsribeData() {
    this.getData().subscribe(documents => {
      this.subject$.next(documents);
    });


    this.data$.pipe(
      filter<Document[]>(Boolean)
    ).subscribe(documents => {
      this.documents = documents;
      this.dataSource.data = documents;
    });
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.paginator.length = this.total;
      //this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }
  }


  createDocument() {
    this.dialog.open(DocumentCreateUpdateComponent, {
      disableClose: true,
      data: new TransferData({
        type: this.documentType,
        reference: this.reference,
        documentCategoryId: this.documentCategoryId

      })
    }).afterClosed().subscribe((documents: Array<Document>) => {
      this.loadData();
      /* if (documents) {
        this.documents.unshift(...documents);
        this.subject$.next(documents);
      } */
    });
  }

  updateDocument(document: Document) {
    if (this.editabled) {
      this.dialog.open(DocumentCreateUpdateComponent, {
        disableClose: true,
        data: new TransferData({
          values: document,
          type: this.documentType,
          reference: this.reference,
          documentCategoryId: this.documentCategoryId
        })
      }).afterClosed().subscribe(updatedDocument => {
        if (updatedDocument) {
          this.loadData();
        }
      });
    }
  }

  showDeleteDocumentsAlert(documents: Document[]): void {
    if (documents.length == 0) {
      return;
    }
    else if (documents.length == 1) {
      this.showDeleteDocumentAlert(documents[0]);
      return;
    }


    this.dialog
      .open(ConfirmDialogComponent, {
        data: `¿Está seguro de querer eliminar los documentos seleccionados?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteDocuments(documents);
        }
      });
  }

  showDeleteDocumentAlert(document: Document): void {

    this.dialog
      .open(ConfirmDialogComponent, {
        data: `¿Está seguro de querer eliminar el documento?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteDocument(document);
        }
      });
  }

  deleteDocument(document: Document) {
    this.documentService.delete(document).subscribe({
      next:
        response => {
          this.selection.deselect(document);
          if (!response) {
            this.alert.error("No se ha podido borrar el documento " + document.name);
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            this.loadData();
            /*  this.documents.splice(this.documents.findIndex((existingDocument) => existingDocument.id === document.id), 1);
             this.selection.deselect(document);
             this.subject$.next(this.documents); */
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido borrar el documento " + document.name);
        }
    });

  }

  deleteDocuments(documents: Document[]) {
    documents.forEach(c => this.deleteDocument(c));
  }

  onFilterChange(value: string) {
    //this.loadData();
    /*  if (!this.dataSource || !value) {
       return;
     }
     value = value.toString().trim();
     value = value.toString().toLowerCase();
     this.dataSource.filter = value; */

  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const page = this.dataSource['_renderData']['_value'].length;
    return numSelected === page;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.selectRows();
  }
  selectRows() {
    let total = this.pageSize;
    let initIndex: number = 0;
    if (this.pageIndex > 0 && this.dataSource['_renderData']['_value'].length !== this.dataSource.data.length) {
      initIndex = (this.pageIndex) * this.pageSize;
      total = this.pageSize * (this.pageIndex + 1);
    }
    for (let index = initIndex; index < total; index++) {
      if (this.dataSource.data[index])
        this.selection.select(this.dataSource.data[index]);
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: Document) {
    const index = this.documents.findIndex(c => c === row);
    //this.documents[index].labels = change.value;
    this.subject$.next(this.documents);
  }

  mouseLeave(trigger) {
    trigger.closeMenu();
  }

  closeMenu(trigger) {
    trigger.closeMenu();
  }

  onPaginateChange(event) {
    this.selection.clear();
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    this.loadData()
  }

  getColor(amount) {
    if (amount < 0)
      return 'red'
    else
      return ''

  }
  close() {
    this.dialogRef.close();
  }

  openFullAggregatorsList(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }


  public _filter(value: any): any[] {
    if (value == null)
      value = '';
    return this.items.filter(option => option.name.toLowerCase().includes(value?.toLowerCase()));
  }


}

