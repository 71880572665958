import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ImageDialogComponent } from 'src/app/pages/apps/common-dialogs/image-dialog/image-dialog.component';
import { AuthenticationService } from 'src/app/services/api/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StorageService } from 'src/app/services/utils/storage.service';
import { PopoverRef } from '../popover/popover-ref';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  appUser;
  constructor(private readonly popoverRef: PopoverRef,
    private router: Router,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private alert: ToastService
  ) { this.appUser = JSON.parse(localStorage.getItem("currentUser")); }

  ngOnInit(): void {
  }

  close(): void {
    this.popoverRef.close()
    this.authService.logout();
    //this.router.navigate(['/login']);
  }

  updateAvatar(){
    this.dialog.open(ImageDialogComponent, {
      data: this.appUser
    }).afterClosed().subscribe(image => {
      if (image) {
       this.alert.info('Avatar cambiado con éxito');
      
        //this.defaults.imageSrc = image;
      }
     /*  else
        this.alert.info('No ha sido posible cambiar la imagen de su avatar') */

        this.popoverRef.close();  

    });
  }

 
}
