import { IFilters } from "src/app/interfaces/ifilters";
import { EmptyKeyValue, KeyValue } from "../key-value";

export class InvoicesFilters implements IFilters {
    rowsPerPage: number;
    pageNumber: number;
    orderBy: string;
    order: string;

    invoiceNumber: number = null;
    name: string = "";
    proformaCode: string = "";
    invoiceTitleId: number = null;
    invoiceTitle: KeyValue = new EmptyKeyValue();
    administrativeFile: KeyValue = new EmptyKeyValue();
    administrativeFileId: number = null;
    id?: number;
    fromDate: Date = null;
    toDate: Date = null;
/*     officeId : number= 1;
    orderTypeId : number= null;
    accountingEntryId :number= null;
    vehicleCostTypeId  :number= null;
    bankingTransactionId:number= null;
    concept?:KeyValue = null;
    administrativeFileReference?:KeyValue = null;
    costTypeId?:number = null; 
    createdAt?: Date = null;
    accountingEntryTypeId?:number = null;
    entityId : number= null;
    proformaInvoiceId?:number = null;
    remarksLike?:string = null;
    proformas?: Array<KeyValue> = []; */
}