import { KeyValue } from "./key-value";

export class CommonItemList<T> {

  id: number = null;
  vehicleId?: number = null;
  items: Array<T> = [];
  orderTypeId?:number = null;
  accountingEntries? :Array<KeyValue> = [];
  accountingEntry? :KeyValue = null;
  administrativeFiles? :Array<KeyValue> = [];

  constructor(id, items) {
  
    this.id = id;
    this.items = items;
  }


    
  }