import { IFilters } from "src/app/interfaces/ifilters";
import { KeyValue } from "../key-value";

export class CommonFilter implements IFilters {

    id: number = null;
    
    rowsPerPage: number = 5;
    pageNumber: number = 1;
    orderBy: string;
    order: string;
    name: string = "";
    hasSale?: boolean = null;
    hasShipment? = null;
    locationType?: KeyValue;
    sign?:number;
    extraId?: number;
    orderTypeId?:number = null;
    entityId?:number = null;
    proformaInvoices?: Array<KeyValue> = [];
    isAssignment?: boolean = false;
    administrativeFileId?:number = null;
    accountingEntryTypeId?:number = null;
    accountingEntryId?:number = null;
    proformaInvoiceId?:number = null;
    remarksLike?:string = '';
    createdAt?: Date = null;
    bankingTransactionId?:number= null;
    concept?:KeyValue = null;
    administrativeFileReference?:KeyValue = null;
    costTypeId?:number = null; 
    proformas?: Array<KeyValue> = [];
    orderType?:KeyValue = null;
    officeId:number = null; 
    vehicleCostTypeId:number = null; 
    shipmentId?: number = null;
    vin?: string = '';
    plate?: string = '';
    isLinked?: boolean = null;
    version?: string = "";
    shipmentCostType?: KeyValue;
    vehicleVersionId?: number;
    brand?: KeyValue;
    brandId?: number = null;
    model?: KeyValue;
    constructor(id) {
  
      this.id = id;
      this.hasSale = null;
    }
 
    
  
  }
  