import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Injector, Input, isDevMode, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { BehaviorSubject, debounceTime, exhaustMap, Observable, of, ReplaySubject, scan, startWith, Subject, switchMap, tap } from 'rxjs';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { CommonFile } from 'src/app/models/file';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { Vehicle } from 'src/app/models/vehicles/vehicle';
import { ListComponent } from 'src/app/pages/apps/table/list/list.component';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { DocumentService } from 'src/app/services/api/document.service';
import { MasterService } from 'src/app/services/api/masters.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { documentsTypes, documentTypes } from './types-factory/document-types';
import { Document } from '../../../../../models/documents/document';
import { IFilters } from 'src/app/interfaces/ifilters';
import { ExpedientFilters } from 'src/app/models/expedients/expedient-filters';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/utils/storage.service';


@Component({
  selector: 'vex-document-create-update',
  templateUrl: './document-create-update.component.html',
  styleUrls: ['./document-create-update.component.scss']
})
export class DocumentCreateUpdateComponent implements OnInit {

  filteredOptions: Observable<KeyValue[]>;
  @Output() Change: EventEmitter<Array<Vehicle>> = new EventEmitter<Array<Vehicle>>();

  _vehicles: Array<Vehicle>;

  @Input()
  set vehicles(val: Array<Vehicle>) {
    this.Change.emit(val || []);
    this._vehicles = val || [];
  }
  get vehicles() {
    //this.tableData = this.params?.list?.tableData;
    return this._vehicles;
  }

  public files: any[] = null;
  panelOpenState = false;

  file: CommonFile | null;

  public service: any;

  requiredReference: boolean = false;
  requiredType: boolean = false;

  params: ITableParams<Vehicle, Vehicle>;

  //vehicles: Vehicle[] = [];
  expedient: string = '';

  documentType: string = null;
  reference: KeyValue;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  docTypes: Array<KeyValue>;
  progress: boolean;

  documentCategoryId: number;

  filters: IFilters;
  expedientFilters: ExpedientFilters = new ExpedientFilters();

  next$ = new Subject();
  startSearch = new BehaviorSubject<any>('');
  startSearch$ = this.startSearch.asObservable();

  officeId: number;



  getEntities(typeId: number, page: number): Observable<any[]> {
    if (typeId) {
      const take = 6;
      //const skip = page > 0 ? (page - 1) * take : 0;
      const injectable = documentTypes.get(typeId);
      // Inject service
      this.service = this.injector.get(injectable);
      this.service.getFilters().subscribe((filters) => {
        this.filters = filters;
      })
      if (this.filters) {
        this.filters.pageNumber = page;
        this.filters.rowsPerPage = 6;
        this.filters.order = "asc";
        this.filters.orderBy = "name";
        this.filters.officeId = this.officeId;
        this.filters.name = this.form.value.referenceName.trim();
        return this.masterService.getAllFiltered(documentsTypes.get(typeId), this.filters);
      }
    }
    return of([]);
  }

  async loadData(service, filters) {
    this.masterService.getAllFiltered(service, filters).subscribe(items => {
      return items;
    });

  }
  onScroll() {
    this.next$.next('');
  }

  onPanelOpen(weight: any) {

    // this.startSearch.next(weight.controls['weight'].value);
    this.startSearch.next('');
  }




  //categories: Array<ComboItem> = documentCategoriesTableData.map(({ id: id, description: value }) => ({ id, value }));
  categories: Array<KeyValue>;



  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialog: MatDialog,
    private router: Router,
    private dialogRef: MatDialogRef<DocumentCreateUpdateComponent>,
    private fb: FormBuilder,
    private injector: Injector,
    private alert: ToastService,
    private documentService: DocumentService,
    private masterService: MasterService<MasterCommon>,
    public scroll: ScrollDispatcher,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService) {
  }



  ngOnInit() {
    (async () => {
      this.categories = await this.masterService.get("documentCategory") as unknown as Array<KeyValue>;
      this.docTypes = await this.masterService.get("documentTypes") as unknown as Array<KeyValue>;
    })();
    if (this.defaults.values) {
      this.progress = false;
      this.mode = 'update';
      this.files = [];
      this.downloadFile(this.defaults.values);
    } else {
      this.progress = true;
      this.defaults.values = {} as Document;
    }



    /* if(this.defaults.values.file){
      this.files = [];
      this.files.push(this.defaults.values.file);
      this.fileBrowseHandler(this.files);
    }
*/
    this.documentType = this.defaults?.type || this.defaults?.values.documentType?.name;;
    this.documentCategoryId = this.defaults?.documentCategoryId;
    this.reference = this.defaults?.reference;
    this.vehicles = this.defaults?.values.vehicles;
    if (!this.documentType)
      this.requiredType = true;

    this.storageService.getItem("origin").subscribe(origin => {
      this.officeId = origin;
    });


    this.form = this.fb.group({
      date: [this.defaults.values.date || ''],
      name: [this.defaults.values.name || '', Validators.required],
      documentType: [this.defaults.values.documentType || ''],
      description: this.defaults.values.description || '',
      reference: this.defaults.values.reference || '',
      referenceName: this.defaults.values.referenceName || '',
      documentCategory: [this.defaults.values.documentCategory || ''],
      remarks: this.defaults.values.remarks || '',
      isVisible: this.defaults.values.isVisible || null,
      files: [this.files, Validators.required]
    });









    if (!this.defaults?.type) {
      this.loadTypeOptions();
      this.loadExpedient();
    }
    else if (this.defaults?.type == 'Expediente') {
      this.expedientFilters.administrativeFileId = this.defaults.reference.id;
      this.expedientFilters.pageNumber = 0;
      this.expedientFilters.rowsPerPage = 5;
      this.expedientFilters.order = "asc";
      this.expedientFilters.orderBy = "name";
      this.loadParams();
    }
  }

  loadExpedient() {
    if (this.documentType == 'Expediente') {
      this.filteredOptions.subscribe(x => {
        const items = x.filter(z => z.name == this.form.value.referenceName.trim());
        this.expedientFilters.administrativeFileId = items[0]?.id;
        this.expedientFilters.pageNumber = 0;
        this.expedientFilters.rowsPerPage = 5;
        this.expedientFilters.order = "asc";
        this.expedientFilters.orderBy = "name";

        this.loadParams();
      }
      );
    }
  }

  downloadFile(doc: Document) {
    this.documentService.getDocument(doc).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido descargar el documento " + doc.name);
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            /* var pdfResult = response.base64;
            var file = this.base64toBlob(pdfResult, doc.mimeType);
            var fileURL = URL.createObjectURL(file); */

            const base64 = response.base64;
            const imageName = doc.originalName;

            const ia = new Uint8Array(base64.length)
            for (let i = 0; i < base64.length; i++)
              ia[i] = base64.charCodeAt(i)


            const imageBlob = new Blob([ia], { type: response.mimeType })

            this.files.push(new File([imageBlob], imageName, { type: response.mimeType }));
            this.form.controls['files'].patchValue(this.files);

          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido descargar el documento " + doc.originalName);
        }
    });
  }

  loadParams() {
    this.params = {
      isMenu: false,
      title: 'Vehículos',
      element: "Vehículo",
      titleProperty: 'name',
      hasImage: false,
      tableData: this._vehicles || [],
      columns: [
        { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
        { label: 'Modelo', property: 'name', type: 'text', visible: true },
        { label: 'Matrícula', property: 'plate', type: 'text', visible: true },
        { label: 'Bastidor', property: 'vin', type: 'text', visible: true },

      ],
      list: {
        isMenu: false,
        element: "Vehículo",
        title: 'Vehículos',
        titleProperty: 'name',
        service: "administrative-files/vehicles/maestra",
        hasImage: false,
        filters: this.expedientFilters,
        paginatedOnBack: true,
        columns: [
          { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
          { label: 'Modelo', property: 'name', type: 'text', visible: true },
          { label: 'Matrícula', property: 'plate', type: 'text', visible: true },
          { label: 'Bastidor', property: 'vin', type: 'text', visible: true },

        ],
        //tableData: carsTableData.map(car => new Car(car)),
      }
    }
  }
  checkType() {
    if (!this.reference)
      if ((this.form?.value?.documentType?.id > 1 && this.form?.value?.documentType?.id < 5) || this.form?.value?.documentType?.id == 6 || this.form?.value?.documentType?.id == 7)
        return true;

    return false;
  }

  loadTypeOptions() {
    this.filteredOptions = this.startSearch$.pipe(
      startWith(''),
      debounceTime(200),
      switchMap(filter => {
        //Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.next$.pipe(
          startWith(currentPage),
          //Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.getEntities(this.form?.value?.documentType?.id, currentPage)),
          tap(() => currentPage++),
          //Note: This is a custom operator because we also need the last emitted value.
          //Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive((p: any) => p.length > 0),
          scan(
            (allItems: any, newItems: any) =>
              allItems.concat(newItems),
            []
          )
        );
      })
    );
  }
  SearchWeightuggestions(val: any) {

    //const d = this.getEntities(String(val),1);
    const d = this.getEntities(this.form?.value?.documentType?.id, 1);
    this.startSearch.next(val.trim());
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.scroll.scrolled().subscribe((data: CdkScrollable) => {
      console.log(data);
      //make an HTTP call when it reaches to the end, to add some more data
    });
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  displayFn(option) {
    if (option && option.name) {
      return option.name.toLowerCase();
    } else {
      return option
    }
  }

  /*   updateImage() {
      
      this.dialog.open(FileDialogComponent, {
        data: this.defaults
      }).afterClosed().subscribe(url => {
        if (url) {
          this.form.value.url = url;
          this. = url;
          //this.defaults.imageSrc = image;
        }
      });
    } */


  save() {
    if (this.mode === 'create') {
      this.createDocument();
    } else if (this.mode === 'update') {
      this.updateDocument();
    }
  }

  getReferenceId(referenceName) {
    this.filteredOptions.subscribe(x => {
      const items = x.filter(z => z.name == referenceName);
      return items[0].id;
    });
    //return this.filteredOptions.pipe(map(i => i.filter(f => f.name == referenceName)))[0]?.id;
    //return this.filteredOptions.subscribe(item => item.filter(reference => reference.name === referenceName))[0]?.id;
  }

  createDocument() {

    const document = this.form.value;
    document.active = true;
    document.createdAt = new Date();

    document.referenceName = this.reference?.name || this.form.value.referenceName;
    document.referenceId = this.reference?.id || this.form.value.referenceId;


    document.url = this.form.value.url || '';
    document.vehicles = this.vehicles || [];
    if (!document.date) {
      document.date = new Date();
    }
    if (this.documentType) {
      document.documentType = this.docTypes.filter(d => d.name === this.documentType)[0];
    }

    if (this.documentCategoryId) {
      document.documentCategoryId = this.documentCategoryId || this.form.value.documentCategoryId;
      document.documentCategory = new EmptyKeyValue();
      document.documentCategory.id = this.documentCategoryId || this.form.value.documentCategoryId;
    }
    const name = document.name;
    let documents: Array<Document> = [];
    if (this.filteredOptions && this.checkType()) {
      this.filteredOptions.subscribe(x => {
        const items = x.filter(z => z.name == this.form.value.referenceName);
        document.referenceId = this.reference?.id || items[0]?.id || null;

        this.files.forEach(x => {
          this.convertFile(x).subscribe(base64 => {
            document.file = new CommonFile(x.name, x.type, base64);
            if (this.files.indexOf(x) > 0)
              document.name = name + ' (' + Number(1 + this.files.indexOf(x)) + ')';
            this.documentService.createDocument(document).subscribe(() => {
              documents.push(document);
              if (documents.length == this.files.length)
                this.dialogRef.close(documents);
            });

          });
        });

      })
    }
    else {
      document.referenceId = this.reference?.id || null;
      this.files.forEach(x => {
        this.convertFile(x).subscribe(base64 => {
          document.file = new CommonFile(x.name, x.type, base64);
          if (this.files.indexOf(x) > 0)
            document.name = name + ' (' + (Number(this.files.indexOf(x))) + ')';
          this.documentService.createDocument(document).subscribe(() => {
            documents.push(document);
            if (documents.length == this.files.length)
              this.dialogRef.close(documents);
          });

        });
      });

    }

  }

  updateDocument() {
    const document = this.form.value;
    document.id = this.defaults.values.id;
    /*  document.referenceId = this.form.value.reference.id; */
    document.referenceName = this.reference?.name || this.form.value.referenceName || '';
    document.referenceId = this.reference?.id || this.form.value.referenceId;

    if (this.documentType) {
      document.documentType = this.docTypes.filter(d => d.name === this.documentType)[0];
    }
    if (this.documentCategoryId) {
      document.documentCategoryId = this.documentCategoryId || this.form.value.documentCategoryId;
      document.documentCategory = new EmptyKeyValue();
      document.documentCategory.id = this.documentCategoryId || this.form.value.documentCategoryId;
    }
    document.documentCategoryId = this.documentCategoryId || this.form.value.documentCategoryId;

    document.url = this.defaults.values.url || '';
    document.createdAt = this.defaults.values.createdAt;
    document.vehicles = this.vehicles || [];
    document.originalName = this.defaults.values.originalName;
    document.storageName = this.defaults.values.storageName;

    if (this.filteredOptions && this.checkType()) {
      this.filteredOptions.subscribe(x => {
        this.convertFile(this.files[0]).subscribe(base64 => {
          document.file = new CommonFile(this.files[0].name, this.files[0].type, base64);
          const items = x.filter(z => z.name == this.form.value.referenceName);
          document.referenceId = this.reference?.id || items[0]?.id || null;
          this.modifyDocumento(document);
        });
      })
    }
    else {
      document.referenceId = this.reference?.id || null;
      this.convertFile(this.files[0]).subscribe(base64 => {
        document.file = new CommonFile(this.files[0].name, this.files[0].type, base64);
        this.modifyDocumento(document);
      });
    }

  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }

  onValueChange(file: File[]) {
    console.log("File changed!");
  }
  selectedRole(event: MatSelectChange) {
    this.defaults.values.role = event.source.triggerValue;
  }

  changeType(type: any) {
    const service = documentsTypes.get(type.id);
    //this.getEntities(service,1);
    this.documentType = type.name;
    this.reference = null;
    this.loadTypeOptions();
    this.expedient = '';
    this.vehicles = [];
    this.form.get('referenceName').setValue("");
    this.params = null;
    //this.items = expedientsTableData.map(({ id: id, internalId: value }) => ({ id, value }));
    //this.cities = [];
  }

  loadCars(type) {
    if (type === 'Expediente') {
      this.dialog.open(ListComponent,
        {
          data: this.params.list
        }).afterClosed().subscribe((vehicles: Vehicle[]) => {
          if (vehicles) {
            this.vehicles = vehicles;
          }
        });
    }

  }

  addDocument(doc: Document) {
    //doc.file = this.file;
    this.documentService.createDocument(doc).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible dar de alta el documento");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              doc.id = response.data[0].id;
              doc.url = response.data[0].url;
              doc.originalName = response.data[0].originalName;
              doc.storageName = response.data[0].storageName;
              doc.mimeType = response.data[0].mimeType;

            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible dar de alta el documento");
          }
      });

  }

  modifyDocumento(doc: Document) {
    //doc.file = this.file || null;
    this.documentService.updateDocument(doc).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible actualizar el documento");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              doc.url = response.data[0].url;
              doc.mimeType = response.data[0].mimeType;
              this.dialogRef.close(doc);
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible actualizar el documento");
          }
      });

  }



  handleFileInput(event: Event) {
    let file: File = event.target['files'][0];
    this.convertFile(file).subscribe(base64 => {

      this.file = new CommonFile(file.name, file.type, base64);
    });

  }
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  setExpedient(event) {
    this.vehicles = [];
    this.params = null;
    //this.expedient = event.value;
    this.loadExpedient();
  }


  openFullAggregatorsList(event: Event, trigger: MatAutocompleteTrigger) {
    event.stopPropagation();
    trigger.openPanel();
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if (this.isUpdateMode())
      if (files.length > 1) {
        this.alert.info("Solo puede subir un documento");
        return;
      }

    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.form.controls['files'].patchValue(this.files);
    this.progress = true;
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index]?.progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 20);
      }
    }, 100);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    let message: string = '';
    for (const item of files) {
      if (item.size > 31457280) {
        if (!message)
          message = "Solo puede subir documentos de menos de 30MB. Se descartó '" + item.name + "'";
        else
          message = message + ", '" + item.name + "'";
      }
      else {
        if (this.isUpdateMode())
          this.deleteFile(0);
        item.progress = 0;
        if (!this.files)
          this.files = [];
        this.files.push(item);

        this.form.controls['files'].patchValue(this.files);
      }
    }
    this.uploadFilesSimulator(0);
    if (message)
      this.alert.info(message);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}


