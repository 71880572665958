export const packsTableData= [{
	"createdAt": "2022-07-04T09:54:03.344Z",
	"updatedAt": "2022-07-04T09:54:03.344Z",
	"createdBy": null,
	"id": 137,
	"name": "Pack M Sport",
	"brandId": 8,
	"brand": "BMW"
}, {
	"createdAt": "2022-06-13T17:29:54.329Z",
	"updatedAt": "2022-06-13T17:29:54.329Z",
	"createdBy": null,
	"id": 136,
	"name": "Pack Light",
	"brandId": 70,
    "brand": "Volvo"
}, {
	"createdAt": "2022-06-13T17:29:42.966Z",
	"updatedAt": "2022-06-13T17:29:42.966Z",
	"createdBy": null,
	"id": 135,
	"name": "Pack Business Connect",
	"brandId": 70,
    "brand": "Volvo"
}, {
	"createdAt": "2022-06-13T14:35:42.404Z",
	"updatedAt": "2022-06-13T14:35:42.404Z",
	"createdBy": null,
	"id": 134,
	"name": "Pack Navi",
	"brandId": 28,
    "brand": "Hyundai"
}, {
	"createdAt": "2022-06-13T14:35:32.615Z",
	"updatedAt": "2022-06-13T14:35:32.615Z",
	"createdBy": null,
	"id": 133,
	"name": "Pack Safety",
	"brandId": 28,
	"brand": "Hyundai"
}, {
	"createdAt": "2022-06-13T14:35:22.266Z",
	"updatedAt": "2022-06-13T14:35:22.266Z",
	"createdBy": null,
	"id": 132,
	"name": "Pack LED",
	"brandId": 28,
	"brand": "Hyundai"
}, {
	"createdAt": "2022-06-13T11:03:14.109Z",
	"updatedAt": "2022-06-13T11:03:14.109Z",
	"createdBy": null,
	"id": 131,
	"name": "Pack Cold Climate",
	"brandId": 36,
	"brand": "Range Rover"
}, {
	"createdAt": "2022-06-08T14:47:12.891Z",
	"updatedAt": "2022-06-08T14:47:12.891Z",
	"createdBy": null,
	"id": 130,
	"name": "Pack Media Nav & Comfort",
	"brandId": 56,
	"brand": "Renault"
}, {
	"createdAt": "2022-06-08T14:47:00.685Z",
	"updatedAt": "2022-06-08T14:47:00.685Z",
	"createdBy": null,
	"id": 129,
	"name": "Pack Easy",
	"brandId": 56,
	"brand": "Renault"
}, {
	"createdAt": "2022-05-26T12:26:08.637Z",
	"updatedAt": "2022-05-26T12:26:08.637Z",
	"createdBy": null,
	"id": 128,
	"name": "Pack Business Plus",
	"brandId": 78,
	"brand": "Opel"
}, {
	"createdAt": "2022-05-23T14:34:00.829Z",
	"updatedAt": "2022-05-23T14:34:00.829Z",
	"createdBy": null,
	"id": 127,
	"name": "Pack Smart",
	"brandId": 67,
	"brand": "Toyota"
}, {
	"createdAt": "2022-05-23T10:46:13.314Z",
	"updatedAt": "2022-05-23T10:46:13.314Z",
	"createdBy": null,
	"id": 126,
	"name": "Pack Cold",
	"brandId": 49,
	"brand": "Nissan"
}, {
	"createdAt": "2022-05-23T10:14:54.053Z",
	"updatedAt": "2022-05-23T10:14:54.053Z",
	"createdBy": null,
	"id": 125,
	"name": "Pack Comfort",
	"brandId": 76,
	"brand": "Skoda"
    
}, {
	"createdAt": "2022-05-19T15:42:39.652Z",
	"updatedAt": "2022-05-19T15:42:39.652Z",
	"createdBy": null,
	"id": 124,
	"name": "Pack Style",
	"brandId": 49,
	"brand": "Nissan"
}, {
	"createdAt": "2022-05-19T15:42:24.063Z",
	"updatedAt": "2022-05-19T15:42:24.063Z",
	"createdBy": null,
	"id": 123,
	"name": "Pack Winter",
	"brandId": 49,
	"brand": "Nissan"
}, {
	"createdAt": "2022-05-19T10:11:02.277Z",
	"updatedAt": "2022-05-19T10:11:02.277Z",
	"createdBy": null,
	"id": 122,
	"name": "Highlights",
	"brandId": 5,
	"brand": "Audi"
}, {
	"createdAt": "2022-05-18T16:07:16.392Z",
	"updatedAt": "2022-05-18T16:07:16.392Z",
	"createdBy": null,
	"id": 121,
	"name": "Pack Black Line",
	"brandId": 32,
	"brand": "Jeep"
}, {
	"createdAt": "2022-05-18T12:14:38.013Z",
	"updatedAt": "2022-05-18T12:14:38.013Z",
	"createdBy": null,
	"id": 120,
	"name": "Pack Winter",
	"brandId": 70,
	"brand": "Volvo"
}, {
	"createdAt": "2022-05-18T12:11:58.329Z",
	"updatedAt": "2022-05-18T12:11:58.329Z",
	"createdBy": null,
	"id": 119,
	"name": "Pack Memory",
	"brandId": 70,
	"brand": "Volvo"
}, {
	"createdAt": "2022-05-18T12:09:18.960Z",
	"updatedAt": "2022-05-18T12:09:31.000Z",
	"createdBy": null,
	"id": 118,
	"name": "Pack Intellisafe Pro",
	"brandId": 70,
	"brand": "Volvo"
}]