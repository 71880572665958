import { KeyValue } from "../key-value";
import { Invoice } from "./invoice";
import { Transaction } from "./transaction";
import { Vehicle } from "./vehicle";

export class PayCharge {
    id: number;
    confirmado: boolean;
    accEntryType: KeyValue;
    administrativeFiles: Array<any>;
    amount: number;
    settledAmount: number;
    vehiclesPay: number;
    createdAt: Date;
    expedient: string;
    invoice: string;
    proformaInvoices: KeyValue//Array<KeyValue>;//Array<Invoice> = [];
    vehicles: Array<Vehicle> = [];
    transactions: Array<Transaction> = [];
    active:boolean;
    remarks:string;
    proformas: Array<KeyValue>;//Array<Invoice> = [];
    proformaInvoiceId:number;
    amountSettLoaded: number;
    bankingTransactionsId: number;
    
  
    constructor(payCharge) {
      this.id = payCharge.id;
      this.confirmado = payCharge.confirmado;
      this.accEntryType = payCharge.accEntryType;
      this.amount = payCharge.amount;
      this.settledAmount=  payCharge.settledAmount; // !payCharge.invoices || payCharge.invoices.map(e=> e.amount).reduce((sum, current) => sum + current);
      this.createdAt = payCharge.createdAt;
      this.expedient = payCharge.expedient;
      this.invoice = payCharge.invoice;
      this.proformaInvoices = payCharge.proformaInvoices || [];
      this.vehicles = payCharge.vehicles || [];
      this.transactions = payCharge.transactions || [];
      this.active = payCharge.active;
      this.remarks = payCharge.remarks;
      this.administrativeFiles = payCharge.administrativeFiles;
      this.vehiclesPay = payCharge.vehiclesPay;
      this.proformas = payCharge.proformas;
      this.proformaInvoiceId = payCharge.proformaInvoiceId;
      this.amountSettLoaded = payCharge.settledAmount;
      this.bankingTransactionsId = payCharge.bankingTransactionsId;
    }
}