import { CommonFile } from "../file";
import { EmptyKeyValue, KeyValue } from "../key-value";
import { Vehicle } from "../vehicles/vehicle";


export class Document {
  id: number;
  createdAt: Date;
  name: string;
  description: string;
  documentType: KeyValue;
  reference: KeyValue;
  referenceId: number;
  referenceName: string;
  documentCategory: KeyValue;
  cars: string[];
  remarks:string;
  file: CommonFile;
  url:string;
  vehicles:Array<Vehicle>;
  selected: boolean = false;
  isVisible: boolean = false;
  originalName:string;
  storageName:string;
  mimeType: string;
  base64: string;
  
  constructor(doc) {
    this.id = doc.id;
    this.createdAt = doc.createdAt;
    this.name = doc.name;
    this.description = doc.description;
    this.documentType = doc.documentType;
    this.referenceId = doc.referenceId;
    this.referenceName = doc.referenceName;
    this.documentCategory = doc.documentCategory;
    this.cars = doc.cars;
    this.remarks= doc.remarks;
    this.reference = doc.reference == null ? new EmptyKeyValue() : new KeyValue(doc.reference);
    this.reference.id = doc.referenceId;
    this.reference.name = doc.referenceName;
    this.file = doc.file;
    this.url = doc.url;
    this.vehicles = doc.vehicles;
    this.originalName = doc.originalName;
    this.storageName = doc.storageName;
    this.mimeType = doc.mimeType;
    this.base64 = doc.base64;
    this.isVisible = doc.isVisible;
  }

}
