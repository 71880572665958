import { NgModule } from "@angular/core";
import { VehicleFilter } from "./car-type";
import { EntityFilter } from "./contact-type";
import { CostFilter } from "./cost-type";
import { ExpedientFilter } from "./expedient-type";
import { OfferFilter } from "./offer-type";
import { OperationFilter } from "./operation-type";
import { OthersType } from "./others-type";
import { AccountingEntriesFilter } from "./pay-type";





@NgModule({
    providers: [ExpedientFilter, 
        VehicleFilter, 
        AccountingEntriesFilter, 
        OthersType, 
        OperationFilter, 
        OfferFilter, 
        CostFilter, 
        EntityFilter]
})
export class TypesFactoryModule {
}
