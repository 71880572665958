import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, config, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/users/user';
import { AppUser } from 'src/app/models/auth/app-user';
import { Router } from '@angular/router';
import { CommonListResponse } from 'src/app/models/common-list-response';
import { MasterService } from './masters.service';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { IModel } from 'src/app/interfaces/model';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<CommonListResponse<AppUser>>;
  public currentUser: Observable<CommonListResponse<AppUser>>;
  isUserLoggedIn: boolean = false;

  constructor(private router: Router,
    private masterService: MasterService<IModel>,
    private http: HttpClient,) {
    this.currentUserSubject = new BehaviorSubject<CommonListResponse<AppUser>>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): CommonListResponse<AppUser> {
    return this.currentUserSubject.value;
  }

  newPassword(item)
  {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        //'X-XSRF-TOKEN': 'eyJpdiI6IjEvRy9sWkpnVGJiVGxGQ0NkbzRJaUE9PSIsInZhbHVlIjoiZFZXWDA1RzdrQ1B1OFZQTGxXM0w3ek0zTHZYYkFPbWRKb1JqYXRrVk1oUjZoT1IwMHNuUTlTL3ZIR0FSaEx6bkptVFpFR0RlbmUyNzV3MVBocllwZ1M0Njc1UG90Q09HNHlCYzBxY0dOb3ZlY05QN3ZnaEdZMzBjdzhzaFdVY0oiLCJtYWMiOiJhMDgxMjNiZWZiMjUxMWQ4ZmU1OWFmMzE0ZWY5MTc3NGFhM2Y3MWUyZmY1N2IxZjY2MTM2N2VkY2Y4N2Y4ZTU1IiwidGFnIjoiIn0%3D'
      })
    };
    return this.http.post<CommonListResponse<AppUser>>(`${environment.apiUrl}newPassword`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  resetPassword(item)
  {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        //'X-XSRF-TOKEN': 'eyJpdiI6IjEvRy9sWkpnVGJiVGxGQ0NkbzRJaUE9PSIsInZhbHVlIjoiZFZXWDA1RzdrQ1B1OFZQTGxXM0w3ek0zTHZYYkFPbWRKb1JqYXRrVk1oUjZoT1IwMHNuUTlTL3ZIR0FSaEx6bkptVFpFR0RlbmUyNzV3MVBocllwZ1M0Njc1UG90Q09HNHlCYzBxY0dOb3ZlY05QN3ZnaEdZMzBjdzhzaFdVY0oiLCJtYWMiOiJhMDgxMjNiZWZiMjUxMWQ4ZmU1OWFmMzE0ZWY5MTc3NGFhM2Y3MWUyZmY1N2IxZjY2MTM2N2VkY2Y4N2Y4ZTU1IiwidGFnIjoiIn0%3D'
      })
    };
    return this.http.post<CommonListResponse<AppUser>>(`${environment.apiUrl}resetPassword`, { item }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  login(email, password, rememberMe) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        //'X-XSRF-TOKEN': 'eyJpdiI6IjEvRy9sWkpnVGJiVGxGQ0NkbzRJaUE9PSIsInZhbHVlIjoiZFZXWDA1RzdrQ1B1OFZQTGxXM0w3ek0zTHZYYkFPbWRKb1JqYXRrVk1oUjZoT1IwMHNuUTlTL3ZIR0FSaEx6bkptVFpFR0RlbmUyNzV3MVBocllwZ1M0Njc1UG90Q09HNHlCYzBxY0dOb3ZlY05QN3ZnaEdZMzBjdzhzaFdVY0oiLCJtYWMiOiJhMDgxMjNiZWZiMjUxMWQ4ZmU1OWFmMzE0ZWY5MTc3NGFhM2Y3MWUyZmY1N2IxZjY2MTM2N2VkY2Y4N2Y4ZTU1IiwidGFnIjoiIn0%3D'
      })
    };
    return this.http.post<CommonListResponse<AppUser>>(`${environment.apiUrl}login?email=${email}&password=${password}`, { email, password }, options)
      .pipe(
        map(response => {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('RememberMe');
          if (response.isSuccessful) {
            sessionStorage.setItem('accessToken', response.data[0].accessToken);
            if (rememberMe) {
              localStorage.setItem('currentUser', JSON.stringify(response.data[0]));
              localStorage.setItem('RememberMe', JSON.stringify(rememberMe));
            }
            this.masterService.getAllMasters();
            this.currentUserSubject.next(response);
          }
          return response;
        }));
  }

  logout(): void {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    this.http.get<any>(`${environment.apiUrl}logOut`, options)
      .subscribe(() => {
        sessionStorage.removeItem('accessToken');
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
      });
  }

}