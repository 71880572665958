import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentsTableRoutingModule } from './documents-table-routing.module';
import { DocumentsTableComponent } from './documents-table.component';
import { PageLayoutModule } from '../../../../../@vex/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import { DocumentCreateUpdateModule } from './document-create-update/document-create-update.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { ConfirmDialogModule } from '../../../apps/common-dialogs/confirm-dialog/confirm-dialog.module';
import { DocumentService } from 'src/app/services/api/document.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';




@NgModule({
  imports: [
    CommonModule,
    DocumentsTableRoutingModule,
    PageLayoutModule,
    BreadcrumbsModule,
    DocumentCreateUpdateModule,
    MatPaginatorModule,
    MatTableModule,
    MatChipsModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonToggleModule,
    ConfirmDialogModule
    
  ],
  declarations: [DocumentsTableComponent],
  providers:[DocumentService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  exports: [DocumentsTableComponent]
})
export class DocumentsTableModule {
}
