export class KeyValue {

    id?: number;
    name: string;
    office?: KeyValue;
    proformaCode?: string;
    vehicleName?: string;
    brand?: any;
    model?:KeyValue;
    //version?: KeyValue;
    
  
    constructor(comboItem) {
  
      this.id = comboItem.id;
      this.name = comboItem.name;
      this.vehicleName = comboItem.name;
    }
    
  
  }
  
  export class EmptyKeyValue  {
    id: number = null;
    name: string = "";

    
    constructor(id?) {
  
      this.id = id || null;
      this.name = null;
    }
    
  }