
import { IFilters } from "src/app/interfaces/ifilters";
import { KeyValue } from "../key-value";

export class ExpedientFilters implements IFilters {
    rowsPerPage: number;
    pageNumber: number;
    orderBy: string;
    order: string;

    id: number = null;
    name: string = "";
    officeId : number;
    orderTypeId : number= null;
    accountingEntryId :number= null;
    administrativeFileReference : string = "";
    entityId : number= null;
    createdAt?: Date= null;
    vin  : string = "";
    plate  : string = "";
    saleAgentId  : number= null;
    brokerId  : number= null;
    countryId  : number= null;
    clientId : number= null;
    providerId : number= null;
    isPaid?: boolean = null;
    isCollected?: boolean = null; 
    proformaInvoiceId: number= null;
    administrativeFileId: number= null;
    vehicleCostTypeId: number= null;
    costTypeId?:number = null; 
    bankingTransactionId?: number = null;
    concept?: string = null;
    proformaInvoices?: Array<KeyValue> = [];
    accountingEntryTypeId?:number = null;
    remarksLike?:string = null;
    shipmentId?:number = null;
    orderType?: KeyValue = null;
    
   
    
   
}   