import { Component, EventEmitter, Input, Output } from '@angular/core';

import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';

import { CommonFilter } from 'src/app/models/masters/common-filter';
import { Transaction } from 'src/app/models/pays-charges/transaction';
import { TransactionsFilter } from 'src/app/models/pays-charges/transations-filter';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { Invoice } from '../../../../../models/pays-charges/invoice';



@UntilDestroy()
@Component({
  selector: 'vex-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class TransactionsComponent {

  @Input() tableData: Array<Transaction>;
  @Input() invoices: Array<Invoice>;
  @Input() service: string = "bankingTransactions/accountingentries";
  @Input() amount: number;
  @Input() sign: number;
  @Input() deleteService :string = "bankingTransactions/accountingentries";

  _settledAmount: number = 0;
  _amountSett: number = 0;

  @Input()
  set amountSett(val: number) {
    this._amountSett = val;
    this.params = this.getParams();
  }
  get amountSett() {
    return this._settledAmount;
  }

  @Input()
  set settledAmount(val: number) {
    this._settledAmount = val;
    this.updatedSettledAmount.emit(val);
  }
  get settledAmount() {
    //this.tableData = this.params?.list?.tableData;
    return this._settledAmount;
  }



  @Output() updatedSettledAmount: EventEmitter<number> = new EventEmitter<number>();

  list: ITableParams<Transaction, Transaction>;

  @Input() filters: CommonFilter;

  @Output() params: ITableParams<Transaction, Transaction> = null;

  transactionsFilter: TransactionsFilter;

  total=0;
  pageSize = 5;
  pageIndex = 0;
  order = "asc";
  orderType = "";

  getIsUpdatedValue(value) {
    this.settledAmount = value;
    
  }
  columns: Array<TableColumn<Transaction>> = [
    { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Fecha', property: 'transactionDate', type: 'date', visible: true },
    { label: 'Banco', property: 'bank', type: 'select', visible: true },
    { label: 'Concepto', property: 'concept', type: 'text', visible: true },
    { label: 'Importe', property: 'amount', type: 'number', visible: true },
    { label: 'Importe liquidado', property: 'amountSett', type: 'number', visible: true }
  ];
  ngOnInit() {
    // this.list.tableData = invoicesTableData.map(invoice => new Invoice(invoice));
    this.transactionsFilter = new TransactionsFilter(this.sign)
    this.loadFilters();
    this.params = this.getParams();
  }

  getParams(){
    const params: ITableParams<Transaction, Transaction> =  {

      isMenu: false,
      element: "Movimiento bancario",
      title: 'Movimientos bancarios',
      titleProperty: "concept",
      //tableData: this.tableData,
      service: "accountingentries/bt",
      deleteService: this.deleteService,
      filters: this.filters,
      hasImage: false,
      columns: this.columns,
      list: {
        isMenu: false,
        element: "Movimiento bancario",
        title: 'Movimientos bancarios',
        titleProperty: 'concept',
        service: 'bankingTransactions', // AÑADIR SIGNO
        filters: this.transactionsFilter,
        paginatedOnBack: true,
        extraValue: Number(this._amountSett) as unknown as object,
        showAmount: true,
        hasImage: false,
        columns: [
          { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
          { label: 'Fecha', property: 'transactionDate', type: 'date', visible: true },
          { label: 'Banco', property: 'bank', type: 'select', visible: true },
          { label: 'Concepto', property: 'concept', type: 'text', visible: true },
          { label: 'Importe', property: 'amount', type: 'number', visible: true },
          { label: 'Importe liquidado', property: 'amountSett', type: 'number', visible: true }

        ],
        //tableData: transactionsTableData.map(tran => new Transaction(tran)) || [],

      }
    }
    return params;
  }

  loadFilters() {
    this.transactionsFilter.pageNumber = this.pageIndex + 1;
    this.transactionsFilter.rowsPerPage = 10;
    this.transactionsFilter.order = this.order;
    this.transactionsFilter.orderBy = this.orderType;
    //this.transactionsFilter.sign = this.sign;
    
  }
}