import { KeyValue } from "../key-value";
import { CommonFilter } from "../masters/common-filter";
import { Entity } from "./entity";


export class TransferData {
    values?: Entity;
    type?: string;
    price?:number; 
    isMenu?:boolean;
    service?: string;
    documentTypeId?: number;
    reference?: KeyValue;
    filters?: CommonFilter;
    profit?: number;
    sign?: number;
    payChargeTitle?: string;
    documentCategoryId: number;
    readOnly?: boolean;
    transmissionType?: KeyValue;
    fuelType?: KeyValue;


    constructor(data) {
        this.values = data.values;
        this.type = data.type;
        this.price = data.price;
        this.isMenu = data.isMenu;
        this.service = data.service;
        this.documentTypeId = data.documentTypeId;
        this.reference = data.reference;
        this.filters = data.filters;
        this.profit = data.profit;
        this.sign = data.sign;
        this.payChargeTitle = data.payChargeTitle;
        this.documentCategoryId = data.documentCategoryId;
        this.readOnly = data.readOnly;
        this.transmissionType= data.transmissionType;
        this.fuelType= data.fuelType;
      }
    
}