import { IFilters } from "src/app/interfaces/ifilters";


export class BasicFilters implements IFilters{
    rowsPerPage: number = 10000;
    pageNumber: number = 1;   
    orderBy: string = "";
    order: string = "asc";
    
    equipmentClassId: number = null;
    name:  string = "";

}