import { IFilters } from "src/app/interfaces/ifilters";
import { Control } from "./control";

export class Item<T> {
    hasImage: boolean;
    element: string;
    values: T[];
    fields: Control[];
    titleProperty: string;
    extraValue?:string;
    filters?: IFilters;
    service?: string;
    linkService?: string;
  
    constructor(item) {
      this.hasImage = item.hasImage;
      this.fields = item.fields;
      this.element= item.element;
      this.values = item.values;
      this.titleProperty = item.titleProperty;
      this.extraValue = item.extraValue;
      this.filters = item.filters;
      this.service = item.service;
      this.linkService = item.linkService;
    }
  
  }
  