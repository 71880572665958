
<h2 mat-dialog-title>Reparto del importe</h2>
<mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field 
      class="flex-auto" required>
      <mat-select [(value)]="selectedItem" cdkFocusInitial placeholder="Seleccionar reparto">
        <mat-option value="1">Proporcional</mat-option>
        <mat-option value="2">Porcentaje</mat-option>
        <mat-option value="3">Pago total</mat-option>

    </mat-select>
    </mat-form-field>
      <mat-form-field [ngClass]="{ 'hidden': selectedItem != '2' }" class="sm:ml-6 flex-auto">
        <mat-label>%</mat-label>
        <input matInput pInputText  required [(ngModel)]="percentage">
     </mat-form-field>
    </div>
  </mat-dialog-content>
<div mat-dialog-actions align="end" >
    <button mat-button (click)="closeDialog()">Cancelar</button>
    <button color="primary" mat-flat-button  (click)="confirm()" cdkFocusInitial>Aceptar</button>
</div>