import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, catchError, throwError, lastValueFrom } from 'rxjs';
import { CommonListResponse } from 'src/app/models/common-list-response';
import { Document } from 'src/app/models/documents/document';
import { retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DocFilters } from 'src/app/models/documents/doc-filters';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { CommonFile } from 'src/app/models/file';
import { Base64 } from 'src/app/models/documents/base64';




@Injectable({
  providedIn: 'root',
})
export class DocumentService {
    private masterSubject: BehaviorSubject<CommonListResponse<Document>>;


  constructor(private http: HttpClient) {
  }

  public get currentUserValue(): CommonListResponse<Document> {
    return this.masterSubject.value;
  }

  getImages(filters: CommonFilter) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<Document>>(`${environment.apiUrl}images/offers/get`, {filters}  ,options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  getAll(filters: DocFilters) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<Document>>(`${environment.apiUrl}documents/get`, {filters}  ,options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  updateDocument(document: Document) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<Document>>(`${environment.apiUrl}documents/update`, { document }, options)
      .pipe(
        map(response => {
          
          return response;
        }));
  }

  createDocument(document: Document) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<Document>>(`${environment.apiUrl}documents/register`, { document }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  delete(item: Document) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<Document>>(`${environment.apiUrl}documents/delete`, { item }, options)
      .pipe(
        map(response => {
          //this.getDocument().subscribe();;
          return response;
        }));
  }
  getDocument(item: Document) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<Base64>(`${environment.apiUrl}files/document/get`, { item }, options)
      .pipe(
        map(response => {
          //this.getDocument().subscribe();;
          return response;
        }));
  }
  async addDocument(document: Document) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return await lastValueFrom(this.http.post<CommonListResponse<Document>>(`${environment.apiUrl}document`, { document }, options)
      .pipe(
        //catchError(this.handleError), 
        map(response => {
          return response;
        })));
  }

}