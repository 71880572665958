
import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, Input, isDevMode, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable, startWith, map } from 'rxjs';
import { ComboItem } from 'src/app/models/combo-item';
import { Basic } from 'src/app/models/equipments/basic';
import { Optional } from 'src/app/models/equipments/optional';
import { VehicleVersion } from 'src/app/models/equipments/vehicle-version';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { VehicleFilters } from 'src/app/models/vehicles/vehicle-filters';
import { CommonService } from 'src/app/services/api/common.service';
import { MasterService } from 'src/app/services/api/masters.service';
import { ToastService } from 'src/app/services/toast/toast.service';


@Component({
  selector: 'vex-optional-create-update',
  templateUrl: './optional-create-update.component.html',
  styleUrls: ['./optional-create-update.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable({ providedIn: 'root' })
export class OptionalCreateUpdateComponent implements OnInit, AfterContentChecked {

  layoutCtrl = new UntypedFormControl('fullwidth');

  _tableData: Array<Basic>;
  @Input()
  set tableData(val: Array<Basic>) {
    this.dataChange.emit(val);
    this._tableData = val;
  }
  get tableData() {
    return this._tableData;
  }
  @Output() dataChange: EventEmitter<Array<Basic>> = new EventEmitter<Array<Basic>>();

  @Output() changedModelEvent = new EventEmitter<Array<ComboItem>>();

  _packs: Array<ComboItem>;

  @Input()
  set packs(val: Array<ComboItem>) {
    this.changedModelEvent.emit(val);
    this._packs = val;
  }
  get packs() {
    //this.tableData = this.params?.list?.tableData;
    return this._packs;
  }

  point: string = ".";
  optionalType: string;
  loadPacks = true;


  optionalEquipmentHasBasics: Array<Basic> = [];
  vehicleVersions: Array<ComboItem>;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  vehicleFilters: any;

  categories: Array<ComboItem>;
  filteredModels: Observable<Array<KeyValue>>;

  model: string = "";


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<OptionalCreateUpdateComponent>,
    private router: Router,
    private fb: FormBuilder,
    private alert: ToastService,
    private masterService: MasterService<KeyValue>,
    private cd: ChangeDetectorRef,
    private commonService: CommonService<Optional, CommonFilter>) { }

  ngOnInit() {
    (async () => {
      if (this.defaults.values && this.defaults?.values?.id != null) {
        this.mode = 'update';
        this.defaults.values.vehicleVersion.name = this.defaults.values.nameVehiculo;
      }
      else if (this.defaults.values && this.defaults?.values?.id == null) {
        this.defaults.values.vehicleVersion.name = this.defaults.values.nameVehiculo;
      }
      else {
        this.defaults.values = {} as Optional;
      }



      this.getItems();

      this.model = this.defaults?.values?.vehicleVersion?.name || "";

      this.optionalType = this.defaults.optionalType;
      this.optionalEquipmentHasBasics = this.defaults.values.optionalEquipmentHasBasics || [];
      this.categories = this.defaults.categories;
      //this.packs = this.defaults.packs.filter(x=> x.vehicleBrand?.id == this.defaults.values.vehicleBrand?.id);


      this.form = this.fb.group({
        name: [{ value: this.defaults.values.name, disabled: false }],
        createdAt: [this.defaults.values.createdAt ? new Date(this.defaults.values.createdAt) : new Date(), Validators.required],
        vehicleVersion: [this.defaults.values.vehicleVersion || '', Validators.required],

      });
    })();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  async getItems() {
    const keyValue = new EmptyKeyValue;
    this.vehicleFilters = new VehicleFilters(keyValue, keyValue, "");
    this.vehicleVersions = (await this.masterService.getAllFilteredAsync("vehicleVersions", this.vehicleFilters) as unknown as Array<MasterCommon>)?.filter(x => x.name?.trim());
    this.loadPacks = false;
    if (this.defaults.values?.vehicleVersion)
      this.packs = await this.masterService.getAllItemsFilter("equipmentPacks", new VehicleVersion(this.defaults.values?.vehicleVersion)) as unknown as Array<MasterCommon>;
    this.loadPacks = true;
  }

  async changeVersion(version) {
    this.loadPacks = false;
    if (version?.id)
      this.packs = await this.masterService.getAllItemsFilter("equipmentPacks", new VehicleVersion(version)) as unknown as Array<MasterCommon>;
    this.loadPacks = true;
    //this.changedModel(this.packs);
  }

  duplicateOptional() {
    this.mode = 'create';
    this.defaults.values.id = null;
    this.form.value.id = null;
    //this.save()
  }
  save() {
    this.setModel();
    if (this.mode === 'create') {
      this.createOptional();
    } else if (this.mode === 'update') {
      this.updateOptional();
    } else if (this.mode === 'menu') {
      this.createOptional();
    }
  }
  getTableData(tableData) {
    this.optionalEquipmentHasBasics = tableData;
  }
  createOptional() {
    const optional = this.form.value;
    optional.isActive = true;
    optional.optionalEquipmentHasBasics = this.optionalEquipmentHasBasics || [];
    this.addOptional(optional);
  }

  updateOptional() {
    const optional = this.form.value;
    optional.id = this.defaults.values.id;
    optional.optionalEquipmentHasBasics = this.optionalEquipmentHasBasics || [];
    this.modifyOptional(optional);
  }

  addOptional(optional: Optional) {
    this.commonService.create('optionalEquipments', optional).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible dar de alta el equipamiento opcional");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              optional.id = response.data[0].id;
              optional.nameVehiculo = optional.vehicleVersion.name;
              this.dialogRef.close(optional);
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible dar de alta el equipamiento opcional");
          }
      });

  }

  modifyOptional(optional: Optional) {
    this.commonService.update('optionalEquipments', optional).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible actualizar el equipamiento opcional");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              optional.nameVehiculo = optional.vehicleVersion.name;
              this.dialogRef.close(optional);
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible actualizar el equipamiento opcional");
          }
      });

  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
  /* 
    isMenuMode() {
      return this.mode === 'menu';
    } */

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }


  selectedRole(event: MatSelectChange) {
    this.defaults.role = event.source.triggerValue;
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  optionSelected(input: HTMLInputElement) {
    input.blur();
    input.setSelectionRange(0, 0);
    input.focus();
  }

  clearModels(event) {
    //event.target.value = '';
    //this.changeVersion(event);
    this.filteredModels = this.form.controls.vehicleVersion.valueChanges.pipe(
      startWith(''),
      map(value => this.filterModel(value || '')),
    );

  }

  private getModel(value: any): any[] {
    if (!value?.name) {
      if (value == undefined)
        value = '';
      return this.vehicleVersions.filter(option => option.name.toUpperCase() == value?.toUpperCase());
    }
  }

  private filterModel(value: any): any[] {
    if (!value?.name) {
      if (value == undefined)
        value = '';
      return this.vehicleVersions.filter(option => option.name.toUpperCase().includes(value?.toUpperCase()));
    }
  }

  displayFn(option) {
    if (option && option.name) {
      return option.name.toUpperCase();
    } else {
      return option
    }
  }

  setModel() {
    let result = false;
    if (this.vehicleVersions) {
      let input = this.form.value['vehicleVersion'];
      let matchingOption = input;
      if (this.getModel(input)) {
        matchingOption = this.getModel(input)[0];
        result = true;
      }

      this.form.value['vehicleVersion'] = matchingOption;
    }

    return result;

  }
}
