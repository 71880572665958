import { IFilters } from "src/app/interfaces/ifilters";
import { EmptyKeyValue, KeyValue } from "../key-value";

export class VehicleFilters implements IFilters {

    brand: KeyValue = new EmptyKeyValue();
    model: KeyValue= new EmptyKeyValue();
    version:string = "";
    name: string = "";

    rowsPerPage: number;
    pageNumber: number;
    orderBy: string;
    order: string;
    hasSale?: boolean = null;
    hasShipment? = null;
    id?:number = null;
    shipmentId? = null;
    accountingEntryId? = null;
    constructor(brand, model, version) {
  
      this.brand = brand;
      this.model = model;
      this.version = version;
      this.hasSale = null;
    }
 
    
  
  }
  