import { expedientsTableData } from "src/app/mocks/expedientes";
import { ComboItem } from "src/app/models/combo-item";
import { IDocumentTypes } from "./document-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { EntityFilters } from "src/app/models/contacts/entity-filters";
import { ExpedientFilters } from "src/app/models/expedients/expedient-filters";

@Injectable()
export class ExpedientFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters> {
        return of (new ExpedientFilters());
    }
}