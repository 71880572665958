import { expedientsTableData } from "src/app/mocks/expedientes";
import { ComboItem } from "src/app/models/combo-item";
import { IDocumentTypes } from "./document-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { PayChargeFilters } from "src/app/models/pays-charges/pay-charge-filters";

@Injectable()
export class AccountingEntriesFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters>{
        return of(new PayChargeFilters());
    }
}