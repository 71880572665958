import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { KeyValue } from '../models/key-value';
import { AuthenticationService } from '../services/api/auth.service';
import { StorageService } from '../services/utils/storage.service';



@Injectable({
   providedIn: 'root'
})
export class AdminGuard implements CanActivate {

   roles: Array<KeyValue>;
   constructor(private storageService: StorageService, private authService: AuthenticationService, private router: Router) {
      this.storageService.getItem("currentUser").subscribe(appUser => {
         appUser = JSON.parse(appUser);
         this.roles = appUser?.roles;
      })
   }

   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean | UrlTree {
      let url: string = state.url;
      /*  this.storageService.getItem("currentUser").subscribe(appUser => {
          appUser = JSON.parse(appUser);
          this.roles = appUser?.roles;
       }) */
      return this.checkLogin(next, url);
   }

   checkLogin(route: ActivatedRouteSnapshot, url: string): true | UrlTree {
      let val: string = sessionStorage.getItem('accessToken');

      if (val) {
         if (url == "/login")
            this.router.parseUrl('/users');
         else {
            if (this.roles.filter(f => route.data.roles.includes(f.name).length > 0))
               return true;
           /*  const result = this.roles.filter(f => route.data.roles.includes(f.name));
            if (result.length > 0)
              return  true; */

         }
      } else {
         return this.router.parseUrl('/login');
      }
   }

   haveAccess(route) {
      //routes.filter(x => x.path == route)
      const result = this.roles.filter(f => route.roles.includes(f.name));
      if (result.length > 0)
         return true;
      return false;
   }

}