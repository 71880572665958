import { IContactTypes } from "./contact-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { EntityFilters } from "src/app/models/contacts/entity-filters";

@Injectable()
export class ClientType implements IContactTypes {
    public getFilters(): Observable<IFilters> {
    return of(new EntityFilters(3));
}
}