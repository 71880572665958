import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CostCreateUpdateComponent } from './cost-create-update.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MondayDateAdapter } from 'src/app/pages/apps/adapters/date-adapter';
import { InvoicesModule } from '../../pays-charges/invoices/invoices.module';
import { TransactionsModule } from '../../pays-charges/transactions/transactions.module';
import { DocumentsTableModule } from '../../../documentation/documents/documents-table.module';
import { ConceptsModule } from '../concepts/concepts.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContactTypesFactoryModule } from 'src/app/pages/apps/factories/cost-types/contact-types-factory.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { DynamicInputModule } from 'dynamic-input-width';
import { MatAutocompleteOptionsScrollModule } from 'src/app/directives/options-scroll.directive.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    NgMultiSelectDropDownModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTabsModule,    
    ConceptsModule,
    InvoicesModule,
    TransactionsModule,
    MatDatepickerModule,
    DocumentsTableModule,
    ContactTypesFactoryModule,
    DragDropModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatAutocompleteModule,
    MatExpansionModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DynamicInputModule ,
    MatAutocompleteOptionsScrollModule,
    MatNativeDateModule
  ],
  declarations: [CostCreateUpdateComponent],
  exports: [CostCreateUpdateComponent]
})
export class CostCreateUpdateModule {
}
/* 
  ],
  declarations: [CostCreateUpdateComponent],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, {provide: DateAdapter, useClass: MondayDateAdapter }],
})
export class CostCreateUpdateModule {
}
 */