export class DocFilters {

  nameLike: string = "";
  documentTypeId: number = 0;
  documentCategoryId: number = null;
  referenceName: string = "";
  rowsPerPage: number = null;
  pageNumber: number = null;   
  orderBy: string;
  order: string = "asc";
  referenceId: number = null;


}
