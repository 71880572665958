import { IFilters } from "src/app/interfaces/ifilters";


export class TransactionsFilter implements IFilters{
    rowsPerPage: number = 5000;
    pageNumber: number = 1;   
    orderBy: string = "";
    order: string = "asc";
    name:string = "";
    id: number = null;
    
    bankId: number = null;
    officeId: number= null;
    sign: number= null;
    amount:  string = "";
    conceptLike:  string = "";
    transactionDate: Date= null;
    constructor(sign) {
        this.sign = sign;
    }

    /* constructor(filters) {
        this.sign = filters.sign;
        this.rowsPerPage = filters.rowsPerPage;
        this.pageNumber= filters.pageNumber;   
        this.orderBy= filters.orderBy;
        this.order= filters.order;
        this.name= filters.name;
        
        this.bankId= filters.bankId;
        this.officeId= filters.officeId;
        this.amount= filters.amount;
        this.conceptLike= filters.conceptLike;
        this.transactionDate= filters.transactionDate;
        this.id= filters.id;
    }
 */

}