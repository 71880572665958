import { ChangeDetectorRef, Component, Inject, Injector, isDevMode, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ComboItem } from 'src/app/models/combo-item';
import { Invoice } from '../../../../../models/pays-charges/invoice';
import { Document } from '../../../../../models/documents/document';
import { Concept } from '../../../../../models/costs/concept';
import { Cost } from 'src/app/models/costs/cost';
import { Transaction } from 'src/app/models/pays-charges/transaction';
import { Entity } from 'src/app/models/contacts/entity';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { BehaviorSubject, debounceTime, exhaustMap, Observable, of, scan, startWith, Subject, switchMap, tap } from 'rxjs';
import { takeWhileInclusive } from 'rxjs-take-while-inclusive';
import { IModel } from 'src/app/interfaces/model';
import { CommonService } from 'src/app/services/api/common.service';
import { MasterService } from 'src/app/services/api/masters.service';
import { UserService } from 'src/app/services/api/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { IFilters } from 'src/app/interfaces/ifilters';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { DecimalPipe } from '@angular/common';
import { ExpedientFilters } from 'src/app/models/expedients/expedient-filters';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/utils/storage.service';
import { contactCostTypes, contactsCostTypes } from 'src/app/pages/apps/factories/cost-types/contact-cost-types';


@Component({
  selector: 'vex-cost-create-update',
  templateUrl: './cost-create-update.component.html',
  styleUrls: ['./cost-create-update.component.scss']
})
export class CostCreateUpdateComponent implements OnInit {

  serviceName: string = 'accountingentries/costs';
  administrativeFiles = 'administrative-files';
  deleteService: string = 'bankingTransactions/accountingentries/costs';
  costType: string;
  amountSett: number = 0;

  requiredReference: boolean = false;
  sign: number;

  public service: any;
  items: Array<KeyValue> = [];

  entityTypes: Array<KeyValue>;

  typesItems: Array<KeyValue>;

  isMenu: boolean;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  reference: KeyValue = new EmptyKeyValue();
  documentType: string = "Coste";

  costTypes: Array<ComboItem> = [];
  commercialType: Array<ComboItem> = [];
  brokers: Array<ComboItem> = [];
  customerProvider: Array<ComboItem> = [];
  purchaseTypes: Array<ComboItem> = [];
  province: Array<ComboItem> = [];
  interestBrands: Array<ComboItem> = [];
  interestVehicles: Array<ComboItem> = [];
  CarDealarshipBrands: Array<ComboItem> = [];
  invoicesTableData: Array<Invoice> = [];
  transactionsTableData: Array<Transaction> = [];
  paysChargesTableData: Array<Cost> = [];
  conceptsTableData: Array<Concept> = [];
  documentsTableData: Array<Document> = [];

  filteredOptions: Observable<KeyValue[]>;
  entityFilters: IFilters;
  next$ = new Subject();
  startSearch = new BehaviorSubject<any>('');
  startSearch$ = this.startSearch.asObservable();

  filters: CommonFilter;
  costFilters: ExpedientFilters = new ExpedientFilters();
  payChargeType: string;
  settledAmount: number = 0;
  vehiclesPay: number = 0;
  type: KeyValue = null;
  amount: number;
  readOnly: boolean = false;
  appUser;
  adminManager: boolean = false;
  editabled: boolean = false;
  

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<CostCreateUpdateComponent>,
    private router: Router,
    private fb: FormBuilder,
    private injector: Injector,
    private masterService: MasterService<IModel>,
    private _decimalPipe: DecimalPipe,
    private alert: ToastService,
    private commonService: CommonService<Cost, ExpedientFilters>,
    private masterServiceNoCache: CommonService<IModel, IFilters>,
    public scroll: ScrollDispatcher,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService) {
      this.appUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  

  ngOnInit() {
    if (this.defaults.values) {
      this.mode = 'update';
      /* this.loadData(); */
    } else {
      this.defaults.values = {} as Cost;
    }
    this.type = this.defaults?.type;

    if (this.defaults?.type == "Coste" || this.defaults?.type == "Pago")
      this.sign = -1;
    else
      this.sign = 1;

    this.amountSett = Number(this.defaults?.values?.amount || 0) - Number(this.defaults?.values?.settledAmount || 0);
    
    this.filters = new CommonFilter(this.defaults?.values?.id);
    //this.filters.name = this.defaults?.values?.name;
    this.amount = Number(this.defaults?.values?.amount);
    this.readOnly = this.defaults?.readOnly;
    this.adminManager = (this.appUser.roles.filter(y => y.id == 4).length == 1) && (this.appUser.roles.filter(y => y.id == 1 || y.id == 10).length == 0);
    this.editabled = this.appUser.roles.filter(y => y.id == 4 || y.id == 1).length > 0;


    this.invoicesTableData = this.defaults.values.invoices || [];
    this.transactionsTableData = this.defaults.values.transactions || [];
    this.paysChargesTableData = this.defaults.values.paysCharges || [];
    this.conceptsTableData = this.defaults.values.concepts || [];
    this.costType = this.defaults.costType || [];
    this.documentsTableData = this.defaults.values.documents || [];
    if (!this.defaults.values.entity?.id)
      this.defaults.values.entity = null;

    this.isMenu = this.defaults.isMenu;

    let decimal_formatted = this._decimalPipe.transform(this.defaults.values.amount, "1.0-2", "es")

    this.form = this.fb.group({
      createdAt: [{ value: this.defaults.values.createdAt ? new Date(this.defaults.values.createdAt) : new Date(), disabled: !this.editabled }, Validators.required],
      amount: [{ value: decimal_formatted, disabled: !this.editabled }],
      costType: [{ value: this.defaults.values.costType || '', disabled: !this.editabled }, Validators.required],
      name: [{ value: this.defaults.values.name || '', disabled: !this.editabled }, Validators.required],
      costEntityType: [{ value: this.defaults.values.costEntityType || '', disabled: !this.editabled }, Validators.required],
      entity: [{ value: this.defaults.values.entity || '', disabled: !this.editabled }, Validators.required],
      transactionDate: [{ value: this.defaults.values.transactionDate, disabled: true }],
      remarks: [{ value: this.defaults.values.remarks || '', disabled: !this.editabled }],
      /*  entityName: [this.defaults.values.entityName || '', Validators.required] */
    });


    this.getItems();

    if (this.form?.value?.costEntityType)
      this.loadTypeOptions();

    const keyValue = new EmptyKeyValue;
    keyValue.id = this.defaults.values.id || null;
    keyValue.name = this.defaults.values.name || "";
    this.reference = keyValue;

  }

  loadFilters() {
    this.costFilters.pageNumber = 0;
    this.costFilters.rowsPerPage = 1;
    this.costFilters.order = "asc";
    this.costFilters.orderBy = "";
    this.costFilters.id = this.defaults.values.id;

    this.storageService.getItem("origin").subscribe(origin => {
      if(this.costFilters.officeId != origin){
        this.costFilters.officeId = origin;
      }
    })

  }


  loadData(event) {
    if (event.index == 0) {
      this.loadFilters();
      this.commonService.getAll(this.serviceName, this.costFilters).subscribe({
        next:
          response => {
            if (!response) {
              this.alert.error("No hay costes");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              //this.defaults.values = new Cost(response.data[0]);
              let decimal_formatted = this._decimalPipe.transform(response.data[0].amount, "1.0-2", "es")
              this.form.controls['amount'].patchValue(decimal_formatted);


            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible recuperar el coste");
          }
      });
    }
  }

  async getItems() {
    //const entities = await this.masterService.getAll("entities") as unknown as Array<Entity>;
    const entities = await this.masterServiceNoCache.getAllItems("entities") as unknown as Array<Entity>;
    this.brokers = entities.filter(x => x.entityType?.id == 1).map(x => new KeyValue(x));
    this.entityTypes = (await this.masterService.get("costEntityTypes")).map(x => new KeyValue(x));
    this.typesItems = (await this.masterService.get("costTypes")).map(x => new KeyValue(x));
  }



  save() {
    if (this.mode === 'create') {
      this.createCost();
    } else if (this.mode === 'update') {
      this.updateCost();
    }
  }
  getIsUpdatedValue(value) {
    //this.amountSett = value;
    
    if(Number(value) == Number(this.defaults?.values?.amount)){
      this.defaults.values.settledAmount = 0;
      this.amountSett = Number(this.defaults?.values?.amount);
    }
    else{
      this.defaults.values.settledAmount =  Number(this.defaults.values.amount) - Number(value) ;
      this.amountSett = Number(value);
    } 
  }

  getIsUpdatedConceptValue(value) {
    //this.amountSett = value;
    

      this.amountSett = Number(this.amountSett) + Number(value);
      this.amount = Number(this.amount) + Number(value);
  }
  getSettledAmount() {
    //if(this.defaults.values.accEntryType?.id)
    const value = (this.defaults?.values?.amount || 0) - (this.defaults?.values?.settledAmount || 0) - this.settledAmount;
    return value;
  }
  

  getPayAmount() {
    //if(this.defaults.values.accEntryType?.id)
    const value = (this.defaults?.values?.amount) || 0 - (this.defaults?.values?.vehiclesPay || 0) - this.vehiclesPay;
    return value;
  }
  createCost() {
    const cost = this.form.value;
    //cost.entity = cost.
    /* if(cost.concepts)
      cost.amount = cost.concepts.length <= 0 ? 0 : cost.concepts.map(e=> e.amount).reduce((sum, current) =>parseFloat(sum) + parseFloat(current)); */
    this.addCost(cost);
  }

  updateCost() {
    const cost = this.form.value;
    cost.id = this.defaults.values.id;
    //cost.entity = cost.

    if (cost.concepts)
      cost.amount = !cost.concepts ? 0 : cost.concepts.map(e => e.amount).reduce((sum, current) => parseFloat(sum) + parseFloat(current));
    this.modifyCost(cost);
  }

  addCost(cost: Cost) {
    this.commonService.create(this.serviceName, cost).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible dar de alta el coste");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
              //this.ngOnInit();
            }
            else {
              cost.id = response.data[0].id;
              this.defaults.values = cost;
              this.filters = new CommonFilter(cost.id);
              if (this.defaults.values.accEntryType?.id == 1)
                this.sign = -1;
              else if (this.defaults.values.accEntryType?.id == 2)
                this.sign = 1;
              else
                this.sign = 0;

                if(this.readOnly)
                  this.dialogRef.close(response.data[0]); 
                else
                  this.mode = "update";
              /*  if(this.isMenu)
                 this.mode = "update";
               else
                 this.dialogRef.close(response.data[0]); */
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible dar de alta el coste");
          }
      });

  }


  deleteCost() {
    const cost = this.form.value;
    cost.id = this.defaults.values.id;
    this.commonService.delete(this.serviceName, cost).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido borrar el coste");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            this.alert.info("Coste eliminada con éxito");
            this.dialogRef.close();
          }
        },
      error:
        (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido borrar el coste");
        }
    });

  }
  modifyCost(cost: Cost) {

    this.commonService.update(this.serviceName, cost).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible actualizar el coste");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              this.defaults.values = response.data[0];
              this.dialogRef.close(this.defaults.values);
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible actualizar el coste");
          }
      });

  }
  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }


  selectedRole(event: MatSelectChange) {
    this.defaults.role = event.source.triggerValue;
  }

  changeType(type: any) {
    this.loadTypeOptions();
    this.form.get('entity').setValue("");
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }


  displayFn(option) {
    if (option && option.name) {
      return option.name;
    } else {
      return option
    }
  }
  onScroll() {
    this.next$.next('');
  }

  onPanelOpen(weight: any) {

    // this.startSearch.next(weight.controls['weight'].value);
    this.startSearch.next('');
  }
  loadTypeOptions() {
    this.filteredOptions = this.startSearch$.pipe(
      startWith(''),
      debounceTime(200),
      switchMap(filter => {
        //Note: Reset the page with every new seach text
        let currentPage = 1;
        return this.next$.pipe(
          startWith(currentPage),
          //Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(_ => this.getEntities(this.form?.value?.costEntityType?.id, currentPage)),
          tap(() => currentPage++),
          //Note: This is a custom operator because we also need the last emitted value.
          //Note: Stop if there are no more pages, or no results at all for the current search text.
          takeWhileInclusive((p: any) => p.length > 0),
          scan(
            (allItems: any, newItems: any) =>
              allItems.concat(newItems),
            []
          )
        );
      })
    );
  }
  SearchWeightuggestions(val: any) {

    //const d = this.getEntities(String(val),1);
    const d = this.getEntities(this.form?.value?.costEntityType?.id, 1);
    this.startSearch.next(val);
    this.cdr.detectChanges();
  }

  getEntities(typeId: number, page: number): Observable<any[]> {

    if (typeId) {
      if (typeId == 2) {
        return this.masterService.getSync("comercialsAll");
      }
      else {
        const take = 6;
        //const skip = page > 0 ? (page - 1) * take : 0;
        const injectable = contactCostTypes.get(typeId);
        // Inject service
        this.service = this.injector.get(injectable);
        this.service.getFilters().subscribe((filters) => {
          this.entityFilters = filters;
        })
        if (this.entityFilters) {
          const id = contactsCostTypes.get(typeId);
          this.entityFilters.contactType = id;
          this.entityFilters.pageNumber = page;
          this.entityFilters.rowsPerPage = 6;
          this.entityFilters.order = "asc";
          this.entityFilters.orderBy = "name";
          this.entityFilters.name = this.form.value.entity?.name ? '' : this.form.value.entity;
          return this.masterService.getAllFiltered("entities", this.entityFilters);
        }
      }
    }
    return of([]);
  }

  ngAfterViewInit() {
    this.scroll.scrolled().subscribe((data: CdkScrollable) => {
      //console.log(data);
      //make an HTTP call when it reaches to the end, to add some more data
    });
  }

  optionSelected(input: HTMLInputElement) {
    input.blur();
    input.setSelectionRange(0, 0);
    input.focus();
  }

  close() {
    this.dialogRef.close(new Invoice(this.defaults.values));
  }

}


