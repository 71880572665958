import { IContactTypes } from "./contact-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { EntityFilters } from "src/app/models/contacts/entity-filters";

/* @Injectable()
export class CommercialType implements IContactTypes {
    public getItems(): Observable<Array<ComboItem>> {
        return of(commercialsTableData.map(({ id: id, name: value }) => ({ id, value })));
    }
} */

@Injectable()
export class CommercialType implements IContactTypes {
    public getFilters(): Observable<IFilters> {
    return of(new EntityFilters(2));
}
}