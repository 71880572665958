import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { BrokerFilters } from "src/app/models/contacts/broker-filters";
import { EntityFilters } from "src/app/models/contacts/entity-filters";
import { EmptyKeyValue } from "src/app/models/key-value";
import { IContactTypes } from "./contact-types";

@Injectable()
export class BrokerType implements IContactTypes {
    public getFilters(): Observable<IFilters> {
    return of(new EntityFilters(1));
}
}

/* IContactTypes {
    public getItems(): Observable<Array<ComboItem>> {
        return of(brokersTableData.map(({ id: id, name: value }) => ({ id, value })));
    }
} */