import { IFilters } from "src/app/interfaces/ifilters";

export class EntityFilters implements IFilters  {

  name: string = "";
  agent:  number = null;
  receivesOffers: string = "";
  buyingPreference: number = null;
  provinceLike: string = "";
  interestBrands: number = null;
  vehicleType:  number = null;
  dealerBrands: number = null;
  contactType: string = "";
  isActive: string = "";
  rowsPerPage: number = null;
  pageNumber: number = null;   
  orderBy: string = "";
  order: string = "asc";

  constructor(contactType) {
    this.contactType = contactType;
  }


}
