import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, catchError, throwError, lastValueFrom } from 'rxjs';
import { CommonListResponse } from 'src/app/models/common-list-response';
import { User } from 'src/app/models/users/user';
import { environment } from 'src/environments/environment';
import { mergeMap, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class UserService {
    private masterSubject: BehaviorSubject<CommonListResponse<User>>;


  constructor(private http: HttpClient) {
  }

  public get currentUserValue(): CommonListResponse<User> {
    return this.masterSubject.value;
  }

  async get() {

      
    const options = {
        headers: new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': '*/*',
            Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
        })
    };
    return await lastValueFrom(this.http.get<CommonListResponse<User>>(`${environment.apiUrl}users`, options)
        .pipe(
            map(response => {
                return response.data;
            })));

}

  getAll() {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.get<CommonListResponse<User>>(`${environment.apiUrl}users`, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  updateUser(user: User) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<User>>(`${environment.apiUrl}userUpdate`, { user }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  createUser(user: User) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return this.http.post<CommonListResponse<User>>(`${environment.apiUrl}userRegister`, { user }, options)
      .pipe(
        map(response => {
          return response;
        }));
  }

  async addImage(user: User) {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': '*/*',
        Authorization: 'Bearer ' + sessionStorage.getItem('accessToken')
      })
    };
    return await lastValueFrom(this.http.post<CommonListResponse<User>>(`${environment.apiUrl}userImage`, { user }, options)
      .pipe(
        //catchError(this.handleError), 
        map(response => {
          return response;
        })));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

}