import { IDocumentTypes } from "./document-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { CostFilters } from "src/app/models/costs/cost-filters";

@Injectable()
export class CostFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters> {
        return of (new CostFilters());
    }
}