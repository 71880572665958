import { Component, Inject, Injector, isDevMode, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable, ReplaySubject } from 'rxjs';
import { CommonFile } from 'src/app/models/file';
import { User } from 'src/app/models/users/user';
import { UserService } from 'src/app/services/api/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StorageService } from 'src/app/services/utils/storage.service';




@Component({
  selector: 'vex-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})

export class ImageDialogComponent implements OnInit {
  appUser;

constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    public dialog: MatDialogRef<ImageDialogComponent>,
    private fb: FormBuilder,
    private alert: ToastService,
    private userService: UserService,
    private storageService: StorageService) { this.appUser = JSON.parse(localStorage.getItem("currentUser")); }

    //@Input() user: User; 

    file: CommonFile | null;
    form: FormGroup;
    

    closeDialog(): void {
      this.dialog.close(null);
    }
    async addFile(): Promise<void> {
      if(this.file && this.defaults?.id)
        await this.addImage(this.defaults);
    }

    async addImage(user: User) {
      
      user.file = this.file;
      
      const response = await this.userService.addImage(user);

          if (!response) {
            this.alert.error("No ha sido posible insertar la imagen de usuario");
          }
          else if(!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else{
            if(this.appUser.id === user.id){
              user.imageSrc = response.data[0]?.imageSrc;
              this.storageService.setItem('currentUser', JSON.stringify(user));
            }
              
            this.dialog.close(response.data[0]?.imageSrc);
          }
            
       
      
    }

    handleFileInput(event: Event ) {
      let file: File = event.target['files'][0];
      this.convertFile(file).subscribe(base64 => {

        this.file = new CommonFile(file.name,file.type,base64);
        });
     
}

convertFile(file : File) : Observable<string> {
  const result = new ReplaySubject<string>(1);
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onload = (event) => result.next(btoa(event.target.result.toString()));
  return result;
}

    ngOnInit() {
      this.form = this.fb.group({
        file: ['', Validators.required]
    });
    }
}