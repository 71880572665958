import { ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, Input, isDevMode, OnInit, Output, ViewEncapsulation, AfterContentChecked } from '@angular/core';
import { FormBuilder, FormGroup, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable, startWith, map } from 'rxjs';
import { ComboItem } from 'src/app/models/combo-item';
import { Basic } from 'src/app/models/equipments/basic';
import { Serie } from 'src/app/models/equipments/serie';
import { VehicleVersion } from 'src/app/models/equipments/vehicle-version';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { VehicleFilters } from 'src/app/models/vehicles/vehicle-filters';
import { CommonService } from 'src/app/services/api/common.service';
import { MasterService } from 'src/app/services/api/masters.service';
import { ToastService } from 'src/app/services/toast/toast.service';


@Component({
  selector: 'vex-serie-create-update',
  templateUrl: './serie-create-update.component.html',
  styleUrls: ['./serie-create-update.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable({providedIn: 'root'})
export class SerieCreateUpdateComponent implements OnInit, AfterContentChecked  {

  layoutCtrl = new UntypedFormControl('fullwidth');
  loadPacks = true;
  _tableData:Array<Basic>;
  @Input()
  set tableData(val: Array<Basic>) {
    this.dataChange.emit(val);
    this._tableData = val;
  }
  get tableData() {
    return this._tableData;
  }
  @Output() dataChange: EventEmitter<Array<Basic>> = new EventEmitter<Array<Basic>>();

  serieType: string;
  point: string = ".";

  documentsTableData: Array<Document>= [];
  vehicleVersions: Array<ComboItem>;
  countries: Array<ComboItem>;
  categories: Array<ComboItem>;

  @Output() changedModelEvent = new EventEmitter<Array<ComboItem>>();

  _packs: Array<ComboItem>;

  @Input()
  set packs(val: Array<ComboItem>) {
    this.changedModelEvent.emit(val);
    this._packs = val;
  }
  get packs() {
    //this.tableData = this.params?.list?.tableData;
    return this._packs;
  }
  form: FormGroup;
  mode: 'create' | 'update' = 'create';


  standardEquipmentHasBasics: Array<Basic>;
  vehicleFilters: VehicleFilters;

  filteredModels: Observable<Array<KeyValue>>;

  model: string = "";


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
  private router: Router,
    private dialogRef: MatDialogRef<SerieCreateUpdateComponent>,
    private fb: FormBuilder,
    private alert: ToastService,
    private masterService: MasterService<KeyValue>,
    private cd: ChangeDetectorRef,
    private commonService: CommonService<Serie, CommonFilter>) { }


  ngOnInit() {
    (async () => {
    
    if (this.defaults.values && this.defaults?.values?.id != null){
      this.mode = 'update';
      this.defaults.values.vehicleVersion.name = this.defaults.values.nameVehiculo;
    }
    else if (this.defaults.values && this.defaults?.values?.id == null) {
      this.defaults.values.vehicleVersion.name = this.defaults.values.nameVehiculo;
    } else {
      this.defaults.values = {} as Serie;
    }
    
    this.getItems();
    
    this.standardEquipmentHasBasics = this.defaults.values.standardEquipmentHasBasics;
    this.serieType = this.defaults.serieType;
    this.categories = this.defaults.categories;
    //this.packs = this.defaults.packs;//.filter(x=> x.vehicleBrand?.id == this.defaults.values.vehicleBrand?.id);

    this.model = this.defaults?.values?.vehicleVersion?.name || "";

    this.form = this.fb.group({
      name:  [{ value:this.defaults.values.name || '',disabled: true }],
      vehicleVersion: [this.defaults.values.vehicleVersion || '', Validators.required], 
      createdAt: [this.defaults.values.createdAt ? new Date(this.defaults.values.createdAt) : new Date(), Validators.required], 
      seriesYear: new FormControl(this.defaults.values.seriesYear || '', Validators.compose([
        Validators.required,
        Validators.maxLength(4)
      ])),
      seriesName: [this.defaults.values.seriesName || '', Validators.required],
      country: [this.defaults.values.country || '', Validators.required]
  });
})();
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }
 /*  changedModel(value: Array<ComboItem>) {
    this.changedModelEvent.emit(value);
  } */
  async getItems(){
    const keyValue = new EmptyKeyValue;
    this.vehicleFilters = new VehicleFilters(keyValue, keyValue, "");
    this.vehicleVersions = (await this.masterService.getAllFilteredAsync("vehicleVersions", this.vehicleFilters) as unknown as Array<MasterCommon>)?.filter(x=> x.name?.trim());
    this.countries = await this.masterService.get("countries") as unknown as Array<MasterCommon>;
    this.loadPacks = false;
    if(this.defaults.values?.vehicleVersion)
      this.packs = await this.masterService.getAllItemsFilter("equipmentPacks", new VehicleVersion(this.defaults.values?.vehicleVersion)) as unknown as Array<MasterCommon>;
    this.loadPacks = true;
  }

  get country(){
    if(this.form?.value?.country?.name)
      return this.form?.value?.country.name;

    return '';
  }
  getTableData(tableData) {
    this.standardEquipmentHasBasics = tableData;
  }
  async changeVersion(version){
    this.loadPacks = false;
    if(version?.id)
      this.packs = await this.masterService.getAllItemsFilter("equipmentPacks", new VehicleVersion(version)) as unknown as Array<MasterCommon>;
    this.loadPacks = true;
    //this.changedModel(this.packs);
  }

  duplicateSerie(){
    this.mode = 'create';
    this.defaults.values.id = null;
    this.form.value.id = null;
    //this.save()
  }

  save() {
    this.setModel();
    if (this.mode === 'create') {
      this.createSerie();
    } else if (this.mode === 'update') {
      this.updateSerie();
    } else if (this.mode === 'menu') {
      this.createSerie();
    }
  }

  createSerie() {
    const serie = this.form.value;
    serie.name = serie?.seriesName + "." + serie?.country?.name + "." + serie?.seriesYear ;
    serie.isActive = true;
    serie.standardEquipmentHasBasics = this.standardEquipmentHasBasics || [];
    this.addSerie(serie);
  }

  updateSerie() {
    const serie = this.form.value;
    serie.id = this.defaults.values.id;
    serie.name = serie?.seriesName + "." + serie?.country?.name + "." + serie?.seriesYear;
    serie.standardEquipmentHasBasics = this.standardEquipmentHasBasics || [];
    this.modifySerie(serie);
  }

  addSerie(serie: Serie) {
    this.commonService.create('standardEquipments', serie).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible dar de alta el equipamiento de serie");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              serie.id = response.data[0].id;
              serie.nameVehiculo = serie.vehicleVersion.name;
              this.dialogRef.close(serie);
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible dar de alta el equipamiento de serie");
          }
      });

  }

  modifySerie(serie: Serie) {
    this.commonService.update('standardEquipments', serie).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible actualizar el equipamiento de serie");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              serie.nameVehiculo = serie.vehicleVersion.name;
              this.dialogRef.close(serie);
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible actualizar el equipamiento de serie");
          }
      });

  }


  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
/* 
  isMenuMode() {
    return this.mode === 'menu';
  } */

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }


  selectedRole(event: MatSelectChange) {
    this.defaults.role = event.source.triggerValue;
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  optionSelected(input: HTMLInputElement) {
    input.blur();
    input.setSelectionRange(0, 0);
    input.focus();
  }

  clearModels(event){
    //event.target.value = '';
    //this.changeVersion(event);
    this.filteredModels = this.form.controls.vehicleVersion.valueChanges.pipe(
      startWith(''),
      map(value => this.filterModel(value || '')),
    );

  }

  private filterModel(value: any): any[] {
    if(!value?.name){
      if (value == undefined)
        value = '';
      return this.vehicleVersions.filter(option => option.name.toUpperCase().includes(value?.toUpperCase()));
    }
  }

  displayFn(option) {
    if (option && option.name) {
      return option.name.toUpperCase();
    } else {
      return option
    }
  }
  

setModel() {
  let result = false;
  if(this.vehicleVersions ){
    let input = this.form.value['vehicleVersion'];
  let matchingOption = input;
  if(this.getModel(input)){
    matchingOption = this.getModel(input)[0];
    result = true;
  }
  this.form.value['vehicleVersion'] = matchingOption;  
  }

  return result;

}

private getModel(value: any): any[] {
  if(!value?.name){
    if (value == undefined)
      value = '';
    return this.vehicleVersions.filter(option => option.name.toUpperCase() == value?.toUpperCase());
  }
}
}
