import { NgModule } from '@angular/core';
import { TransactionsComponent } from './transactions.component';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { CommonModule } from '@angular/common';
import { TableModule } from 'src/app/pages/apps/table/table.module';


@NgModule({
  declarations: [TransactionsComponent],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    TableModule
  ],
  bootstrap: [TransactionsComponent],
  exports: [TransactionsComponent]
})
export class TransactionsModule {}
