import { IFilters } from "src/app/interfaces/ifilters";

export class OfferFilters implements IFilters {
    rowsPerPage: number;
    pageNumber: number;
    orderBy: string;
    order: string;

    id: number = null;
    name: string = "";
    brandId: number= null;
    modelId: number= null;
    versionId:number= null;
    vin: string = "";   
    offerStatusId: number= null;
    providerId: number= null;
   
}