import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) {}

  info(message: string) {
    this.snackBar.open(message, 'Cerrar', {
      duration: 3000,
      panelClass: ['mat-toolbar', 'mat-primary'],
      data: {},
    });
  }

  error(message: string) {
    this.snackBar.open(message, 'CERRAR', {
      duration: 3000,
      panelClass: ['alert-red']
    });
  }
}
