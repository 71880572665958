import { offersTableData } from "src/app/mocks/ofertas";
import { ComboItem } from "src/app/models/combo-item";
import { IDocumentTypes } from "./document-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { OfferFilters } from "src/app/models/offers/offer-filters";

@Injectable()
export class OfferFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters> {
        return of(new OfferFilters());
    }
}