import { IModel } from "src/app/interfaces/model";

export class Invoice implements IModel {
    id: number;
    name: string;
    invoice: string;
    total: number;
    amount: number;
    type:string;
    expedientes?: Array<string>
    
    

    constructor(invoice) {
       // this.id = invoice.id;
        this.name = invoice.name;
        this.invoice = invoice.invoice;
        this.total = invoice.total;
        this.amount = invoice.amount;
        this.type = invoice.type;
        this.expedientes = invoice.expedientes;
    }
}