import { operationsTableData } from "src/app/mocks/operaciones";
import { ComboItem } from "src/app/models/combo-item";
import { IDocumentTypes } from "./document-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { EntityFilters } from "src/app/models/contacts/entity-filters";
import { InvoiceFilters } from "src/app/models/invoices/invoice-filters";

@Injectable()
export class OperationFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters> {
        return of (new InvoiceFilters());
    }
}