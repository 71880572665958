import { NgModule } from '@angular/core';
import { InvoicesComponent } from './invoices.component';
import { InvoicesRoutingModule } from './invoices-routing.module';
import { CommonModule } from '@angular/common';
import { TableModule } from 'src/app/pages/apps/table/table.module';


@NgModule({
  declarations: [InvoicesComponent],
  imports: [
    CommonModule,
    InvoicesRoutingModule,
    TableModule
  ],
  bootstrap: [InvoicesComponent],
  exports: [InvoicesComponent]
})
export class InvoicesModule {}
