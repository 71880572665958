import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageService } from './services/utils/storage.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DndDirective } from './pages/pages/documentation/documents/document-create-update/dnd.directive';
import { CostCreateUpdateModule } from './pages/pages/administration/costs/cost-create-update/cost-create-update.module';


@NgModule({
  declarations: [AppComponent, DndDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatPaginatorModule,
    VexModule,
    CustomLayoutModule,
    ScrollingModule,
    CostCreateUpdateModule
  ],
  providers: 
  [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  , StorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
