export class ComboItem {

    id?: number;
    value?: string;
    imageSrc?: string;
    name?: string;
    
    
  
    constructor(comboItem) {
  
      this.id = comboItem.id;
      this.value = comboItem.value;
      this.imageSrc = comboItem.imageSrc;
      this.name = comboItem.name;
    }
  
  }
  