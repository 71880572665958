import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'vex-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {

constructor(
    public dialog: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }
    selectedItem?: string = '1';
    percentage: string = '';

    closeDialog(): void {
      this.dialog.close(
        {
          selectedItem: 0,
          percentage: ''
        }
      );
    }
    confirm(): void {
      this.dialog.close( {
        selectedItem: this.selectedItem,
        percentage: this.percentage
      });
    }

  ngOnInit() : void {
  }

}