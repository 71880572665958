<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div *ngIf="this.defaults.hasImage">
      <img *ngIf="form.get('imageSrc')?.value" [src]="form.get('imageSrc').value" class="avatar mr-5">
      <img *ngIf="!form.get('imageSrc')?.value" src='assets/img/avatars/avatar.png' class="avatar mr-5">
    </div>
    <h2 *ngIf="form.get('id')?.value ; else newItem" class="headline m-0 flex-auto">{{
      form.get(this.defaults.titleProperty)?.value?.name ||  form.get(this.defaults.titleProperty).value}}</h2>
    <ng-template #newItem>
      <h2 class="headline m-0 flex-auto">{{this.defaults.element}}</h2>
    </ng-template>

    <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button> --> 

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>


 <!--  <mat-divider class="-mx-6 text-border"></mat-divider> -->

  <mat-dialog-content  class="flex flex-col" *ngFor="let control of controls; trackBy: trackByProperty">
    <!-- <div *ngIf="control.type === 'text'" class="flex flex-col sm:flex-row" > -->
    <mat-form-field *ngIf="control.type === 'text' && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <input cdkFocusInitial formControlName="{{ control.property }}" matInput
        required="{{ control.hasOwnProperty('validations')  }}">
      <mat-error *ngIf="form.get(control.property)?.hasError('required')"><strong>{{control.label}} obligatorio</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="control.type === 'number' && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <input type="number" cdkFocusInitial formControlName="{{ control.property }}" matInput min="{{control.minValue}}"
        required="{{ control.hasOwnProperty('validations')  }}">
      <mat-error *ngIf="form.get(control.property)?.hasError('required')"><strong>{{control.label}} obligatorio</strong>
      </mat-error>
      <mat-error *ngIf="control.minValue"><strong>Mayor que 0</strong></mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="control.type === 'textarea' && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <textarea formControlName="{{ control.property }}" matInput
        required="{{ control.hasOwnProperty('validations')  }}"></textarea>
      <mat-error *ngIf="form.get(control.property)?.hasError('required')"><strong>{{control.label}} obligatorio</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="control.type === 'date' && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <input cdkFocusInitial [matDatepicker]="basicDatepicker" formControlName="{{ control.property}}" matInput
      required="{{ control.hasOwnProperty('validations')  }}">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="basicDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #basicDatepicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field *ngIf="control.type === 'hour' && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <input formControlName="{{ control.property}}" matInput
      required="{{ control.hasOwnProperty('validations')  }}">
      <mat-hint>hh:mm</mat-hint>
    </mat-form-field>
    <!--  </div> -->

    <mat-form-field *ngIf="control.type === 'chip' && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <mat-select #sl multiple formControlName="{{ control.property }}" placeholder="Seleccionar {{ control.label }}">
        <mat-option *ngFor="let item of control.items" [value]="item.value">
          <img height="20px" width="20px" style="float: left;text-align: center;margin-top: 10px;margin-right: 10px;"
            [src]="item.imageSrc">
          {{item.value}}
        </mat-option>
        <button style="float:right;" mat-button (click)="sl.close()">Aceptar</button>

      </mat-select>

      <mat-error *ngIf="form.get(control.property)?.hasError('required')"><strong>{{control.label}} obligatorio</strong>
      </mat-error>
      <mat-icon class="mr-3" matPrefix svgIcon="mat:public"></mat-icon>
    </mat-form-field>

    
    <div *ngIf="control.type === 'accordion' && control.visible" class="mb-5"  formArrayName="{{control.property}}">
      <mat-accordion class="mb-5" >
        <mat-expansion-panel  (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-center block">
              {{ control.label }}
            </mat-panel-title>
<!--             <mat-panel-description>
              Currently I am {{panelOpenState ? 'open' : 'closed'}}
            </mat-panel-description> -->
          </mat-expansion-panel-header>
         <div *ngFor="let item of items(control.property).controls; let itemIndex=index">
          <div [formGroupName]="itemIndex">
            <mat-form-field class="flex-auto block"  >
              <mat-label>{{ name(itemIndex,control.property).value}}</mat-label>
              <input *ngIf="name(itemIndex,control.property).value != 'ES'" formControlName="value" matInput>
              <input *ngIf="name(itemIndex,control.property).value === 'ES'" value="{{form.get(this.defaults.titleProperty).value}}" matInput disabled="disabled">

            </mat-form-field>
          </div>
         </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div *ngIf="control.type === 'languages'" class="mb-5" formGroupName="{{control.property}}">
      <mat-accordion class="mb-5" >
        <mat-expansion-panel  (opened)="panelOpenState = true"
                             (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-center block">
              {{ control.label }}
            </mat-panel-title>
          </mat-expansion-panel-header>
         
            <mat-form-field class="flex-auto block"  >
              <mat-label>ES</mat-label>
              <!-- <input  matInput> -->
              <input value="{{form.get('name')?.value}}" matInput disabled="disabled">
            </mat-form-field>
            <mat-form-field class="flex-auto block"  >
              <mat-label>DE</mat-label>
              <input formControlName="de" matInput>
            </mat-form-field>
            <mat-form-field class="flex-auto block"  >
              <mat-label>EN</mat-label>
              <input formControlName="en" matInput>
            </mat-form-field>
            <mat-form-field class="flex-auto block"  >
              <mat-label>FR</mat-label>
              <input formControlName="fr" matInput>
            </mat-form-field>
            <mat-form-field class="flex-auto block">
              <mat-label>IT</mat-label>
              <input formControlName="it" matInput>
            </mat-form-field>
         
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <mat-form-field *ngIf="(control.type === 'select' || control.type === 'editable')  && control.visible" class="flex-auto">
      <mat-label>{{ control.label }} </mat-label>
      <mat-select cdkFocusInitial #sl formControlName="{{ control.property }}" placeholder="Seleccionar {{ control.label }}" [compareWith]="compareCategoryObjects">
        <mat-option *ngFor="let item of control.items" [value]="item">
          {{item.name}}
        </mat-option>
      </mat-select>

      <mat-error *ngIf="form.get(control.property)?.hasError('required')"><strong>{{control.label}} obligatorio</strong>
      </mat-error>
    </mat-form-field>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field *ngIf="control.type === 'flag'  && control.visible" class="flex-auto">
        <mat-label>{{ control.label }} </mat-label>
        <mat-checkbox style="margin-left: 40%;float: right;" matInput formControlName="{{ control.property }}">
        </mat-checkbox>
        <input hidden=true matInput>
      </mat-form-field>
    </div>




  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancelar</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Nuevo
      {{this.defaults.element}}</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Actualizar
      {{this.defaults.element}}</button>
  </mat-dialog-actions>
</form>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exportar</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Eliminar</span>
  </button>
</mat-menu> -->