
export class Languages {
    id:number;
    de: string;
    en: string;
    es: string;
    fr: string;
    it: string;
  
    constructor(languages) {
      this.id= languages.id;
      this.de = languages.de;
      this.en = languages.en;
      this.es = languages.es;
      this.fr = languages.fr;
      this.it = languages.it;
    }

}

export class EmptyLanguages{
  id= null;
  de: string = "";
  en: string= "";
  es: string= "";
  fr: string= "";
  it: string= "";
}