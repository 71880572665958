export const MastersConst =[
    //"administrativeFileType",
    "availabilityTypes",
    "brandPreferenceTypes",
    "buyingPreferenceTypes",
    "channels",
    "equipmentColors",
    "costTypes",
    "countries",
    "documentCategoryTypes",
    "entityTypes",
    "equipmentClass",
    "fuelTypes",
    "invoiceTaxes",
    "invoiceTitles",
    "locationTypes",
    "notificationOptions",
    "notificationTypes",
    "offerStatus",
    "offices",
    "permissionTypes",
    "proformaTitles",
    "invoiceSets",
    "roles",
    "shipmentCostTypes",
    "shippingStatus",
    "comercialsAll",
    "transmissionTypes",
    "valueTypes",
    "vatTypes",
    "vehicleBrands",
    "vehicleCostTypes",
    "vehiclePreferenceTypes",
    "accountingEntryTypes",
    /* "banks",
    "transactionTypes", */
    "documentTypes",
    "documentCategory",
    //"getAllComerciales"
]