
<form [formGroup]="form" >
<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle mat-dialog-title>Seleccionar imagen de usuario</h2>
<mat-dialog-content class="flex flex-col" >
    <div class="flex flex-col sm:flex-row">
    <mat-form-field fxFlex class="flex-auto" required>
      <mat-label>Imagen</mat-label>
      <ngx-mat-file-input  formControlName="file" type="file" (change)="handleFileInput($event)">
      </ngx-mat-file-input>
      <mat-icon svgIcon="mat:folder" matSuffix></mat-icon>
      <mat-error *ngIf="form.get('file').hasError('required')"><strong>Archivo obligatorio</strong>
      </mat-error>
    </mat-form-field>
    </div>
  </mat-dialog-content>
<div mat-dialog-actions align="end" >
    <button mat-button (click)="closeDialog()">Cancelar</button>
    <button color="primary" mat-flat-button  (click)="addFile()" >Aceptar</button>
</div>
</form>