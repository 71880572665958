import { IFilters } from "src/app/interfaces/ifilters";
import { KeyValue } from "../key-value";

export class InvoiceFilters implements IFilters {
    rowsPerPage: number;
    pageNumber: number;
    orderBy: string;
    order: string;

    administrativeFileId: number = null;
    name: string = "";
    officeId : number= null;
    orderTypeId : number= null;
    accountingEntryId :number= null;
    vehicleCostTypeId  :number= null;
    bankingTransactionId:number= null;
    concept?:KeyValue = null;
    administrativeFileReference?:KeyValue = null;
    costTypeId?:number = null; 
    createdAt?: Date = null;
    proformaInvoiceDate?: Date = null;
    accountingEntryTypeId?:number = null;
    entityId : number= null;
    proformaInvoiceId?:number = null;
    remarksLike?:string = null;
    proformas?: Array<KeyValue> = [];
    orderType? : KeyValue= null;
    id?: number;
    isCostOrigin: boolean = true;
    shipmentCostType?:KeyValue = null;

}