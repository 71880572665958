import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { ComboItem } from 'src/app/models/combo-item';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { Invoice } from '../../../../../models/pays-charges/invoice';

import { Concept } from '../../../../../models/costs/concept';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { Validators } from '@angular/forms';


@UntilDestroy()
@Component({
  selector: 'vex-concepts',
  templateUrl: './concepts.component.html',
  styleUrls: ['./concepts.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ConceptsComponent {

  @Input()tableData: Array<Concept>;
  @Input() invoices:Array<Invoice>;
  @Input() amount:string;
  @Input() filters: CommonFilter;
  @Input() readOnly: boolean= false;
  @Input()
  set settledAmount(val: number) {
    this._settledAmount = val;
    this.updatedSettledAmount.emit(val);
  }
  get settledAmount() {
    //this.tableData = this.params?.list?.tableData;
    return this._settledAmount;
  }

  _settledAmount: number = 0;
  list: ITableParams<Concept,Concept>;

  items: Array<ComboItem> = [
    {
      id: 1,
      value: "España",
      imageSrc: "assets/img/flags/España.jpg",
    },
    {
      id: 2,
      value: "Bulgaria",
      imageSrc: "assets/img/flags/Bulgaria.jpg",
    }];

    @Output() params: ITableParams<Concept,Concept> = null;
    @Output() updatedSettledAmount: EventEmitter<number> = new EventEmitter<number>();

    getIsUpdatedValue(value) {
      this.settledAmount = value;
      
    }

  ngOnInit() {
    // this.list.tableData = invoicesTableData.map(invoice => new Invoice(invoice));
    let columns: Array<TableColumn<Concept>> = [
      { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: !this.readOnly },
      { label: 'Fecha', property: 'createdAt', type: 'date', visible: true, defaultValue: new Date() as unknown as object, validations : Validators.compose([
        Validators.required
      ])
    },
      { label: 'Concepto', property: 'name', type: 'text', visible: true, validations : Validators.compose([
        Validators.required
      ]) },
      { label: 'Importe', property: 'amount', type: 'number', minValue:1, visible: true, validations : Validators.compose([
        Validators.required
      ]) }
    ];
    this.params = {

      isMenu: false,
      element: "Concepto",
      title: 'Conceptos',
      titleProperty: "name",
      service: "accountingentries/costs/concepts",
      filters: this.filters,
      disabledMode: this.readOnly,
      hasImage: false,
      columns: columns,
      /* list: {
        isMenu: false,
        element: "Concepto",
        title: 'Conceptos',
        titleProperty: "description",
        extraValue: this.amount as unknown as string,
        hasImage: false,
        columns: [
          { label: 'Casilla de selección', property: 'checkbox', type: 'checkbox', visible: true },
          { label: 'Fecha', property: 'date', type: 'date', visible: true },
          { label: 'Fecha movimiento', property: 'date', type: 'text', visible: true },
          { label: 'Concepto', property: 'description', type: 'text', visible: true },
          { label: 'Importe', property: 'amount', type: 'text', visible: true }
    
        ],
        tableData: conceptsTableData.map(tran => new Concept(tran)) || [],

      } */
    }
  }

}