import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatAutocompleteOptionsScrollDirective } from './options-scroll.directive';
    @NgModule({
      declarations: [
        MatAutocompleteOptionsScrollDirective
      ],
      exports: [
        MatAutocompleteOptionsScrollDirective
      ],
      imports: [
        CommonModule,
        MatAutocompleteModule,
    
      ]
    })
    export class MatAutocompleteOptionsScrollModule { }