import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IFilters } from "src/app/interfaces/ifilters";
import { vehiclesTableData } from "src/app/mocks/vehiculos";
import { ComboItem } from "src/app/models/combo-item";
import { EmptyKeyValue } from "src/app/models/key-value";
import { CommonFilter } from "src/app/models/masters/common-filter";
import { VehicleFilters } from "src/app/models/vehicles/vehicle-filters";
import { IDocumentTypes } from "./document-types";

@Injectable()
export class VehicleFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters> {
        return of(new VehicleFilters(new EmptyKeyValue(),new EmptyKeyValue(),new EmptyKeyValue()));
    }
}