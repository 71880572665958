export const expedientsTableData = [{
	"createdAt": "2022-01-07T11:36:48.917Z",
	"updatedAt": "2022-01-07T11:36:48.917Z",
	"createdBy": null,
	"id": 208,
	"internalId": "EXVC2200002",
	"isClosed": false,
	"type": "buy",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-07T11:58:52.064Z",
	"updatedAt": "2022-01-07T16:09:44.000Z",
	"createdBy": null,
	"id": 209,
	"internalId": "EXVV2200002",
	"isClosed": false,
	"type": "sale",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-07T15:12:03.935Z",
	"updatedAt": "2022-01-07T15:12:03.935Z",
	"createdBy": null,
	"id": 210,
	"internalId": "EXVC2200003",
	"isClosed": false,
	"type": "buy",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-07T15:44:18.905Z",
	"updatedAt": "2022-01-07T15:44:18.905Z",
	"createdBy": null,
	"id": 212,
	"internalId": "EXVC2200004",
	"isClosed": false,
	"type": "buy",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-07T15:48:18.211Z",
	"updatedAt": "2022-01-07T15:48:18.211Z",
	"createdBy": null,
	"id": 213,
	"internalId": "EXVV2200003",
	"isClosed": false,
	"type": "sale",
	"transportType": "inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-10T09:45:51.783Z",
	"updatedAt": "2022-01-10T09:45:51.783Z",
	"createdBy": null,
	"id": 214,
	"internalId": "EXVC2200005",
	"isClosed": false,
	"type": "buy",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-10T09:46:46.703Z",
	"updatedAt": "2022-01-10T09:46:46.703Z",
	"createdBy": null,
	"id": 215,
	"internalId": "EXVC2200006",
	"isClosed": false,
	"type": "buy",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-10T10:04:29.139Z",
	"updatedAt": "2022-01-10T10:04:29.139Z",
	"createdBy": null,
	"id": 216,
	"internalId": "EXVV2200004",
	"isClosed": false,
	"type": "sale",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-10T10:56:44.750Z",
	"updatedAt": "2022-01-10T10:56:44.750Z",
	"createdBy": null,
	"id": 217,
	"internalId": "EXVV2200005",
	"isClosed": false,
	"type": "sale",
	"transportType": "not_inclusive",
	"tenantId": 1
}, {
	"createdAt": "2022-01-10T11:03:14.622Z",
	"updatedAt": "2022-01-10T11:03:14.622Z",
	"createdBy": null,
	"id": 218,
	"internalId": "EXVC2200007",
	"isClosed": false,
	"type": "buy",
	"transportType": "not_inclusive",
	"tenantId": 1
}]