import { Component, HostListener, Inject, Injectable, isDevMode, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { ComboItem } from 'src/app/models/combo-item';
import { Invoice } from '../../../../../models/pays-charges/invoice';
import { Vehicle } from '../../../../../models/vehicles/vehicle';
import { Note } from 'src/app/models/equipments/note';
import { PayCharge } from 'src/app/models/pays-charges/pay-charge';
import { PaysChargesTableData } from 'src/app/mocks/pagos-cobros';
import { Transaction } from 'src/app/models/pays-charges/transaction';
import { ITableParams } from 'src/app/pages/apps/table/models/table-params';
import { expedientsTableData } from 'src/app/mocks/expedientes';
import { Observable, take } from 'rxjs';
import { InvoiceFilters } from 'src/app/models/invoices/invoice-filters';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { VehicleSaleFilters } from 'src/app/models/vehicles/vehicle-sale-filters';
import { IModel } from 'src/app/interfaces/model';
import { ExpedientFilters } from 'src/app/models/expedients/expedient-filters';
import { CommonService } from 'src/app/services/api/common.service';
import { MasterService } from 'src/app/services/api/masters.service';
import { UserService } from 'src/app/services/api/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { StorageService } from 'src/app/services/utils/storage.service';

import { CanDeactivate, Router } from '@angular/router';
import { CanComponentDeactivate } from 'src/app/services/utils/form-guard.service';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { Entity } from 'src/app/models/contacts/entity';
import { Purchase } from 'src/app/models/expedients/purchase';
import { TransferData } from 'src/app/models/contacts/transfer-data';
import { PurchaseCreateUpdateComponent } from '../../purchases/purchase-create-update/purchase-create-update.component';
import { LinkedAdminFiles } from 'src/app/models/expedients/linked-admin-files';
import { PayChargeFilters } from 'src/app/models/pays-charges/pay-charge-filters';
import { InvoicesFilters } from 'src/app/models/invoices/invoices-filters';
import { ConfirmDialogComponent } from 'src/app/pages/apps/common-dialogs/confirm-dialog/confirm-dialog.component';
import { IFilters } from 'src/app/interfaces/ifilters';


@Component({
  selector: 'vex-sale-create-update',
  templateUrl: './sale-create-update.component.html',
  styleUrls: ['./sale-create-update.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable({ providedIn: 'root' })
export class SaleCreateUpdateComponent implements OnInit, CanDeactivate<CanComponentDeactivate> {
  hasUnsavedData() {
    return this.form.dirty;
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = true;
    }
  }

  readOnly: boolean = false;

  layoutCtrl = new UntypedFormControl('fullwidth');
  linkService: string = "accountingentries/costs/administrativefiles"; 

  service: string = 'administrative-files';
  filters: CommonFilter = new CommonFilter(null);
  invoiceFilters: InvoiceFilters = new InvoiceFilters();
  invoicesFilters: InvoicesFilters = new InvoicesFilters();
  payChargeFilters: PayChargeFilters = new PayChargeFilters();
  vehicleSaleFilters: VehicleSaleFilters = new VehicleSaleFilters();
  saleType: string;
  purchaseType: string = 'Oferta de compra';
  purchaseFilters: ExpedientFilters = new ExpedientFilters();
  isUpdating = false;
  params: ITableParams<ComboItem, ComboItem>;
  linkedAdminFiles: Array<LinkedAdminFiles>= [];

  documentsTableData: Array<Document> = [];
  notesTableData: Array<Note> = [];
  invoicesTableData: Array<Invoice> = [];
  vehiclesTableData: Array<Vehicle> = [];
  transactionsTableData: Array<Transaction> = [];
  buyExpedients: string[];
  price: number;
  officeId: number;
  documentType: string = "Expediente";
  reference: KeyValue = new EmptyKeyValue();
  payChargeTitle = "Cobro";
  invoiceTitle = "Proforma";
  type: KeyValue;
  proformaType : KeyValue;
  salesTableData: Array<PayCharge> = PaysChargesTableData.filter(column => column.type.id === 2).map(payCharge => new PayCharge(payCharge));

  transportsTypes: Array<ComboItem> = [
    {
      id: 1,
      value: "Incluido"
    },
    {
      id: 2,
      value: "Incluido en el precio"
    },
    {
      id: 3,
      value: "No incluido"
    }];

  form: FormGroup;
  mode: 'create' | 'update' | 'menu' = 'create';

  shipmentTypes: Array<KeyValue>;
  administrativeFileTypes: Array<KeyValue>;
  clients: Array<KeyValue>;

  vehicleTableData: Array<Vehicle>;
  paysChargesTableData: Array<PayCharge> = PaysChargesTableData.filter(column => column.type.id === 1).map(payCharge => new PayCharge(payCharge));

  amountSett: number = 0;

  disabled: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private router: Router,
    private dialogRef: MatDialogRef<SaleCreateUpdateComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private masterService: MasterService<IModel>,
    private masterServiceNoCache: CommonService<IModel, IFilters>,
    private userService: UserService,
    private alert: ToastService,
    private commonService: CommonService<Purchase, ExpedientFilters>,
    private storageService: StorageService) {
  }

  getIsUpdatedValue(value) {
    //this.amountSett = value;
    
    if(Number(value) == Number(this.defaults?.values?.amount)){
      this.defaults.values.amountSett = 0;
      this.amountSett = Number(this.defaults?.values?.amount);
    }
    else{
      this.defaults.values.amountSett =  Number(this.defaults.values.amount) - Number(value) ;
      this.amountSett = Number(value);
    }
    
      
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (!this.isUpdating && this.form.dirty) {
      this.isUpdating = false;
      this.dialog
        .open(ConfirmDialogComponent, {
          data: `¿Quiere salir y perder los cambios?`
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            return false;
          }
        });
      //return this.dialogService.confirm('¿Quiere salir y perder los cambios?');
    }
    return true;
  }

  ngOnInit() {
    if (!this.defaults.type) {
      this.defaults.values = {} as Vehicle;
      this.mode = 'menu';
    }
    else if (this.defaults?.values) {
      this.mode = 'update';
    } else {
      this.defaults.values = {} as Vehicle;
    }

    this.amountSett = (Number(this.defaults?.values?.salePrice) < 0 ? Number(this.defaults?.values?.salePrice || 0) * -1 : Number(this.defaults?.values?.salePrice || 0)) - Number(this.defaults?.values?.amountPay || 0);

    const appUser = JSON.parse(localStorage.getItem("currentUser"));
    this.readOnly =appUser.roles.filter(y => y.id == 7 || y.id == 2 && (y.id != 1 || y.id != 4)).length > 0;
      
    this.proformaType = new EmptyKeyValue();
    this.proformaType.id = 2;
    this.proformaType.name = 'Compra';
    this.type = new EmptyKeyValue();
    this.type.id = 1;
    this.type.name = 'Venta';


    this.storageService.getItem("origin").subscribe(origin => {
      this.officeId = origin;

    })
    this.price = this.defaults.values.priceBuy;
    if(this.defaults?.filters)
      this.filters = this.defaults?.filters;
    this.filters.id = this.defaults.values.id;
    this.invoiceFilters.administrativeFileId = this.defaults.values.id;
    this.invoicesFilters.administrativeFileId = this.defaults.values.id;
    this.invoiceFilters.accountingEntryId = null;
    this.invoiceFilters.shipmentCostType = this.defaults.values.shipmentCostType;
    

    this.payChargeFilters.administrativeFileId = this.defaults.values.id;

    this.vehicleSaleFilters.entityId = this.defaults.values.entity?.id;
    this.vehicleSaleFilters.hasShipment = false;
    this.vehicleSaleFilters.orderTypeId = 1;

    this.buyExpedients = this.defaults.values.buyExpedients;
    this.vehiclesTableData = this.defaults.values.vehicles || [];
    this.linkedAdminFiles = this.defaults?.values?.linkedAdminFiles;


    this.form = this.fb.group({
      id: [{ value: this.defaults.values.id, disabled: true }],
      name: [{ value: this.defaults.values.name, disabled: true }],
      shipmentCostType: [this.defaults.values.shipmentCostType, Validators.required] ,
      totalBuyPrice: this.defaults.values.totalBuyPrice,
      administrativeFileType: [this.defaults.values.administrativeFileType, Validators.required],
      entity: [this.defaults.values.entity, Validators.required],
      remarks: this.defaults.values.remarks || '',
      externalShipment: this.defaults.values.externalShipment || false,
    });

    this.params = {
      isMenu: false,
      title: 'Expedientes',
      titleProperty: "administrativeFileReference",
      hasImage: false,
      columns: [
        { label: 'Expedeinte de compra', property: 'administrativeFileReference', type: 'text', visible: true },
      ],
      tableData: expedientsTableData.filter(e => e.type === "buy", take(3)).map(({ id: id, internalId: value }) => ({ id, value })),

    }

    this.getItems();

    const keyValue = new EmptyKeyValue;
    keyValue.id = this.defaults.values.id || null;
    keyValue.name = this.defaults.values.name || "";
    this.reference = keyValue;

  }

  loadFilters(id) {
    this.purchaseFilters.id = id;
    this.purchaseFilters.pageNumber = 1;
    this.purchaseFilters.rowsPerPage = 1;
    this.purchaseFilters.order = 'asc';
    this.purchaseFilters.orderBy = '';
    this.storageService.getItem("origin").subscribe(origin => {
    if(this.purchaseFilters.officeId != origin){
      this.purchaseFilters.officeId = origin;
    }
  })
  this.filters.orderTypeId = 2;

  }
  loadAdminFile(id) {
    this.loadFilters(id);
    this.commonService.getAll(this.service, this.purchaseFilters).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("Expediente no encontrado");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            const purchases = response.data.map(x=> new Purchase(x));
            purchases[0].linkedAdminFiles = null;
            this.dialog.open(PurchaseCreateUpdateComponent, {
              data: new TransferData(
              {
                values: purchases[0],
                type: this.purchaseType,
                filters: this.purchaseFilters
              })
            });
          }
        },
      error:
        (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No ha sido posible recuperar el expediente");
        }
    });
  }
  async getItems() {

    this.shipmentTypes = (await this.masterService.get("administrative-files/shipment-types")).map(x => new KeyValue(x));
    this.administrativeFileTypes = (await this.masterService.get("administrative-files/admin-file-types")).map(x => new KeyValue(x));
    const entities = await this.masterServiceNoCache.getAllItems("entities") as unknown as Array<Entity>;
    this.clients = entities.filter(x => x.entityType?.id == 4).map(x => new KeyValue(x));

  }

  save() {
    if (this.mode === 'create') {
      this.createPurchase();
    } else if (this.mode === 'update') {
      this.updatePurchase();
    } else if (this.mode === 'menu') {
      this.createPurchase();
    }
  }

  createPurchase() {
    const purchase = this.form.value;
    purchase.isSale = true;
    purchase.active = true;
    purchase.office = new EmptyKeyValue();
    purchase.office.id = this.officeId;
    this.addPurchase(purchase);
  }
  addPurchase(purchase: Purchase) {
    this.disabled = true;
    this.commonService.create(this.service, purchase).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible dar de alta el expediente");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
              //this.ngOnInit();
            }
            else {
              purchase.id = response.data[0].id;
              this.defaults.values.id = purchase.id;
              this.form.value.name = response.data[0].name;
              this.form.controls['name'].patchValue(response.data[0].name);
              //this.filters = new CommonFilter(purchase.id);
              this.filters.id = purchase.id;
              this.invoiceFilters.administrativeFileId = this.defaults.values.id;
              this.invoicesFilters.administrativeFileId = this.defaults.values.id;
              this.payChargeFilters.administrativeFileId = this.defaults.values.id;
              this.vehicleSaleFilters.entityId = response.data[0].entity?.id;
              this.invoiceFilters.shipmentCostType = response.data[0].shipmentCostType;
              this.mode = "update";
              this.alert.info("Expediente registrado con éxito");
              this.disabled = false;
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible dar de alta el expediente");
          }
      });

  }
  updatePurchase() {
    const purchase = this.form.value;
    purchase.isSale = true;
    purchase.active = true;
    purchase.office = new EmptyKeyValue();
    purchase.office.id = this.officeId;
    purchase.id = this.defaults.values.id;
    
    if ((purchase?.shipmentCostType?.id == 1 && this.defaults?.values?.shipmentCostType?.id == 2) || (purchase?.shipmentCostType?.id == 1 && this.defaults?.values?.shipmentCostType?.id == 3)) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: `Se eliminarán los costes de transporte. ¿Quiere continuar?`
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            this.modifyPurchase(purchase);
          }
        });
    }
    else {
      this.modifyPurchase(purchase);
    }
  }

  modifyPurchase(purchase: Purchase) {

    this.commonService.update(this.service, purchase).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible actualizar el expediente");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              this.defaults.values = response.data[0];
              this.vehicleSaleFilters.entityId = response.data[0].entity?.id;
              this.alert.info("Expediente actualizado con éxito");
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible actualizar el expediente");
          }
      });

  }
  showDeleteAdministrativeFileAlert(): void {
   
    this.dialog
      .open(ConfirmDialogComponent, {
        data: `Se borrarán todos los datos asociados al expediente. ¿Desea continuar?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteAdministrativeFile();
        }
      });
  }

  deleteAdministrativeFile() {
    const invoice = this.form.getRawValue();
    this.commonService.delete(this.service, invoice).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido borrar el expediente");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            this.alert.info("Expediente eliminado con éxito");
            this.dialogRef.close(invoice);
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido borrar el expediente");
        }
    });

  }
  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  isMenuMode() {
    return this.mode === 'menu';
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }


  selectedRole(event: MatSelectChange) {
    this.defaults.role = event.source.triggerValue;
  }

  changePurchaseType(type) {
    this.saleType = type;
  }
  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  close(){
    this.dialogRef.close(new Purchase(this.defaults.values));
  }

}
