import { IFilters } from "src/app/interfaces/ifilters";

export class VehicleSaleFilters implements IFilters {


    name: string = "";

    rowsPerPage: number;
    pageNumber: number;
    orderBy: string;
    order: string;
    hasSale?: boolean = null;
    hasShipment? = null;
    id?:number = null;
    orderTypeId?:number = null;
    entityId?:number = null;
    officeId?:number=null;
 
    
  
  }
  