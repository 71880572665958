import { contactTypesTableData } from "src/app/mocks/tipos-contacto";
import { ComboItem } from "src/app/models/combo-item";
import { IDocumentTypes } from "./document-types";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { EntityFilters } from "src/app/models/contacts/entity-filters";
import { IFilters } from "src/app/interfaces/ifilters";

@Injectable()
export class EntityFilter implements IDocumentTypes {
    public getFilters(): Observable<IFilters> {
        return of (new EntityFilters(null));
    }
}