import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './page-layout.component';
import { PageLayoutHeaderDirective } from './page-layout-header.directive';
import { PageLayoutContentDirective } from './page-layout-content.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastService } from 'src/app/services/toast/toast.service';
import { UniquePipe } from 'src/app/pipes/unique.pipe';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule
  ],
  providers:[ToastService],
  declarations: [PageLayoutComponent, PageLayoutHeaderDirective, PageLayoutContentDirective, UniquePipe],
  exports: [PageLayoutComponent, PageLayoutHeaderDirective, PageLayoutContentDirective]
})
export class PageLayoutModule {
}
